/* eslint-disable */
/* istanbul ignore file */
/* THIS FILE IS AUTOGENERATED - DO NOT EDIT */
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		AudioChannelFeatureChange: [
			'AudioChannelAcousticEchoCancellationChange',
			'AudioChannelAcousticEchoCancellationMonoReferenceChange',
			'AudioChannelAcousticEchoCancellationStereoReferenceChange',
			'AudioChannelAnalogLevelChange',
			'AudioChannelAutoLevelChange',
			'AudioChannelAutoMuteChange',
			'AudioChannelAutoPositionChange',
			'AudioChannelAutomaticGainControlChange',
			'AudioChannelAutomixSourceSelectorChange',
			'AudioChannelAutomixerChange',
			'AudioChannelAutomixerInputChange',
			'AudioChannelAutomixerVoiceActivityDetectionChange',
			'AudioChannelCarriedByRfChannelsChange',
			'AudioChannelChannelConfigurationChange',
			'AudioChannelCompressorChange',
			'AudioChannelConnectionDetectionChange',
			'AudioChannelDanteEncryptionStatusChange',
			'AudioChannelDanteRouteReceiverChange',
			'AudioChannelDanteRouteTransmitterChange',
			'AudioChannelDelayChange',
			'AudioChannelDenoiserChange',
			'AudioChannelDeverbChange',
			'AudioChannelDeviceSpecificEqualizerContourChange',
			'AudioChannelDirectOutputTapPointChange',
			'AudioChannelEqualizerChange',
			'AudioChannelEqualizerContourChange',
			'AudioChannelEqualizerPresetChange',
			'AudioChannelFaderGroupChange',
			'AudioChannelGainChange',
			'AudioChannelGainLockChange',
			'AudioChannelIdentifyChange',
			'AudioChannelInputSourceChange',
			'AudioChannelLightingBehaviorChange',
			'AudioChannelLimiterChange',
			'AudioChannelLobeConfigurationChange',
			'AudioChannelLobePositionChange',
			'AudioChannelLobeTalkerHeightChange',
			'AudioChannelLobeWidthChange',
			'AudioChannelLogicReceiverChange',
			'AudioChannelMicOptimizationChange',
			'AudioChannelMicPolarPatternChange',
			'AudioChannelMicrophoneAngleChange',
			'AudioChannelMonitorMixChange',
			'AudioChannelMonitorMixReverbChange',
			'AudioChannelMuteChange',
			'AudioChannelMuteGroupChange',
			'AudioChannelMuteLockChange',
			'AudioChannelNameChange',
			'AudioChannelNoiseReductionChange',
			'AudioChannelOutputPathChange',
			'AudioChannelPanChange',
			'AudioChannelPcPeripheralChange',
			'AudioChannelPhantomPowerChange',
			'AudioChannelPlosiveReductionChange',
			'AudioChannelPostGateGainChange',
			'AudioChannelPostGateMuteChange',
			'AudioChannelPostGateSoloChange',
			'AudioChannelReverbChange',
			'AudioChannelSelectedLoudspeakerChange',
			'AudioChannelSignalGeneratorChange',
			'AudioChannelSoloChange',
			'AudioChannelStereoGainChange',
			'AudioChannelStereoReverseChange',
			'AudioChannelStereoWidthChange',
			'AudioChannelTalkerHeightChange',
			'AudioChannelUsbOutputModeChange'
		],
		AudioCoverageAreaFeatureChange: [
			'AudioCoverageAreaConfigurationChange',
			'AudioCoverageAreaGainChange',
			'AudioCoverageAreaMuteChange'
		],
		ChargerBayFeatureChange: [
			'ChargerBayAssociationChange',
			'ChargerBayBatteryPresentChange',
			'ChargerBayChargingModeChange',
			'ChargerBayDockedDeviceChange'
		],
		ClearedAssociation: ['ChargerBay', 'RfChannel'],
		CloneRoomEvent: [
			'CloneRoomAudioRouteUpdateEvent',
			'CloneRoomControlRouteUpdateEvent',
			'CloneRoomDeviceUpdateEvent',
			'CloneRoomResultEvent',
			'CloneRoomStartedEvent'
		],
		CrossPointFeatureChange: ['CrossPointConfigurationChange', 'CrossPointGainChange'],
		CrossPointInputAudioChannel: ['AudioChannel', 'StereoAudioChannel'],
		DeviceFeatureChange: [
			'ConnectedDeviceLabelChange',
			'DeviceActivePresetChange',
			'DeviceAllEqualizersBypassChange',
			'DeviceAllIntellimixBypassChange',
			'DeviceAnalogAudioOutputModeChange',
			'DeviceAnalogLogicChange',
			'DeviceAnalogLogicPresetSwitchChange',
			'DeviceAnalogLogicPresetSwitchStatusChange',
			'DeviceAudioAutoFocusChange',
			'DeviceAudioChannelCountChange',
			'DeviceAudioChannelsChange',
			'DeviceAudioMuteChange',
			'DeviceAudioMuteControlChange',
			'DeviceAudioMuteControlGroupChange',
			'DeviceAudioMuteDefaultChange',
			'DeviceAudioMuteLockChange',
			'DeviceAudioNetworkInterfaceChange',
			'DeviceAuthenticationChange',
			'DeviceAutomixGainMeteringChange',
			'DeviceAvailablePackagesChange',
			'DeviceBatteryHealthChange',
			'DeviceBatteryLevelChange',
			'DeviceBodypackMicrophoneModeChange',
			'DeviceCallStatusChange',
			'DeviceCommandStringsServiceChange',
			'DeviceConferencingOperationModeButtonLockChange',
			'DeviceConferencingOperationModeChange',
			'DeviceConfigurationLockChange',
			'DeviceControlNetworkInterfaceChange',
			'DeviceCoverageModeChange',
			'DeviceCoveragePositionChange',
			'DeviceDanteAudioEncryptionChange',
			'DeviceDanteAudioNetworkAccessChange',
			'DeviceDanteAudioNetworkChange',
			'DeviceDectRfSpectrumScanChange',
			'DeviceDeploymentCandidatesChange',
			'DeviceDnsClientConfigurationChange',
			'DeviceDoubleStuffProxiedTransmittersChange',
			'DeviceEqualizerContourChange',
			'DeviceErrorIndicatorChange',
			'DeviceEthernetLightsChange',
			'DeviceFirmwareUpdatePolicyChange',
			'DeviceGatewayDeviceChange',
			'DeviceGlobalMicStatusChange',
			'DeviceHeightChange',
			'DeviceIdentifyChange',
			'DeviceInternetOfThingsChange',
			'DeviceLicenseChange',
			'DeviceLicenseChangeV2',
			'DeviceLightingAudioActivityChange',
			'DeviceLightingAudioMetersChange',
			'DeviceLightingAudioMuteIndicatorOverrideChange',
			'DeviceLightingAutomixGatingChange',
			'DeviceLightingBehaviorChange',
			'DeviceLightingBrightnessChange',
			'DeviceLightingLiveAudioMetersChange',
			'DeviceLightingMuteBehaviorChange',
			'DeviceLightingRingChange',
			'DeviceLightingSplitModeBreathingMeterColorChange',
			'DeviceLightingSplitModeSolidMeterColorChange',
			'DeviceLightingTouchPanelModeChange',
			'DeviceLightingUnmuteBehaviorChange',
			'DeviceLinkButtonLockChange',
			'DeviceLinkedAccessPointChange',
			'DeviceListenerHeightChange',
			'DeviceLogicMuteChange',
			'DeviceLogicReceiverChange',
			'DeviceLogicTransmitterChange',
			'DeviceLoudspeakerChange',
			'DeviceMdnsServiceChange',
			'DeviceMeteringModesChange',
			'DeviceMicStatusChange',
			'DeviceNameChange',
			'DeviceOnChargerChange',
			'DevicePlacementChange',
			'DeviceProxiedDevicesChange',
			'DeviceRedundantAudioNetworkChange',
			'DeviceRfDensityModeChange',
			'DeviceRfPowerChange',
			'DeviceRoomChange',
			'DeviceRotationChange',
			'DeviceRoutingPositionChange',
			'DeviceSecurity8021XChange',
			'DeviceSmartGateChange',
			'DeviceStereoAudioChange',
			'DeviceSwitchConfigurationChange',
			'DeviceTagsChange',
			'DeviceTransmitterIdentifyBehaviorChange',
			'DeviceTransmitterInitialStateFromChargerChange',
			'DeviceTransmitterLightingBehaviorChange',
			'DeviceTransmitterMuteBehaviorChange',
			'DeviceTransmitterOutOfRangeBehaviorChange',
			'DeviceTransmitterRejoinBehaviorChange',
			'DeviceTransmitterSwitchBehaviorChange',
			'DeviceUpdateProgressChange',
			'DeviceUptimeChange',
			'DeviceUsbMuteSyncChange',
			'DeviceUsbTerminalTypeChange',
			'DeviceUserPresetsChange',
			'DeviceVirtualAcousticBoundaryChange',
			'DeviceWebServiceChange'
		],
		DeviceUnlinkingDetailResult: ['DeviceUnlinkingChargerBayResult', 'DeviceUnlinkingRfChannelResult'],
		DiscoveredDevicesEvent: ['DeviceAdded', 'DeviceRemoved'],
		DiscoveredRoomEvent: ['RoomAddedEvent', 'RoomRemovedEvent'],
		EqualizerFilterFeatureChange: [
			'EqualizerFilterBandwidthChange',
			'EqualizerFilterConfigurationChange',
			'EqualizerFilterFrequencyChange',
			'EqualizerFilterGainChange',
			'EqualizerFilterQFactorChange'
		],
		Fragment: [
			'AudioChannel',
			'AudioCoverageArea',
			'ChargerBay',
			'CrossPoint',
			'EqualizerFilter',
			'RfChannel',
			'UserPreset'
		],
		IDurationRangeConstraints: ['AudioChannelDelayFieldConstraints', 'AutomixHoldTimeConstraints'],
		IFieldSupportInfo: [
			'AudioChannelCompressorLevelConstraints',
			'AudioChannelCompressorRatioConstraints',
			'AudioChannelCompressorThresholdConstraints',
			'AudioChannelLimiterThresholdConstraints',
			'AudioChannelLobePositionAutoFocusConstraints',
			'AudioChannelMicrophoneAngleAutoFocusConstraints',
			'AudioChannelTalkerHeightAutoFocusConstraints',
			'AutoLevelConfigurationConstraints',
			'AutoLevelModeConstraints',
			'AutoLevelPositionConstraints',
			'AutoLevelToneConstraints',
			'EqualizerEnabledConstraints',
			'EqualizerFiltersConstraints',
			'EqualizerToneConstraints',
			'FieldSupport',
			'NoiseReductionLevelConstraints'
		],
		IFloatRangeConstraints: [
			'AudioChannelAcousticEchoCancellationErleLevelConstraints',
			'AudioChannelAcousticEchoCancellationPeakMeterLevelConstraints',
			'AudioChannelAcousticEchoCancellationRmsMeterLevelConstraints',
			'AudioChannelAutomaticGainControlPeakMeterLevelConstraints',
			'AudioChannelAutomaticGainControlRmsMeterLevelConstraints',
			'AudioChannelAutomaticGainMaxBoostConstraints',
			'AudioChannelAutomaticGainMaxCutConstraints',
			'AudioChannelAutomaticGainTargetLevelConstraints',
			'AudioChannelAutomixGainConstraints',
			'AudioChannelAutomixerInputPeakLevelConstraints',
			'AudioChannelAutomixerInputRmsLevelConstraints',
			'AudioChannelGainFieldConstraints',
			'AudioChannelLobePositionAutoFocusFieldConstraints',
			'AudioChannelLobePositionFixedFieldConstraints',
			'AudioChannelMatrixMixerInputPeakLevelConstraints',
			'AudioChannelMatrixMixerInputRmsLevelConstraints',
			'AudioChannelMatrixMixerOutputPeakLevelConstraints',
			'AudioChannelMatrixMixerOutputRmsLevelConstraints',
			'AudioChannelMatrixMixerStereoInputPeakLevelConstraints',
			'AudioChannelMatrixMixerStereoInputRmsLevelConstraints',
			'AudioChannelPeakMeterLevelConstraints',
			'AudioChannelPostGateFieldConstraints',
			'AudioChannelRmsLevelConstraints',
			'AudioChannelStereoGainFieldConstraints',
			'AudioChannelStereoPeakLevelConstraints',
			'AudioChannelStereoRmsLevelConstraints',
			'AudioChannelTalkerHeightAutoFocusFieldConstraints',
			'AudioChannelTalkerHeightFieldConstraints',
			'AudioChannelTalkerHeightFixedFieldConstraints',
			'AudioCoverageAreaGainFieldConstraints',
			'AudioCoverageAreaPeakMeterLevelConstraints',
			'AudioCoverageRmsMeterLevelConstraints',
			'AutomixOffAttenuationConstraints',
			'CrossPointGainFieldConstraints',
			'DeviceHeightFieldConstraints',
			'DeviceListenerHeightFieldConstraints',
			'EqualizerFilterFrequencyFieldConstraints',
			'EqualizerFilterGainFieldConstraints',
			'RectangularAreaFieldConstraints'
		],
		IIntRangeConstraints: [
			'AudioChannelAutomaticGainConstraints',
			'AudioChannelMicrophoneAngleAutoFocusFieldConstraints',
			'AudioChannelMicrophoneAngleFixedFieldConstraints',
			'AudioChannelMonitorMixTypeConstraints',
			'AudioChannelPanFieldConstraints',
			'AudioChannelReverbIntensityConstraints',
			'AudioChannelStereoWidthAngleConstraints',
			'AutomixGatingSensitivityConstraints',
			'AutomixMaximumOpenChannelsConstraints',
			'DeviceBatteryHealthPercentageConstraints',
			'DeviceBatteryLevelPercentageConstraints',
			'DeviceCommandStringsServicePortConstraints',
			'DeviceLightingBrightnessFieldConstraints',
			'DeviceRotationAxisConstraints',
			'DeviceVirtualAcousticBoundarySensitivityConstraints',
			'RgbColorMixFieldConstraints'
		],
		INullableDurationRangeConstraints: [],
		INullableFloatRangeConstraints: ['AudioChannelLimiterThresholdConstraints'],
		INullableIntRangeConstraints: ['EqualizerToneConstraints'],
		IStringPatternRangeConstraints: [
			'AudioChannelDanteRouteReceiverDanteChannelNameConstraints',
			'AudioChannelDanteRouteTransmitterDanteChannelNameConstraints',
			'AudioChannelNameFieldConstraints',
			'DeviceDanteAudioNetworkNameConstraints',
			'DeviceNameFieldConstraints',
			'DeviceSecurity8021XAnonymousIdConstraints',
			'DeviceSecurity8021XPasswordConstraints',
			'DeviceSecurity8021XUserNameConstraints',
			'UserPresetCoreNameConstraints'
		],
		IUtf8StringMaxBytesConstraints: ['ConnectedDeviceLabelFieldConstraints', 'DeviceNameFieldConstraints'],
		MeterChange: ['AudioChannelMeterChange', 'AudioCoverageAreaMeterChange', 'RfChannelMeterChange'],
		Node: [
			'AudioChannel',
			'AudioCoverageArea',
			'ChargerBay',
			'CrossPoint',
			'Device',
			'EqualizerFilter',
			'FirmwarePackageDownloadProgress',
			'FirmwarePackageDownloadRequestProgress',
			'FirmwareUpdateProgress',
			'FirmwareUpdateRequestProgress',
			'RfChannel',
			'Room',
			'RoomManifestDevice',
			'StereoAudioChannel',
			'UserPreset'
		],
		NodeChange: [
			'AudioChannel',
			'AudioChannelChange',
			'AudioCoverageArea',
			'AudioCoverageAreaChange',
			'ChargerBay',
			'ChargerBayChange',
			'CrossPoint',
			'CrossPointChange',
			'Device',
			'DeviceChange',
			'DeviceStatusChange',
			'EqualizerFilter',
			'EqualizerFilterChange',
			'FirmwarePackageDownloadProgress',
			'FirmwarePackageDownloadRequestProgress',
			'FirmwareUpdateProgress',
			'FirmwareUpdateRequestProgress',
			'RfChannel',
			'RfChannelChange',
			'Room',
			'UserPreset',
			'UserPresetChange'
		],
		NodeOperationResult: [
			'AudioChannelOperationResult',
			'AudioCoverageAreaOperationResult',
			'ChargerBayOperationResult',
			'ClearAssociationResult',
			'CreateRouteOperationResult',
			'CrossPointOperationResult',
			'DeleteRouteOperationResult',
			'DeviceOperationResult',
			'EqualizerFilterOperationResult',
			'RfChannelOperationResult',
			'RoomOperationResult',
			'UserPresetOperationResult'
		],
		OptimizeRoomProgress: ['OptimizeRoomProgressEvent', 'OptimizeRoomResult'],
		QFactorConstraint: ['EqualizerFilterValidQFactors', 'FloatRange'],
		ResetDeviceResult: ['DeviceOperationResult', 'OperationResult'],
		RfChannelFeatureChange: [
			'RfChannelAssociationChange',
			'RfChannelCarriedAudioChannelsChange',
			'RfChannelLinkedTransmitterChange',
			'RfChannelPrimaryGroupChanNameChange'
		],
		RoomDeploymentProgress: ['RoomDeploymentProgressEvent', 'RoomDeploymentResult'],
		UserPresetFeatureChange: ['UserPresetCoreChange']
	}
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	/**
	 * The `AudioChannelGroupName` scalar represents the type of an audio channel. Use the device 'constraints' field to
	 * get a list of possible values supported for a specific device. Note that this list is open to expansion in the future as more devices
	 * and thus newer channel groups are supported over time.
	 *
	 * For applications integrating with this API, it is recommended to use an object defining a collection of constants (like an Enumeration class)
	 * to support this future expansion.
	 *
	 * Here are the currently supported list of possible values:
	 *
	 * AEC_REFERENCE
	 *
	 * ANALOG_AUX_INPUT
	 *
	 * AUTOMIX_AUX_INPUT
	 *
	 * AUX_OUTPUT
	 *
	 * DANTE_AUX_INPUT
	 *
	 * DANTE_INPUT
	 *
	 * DANTE_LINE_INPUT
	 *
	 * DANTE_LINE_OUTPUT
	 *
	 * DANTE_MIC_INPUT
	 *
	 * DANTE_OUTPUT
	 *
	 * HEADPHONE
	 *
	 * LOBE
	 *
	 * LOUDSPEAKER
	 *
	 * MIC
	 *
	 * MIX_OUTPUT
	 *
	 * MOBILE_INPUT
	 *
	 * MOBILE_OUTPUT
	 *
	 * PC_INPUT PC_OUTPUT
	 *
	 * SUM_USB_INPUT
	 *
	 * USB_OUTPUT
	 *
	 * VIRTUAL_AUDIO_INPUT
	 *
	 * VIRTUAL_AUDIO_OUTPUT
	 *
	 * GENERIC
	 *
	 * BACK_BLUETOOTH_INPUT
	 *
	 * BLUETOOTH_OUTPUT
	 *
	 * ANALOG_OUTPUT
	 *
	 * DIRECT_OUTPUT
	 *
	 * SIGNAL_GENERATOR_INPUT
	 *
	 * DANTE_ANALOG_INPUT
	 *
	 * ANALOG_70V_OUTPUT
	 *
	 * XLR_INPUT
	 *
	 * TRS_INPUT
	 */
	AudioChannelGroupName: { input: string; output: string };
	/** A DateTime in ISO-8601 format. (https://wikipedia.org/wiki/ISO_8601) */
	DateTime: { input: string; output: string };
	/**
	 * Corresponds to a period of time, as specified by https://en.wikipedia.org/wiki/ISO_8601#Durations
	 * E.g. PT1S is one second. PT0.1S is 100 milliseconds.
	 */
	Duration: { input: string; output: string };
	/**
	 * Guid stands for Globally Unique Identifier. Also referred to as UUIDs or Universaly Unique Identifiers.
	 * - Technically they are 128-bit unique reference numbers which are highly unlikely to repeat.
	 * - A Guid follows a specific structure defined in RFC `4122` and come in a few different
	 * versions and variants. All variants follow the same structure xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx
	 * where M represents the version and the most significant bits of N represent the variant.
	 */
	Guid: { input: string; output: string };
	/**
	 * The `IpAddress` scalar represents a dotted quad string notation of
	 * an IPv4 address.
	 */
	IpAddress: { input: string; output: string };
	Regex: { input: string; output: string };
	SemanticVersion: { input: string; output: string };
	Upload: { input: string; output: string };
};

export type AcousticEchoCancellationConstraints = {
	nonLinearProcessingLevel: Maybe<NonLinearProcessingLevelConstraints>;
};

export type ActivateLicenseInput = {
	activationKey: Scalars['String']['input'];
	count: Scalars['Int']['input'];
	id: Scalars['ID']['input'];
};

export enum AnalogAudioOutputMode {
	/**
	 * For use with low impedance speakers (a.k.a Low Z), such as 8-ohm or 16-ohm speakers.
	 * Speakers will be wired individually to each audio channel.
	 */
	LowImpedance = 'LOW_IMPEDANCE',
	/**
	 * For use with 70V speakers with step-up / step-down transformers inside.
	 * Speakers are wired in a daisy-chained way to a single audio channel.
	 */
	SeventyVolt = 'SEVENTY_VOLT'
}

export type AnalogAudioOutputModeConstraints = {
	options: Array<AnalogAudioOutputMode | `${AnalogAudioOutputMode}`>;
};

export enum AnalogLevel {
	Aux = 'AUX',
	Line = 'LINE',
	Mic = 'MIC'
}

export type AnalogLevelConstraints = {
	level: AnalogLevelModeConstraints;
};

export type AnalogLevelModeConstraints = {
	options: Array<AnalogLevel | `${AnalogLevel}`>;
};

export enum AnalogLogicPresetSwitch {
	/** Mode A: This is the default selection. When there is no signal, the device loads Preset 1. When there is signal, the device switches to Preset 2. */
	ModeA = 'MODE_A',
	/** Mode B: When there is no signal, the device loads Preset 2. When there is signal, the device switches to Preset 1. */
	ModeB = 'MODE_B'
}

export enum AnalogLogicPresetSwitchStatus {
	NoSignal = 'NO_SIGNAL',
	Signal = 'SIGNAL'
}

export type AnalogLogicPresetSwitchStatusConstraints = {
	options: Array<AnalogLogicPresetSwitchStatus | `${AnalogLogicPresetSwitchStatus}`>;
};

export enum AssociationStatus {
	/** Access point is not discoverable */
	AccessPointDeviceNotFound = 'ACCESS_POINT_DEVICE_NOT_FOUND',
	/** Charger device is not discoverable */
	ChargerDeviceNotFound = 'CHARGER_DEVICE_NOT_FOUND',
	/** Failure occured on pre-processing a Charger Bay. This include failure in clearing existing association duing re-association */
	FailurePreProcessingChargerBay = 'FAILURE_PRE_PROCESSING_CHARGER_BAY',
	/** Failure occured on pre-processing a RF Channel. This include failure in clearing existing association duing re-association */
	FailurePreProcessingRfChannel = 'FAILURE_PRE_PROCESSING_RF_CHANNEL',
	/** Failure occured when processing association request on Charger Bay. This includes failed opeartions on Charger Bay */
	FailureProcessingChargerBay = 'FAILURE_PROCESSING_CHARGER_BAY',
	/** Failure occured when processing association request on RF Channel. This includes failed opeartions on RF Channel */
	FailureProcessingRfChannel = 'FAILURE_PROCESSING_RF_CHANNEL',
	/** Input node id(s) provided in association request is not valid */
	InvalidInput = 'INVALID_INPUT',
	/** Provided input devices either does not support association feature or not a valid input for this operation */
	InvalidOperation = 'INVALID_OPERATION',
	/** Successfully associated RF channel and Charger bay */
	Success = 'SUCCESS'
}

export enum AudioActivityLevel {
	Clipping = 'CLIPPING',
	Good = 'GOOD',
	Low = 'LOW'
}

export type AudioChannel = Fragment &
	Node & {
		description: AudioChannelDescription;
		features: AudioChannelFeatures;
		fragmentId: Scalars['ID']['output'];
		id: Scalars['ID']['output'];
	};

export type AudioChannelAcousticEchoCancellation = {
	enabled: Scalars['Boolean']['output'];
	nonLinearProcessingLevel: NonLinearProcessingLevel | `${NonLinearProcessingLevel}`;
};

export type AudioChannelAcousticEchoCancellationChange = {
	acousticEchoCancellation: Maybe<AudioChannelAcousticEchoCancellation>;
};

export type AudioChannelAcousticEchoCancellationErleLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAcousticEchoCancellationErleMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelAcousticEchoCancellationErleMeterConstraints = {
	level: AudioChannelAcousticEchoCancellationErleLevelConstraints;
};

export type AudioChannelAcousticEchoCancellationInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	nonLinearProcessingLevel?: InputMaybe<NonLinearProcessingLevel | `${NonLinearProcessingLevel}`>;
};

export type AudioChannelAcousticEchoCancellationMonoReference = {
	/** An audio channel of the device used for monitoring the selected reference source. */
	reference: AudioChannel;
	/** A list of audio channels within this device that can be used as a mono reference for acoustic echo cancellation. */
	referenceSourceOptions: Maybe<Array<AudioChannel>>;
	/**
	 * The audio channel used as a far end mono reference signal.
	 * For best results, use the signal that also feeds your local reinforcement system (e.g. conference room loudspeakers).
	 * On MXA mics, route a far-end signal to the AEC Reference In channel.
	 * On digital signal processors, choose an option from 'referenceSourceOptions'.
	 */
	selectedReferenceSource: AudioChannel;
};

export type AudioChannelAcousticEchoCancellationMonoReferenceChange = {
	acousticEchoCancellationMonoReference: Maybe<AudioChannelAcousticEchoCancellationMonoReference>;
};

export type AudioChannelAcousticEchoCancellationMonoReferenceConstraints = {
	referenceSourceOptions: FieldSupport;
};

export type AudioChannelAcousticEchoCancellationMonoReferenceInput = {
	/** Audio channel which can configure the 'acousticEchoCancellationMonoReference' feature */
	id: Scalars['ID']['input'];
	/** Audio channel to be selected as acoustic echo cancellation mono reference source */
	source: Scalars['ID']['input'];
};

export type AudioChannelAcousticEchoCancellationPeakMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelAcousticEchoCancellationPeakMeterConstraints = {
	level: AudioChannelAcousticEchoCancellationPeakMeterLevelConstraints;
};

export type AudioChannelAcousticEchoCancellationPeakMeterLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAcousticEchoCancellationRmsMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelAcousticEchoCancellationRmsMeterConstraints = {
	level: AudioChannelAcousticEchoCancellationRmsMeterLevelConstraints;
};

export type AudioChannelAcousticEchoCancellationRmsMeterLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAcousticEchoCancellationStereoReference = {
	/**
	 * An internal audio channel of the device used for monitoring the selected reference source.
	 * Use stereoRms and stereoPeak(or matrixMixerStereoInputRms and matrixMixerStereoInputPeak) features for reference channel meters
	 */
	reference: AudioChannel;
	/** A list of audio channels within this device that can be used as a stereo reference for acoustic echo cancellation. */
	referenceSourceOptions: Array<AudioChannel>;
	/**
	 * The audio channel used as a far end stereo left reference signal.
	 * Choose an option from 'referenceSourceOptions'.
	 */
	selectedLeftReferenceSource: AudioChannel;
	/**
	 * The audio channel used as a far end stereo right reference signal.
	 * Choose an option from 'referenceSourceOptions'.
	 */
	selectedRightReferenceSource: AudioChannel;
};

export type AudioChannelAcousticEchoCancellationStereoReferenceChange = {
	acousticEchoCancellationStereoReference: Maybe<AudioChannelAcousticEchoCancellationStereoReference>;
};

export type AudioChannelAcousticEchoCancellationStereoReferenceInput = {
	/** Audio channel which can congfigure the 'acousticEchoCancellationStereoReference' feature */
	id: Scalars['ID']['input'];
	/** Audio channel to be selected as acoustic echo cancellation stereo reference source */
	source: Scalars['ID']['input'];
	/** Audio channel spatial separation information which can configure the 'acousticEchoCancellationStereoReference' feature */
	stereoAudioChannelSpatialSeparation: StereoAudioChannelSpatialSeparation | `${StereoAudioChannelSpatialSeparation}`;
};

export type AudioChannelActivityMeter = {
	level: AudioActivityLevel | `${AudioActivityLevel}`;
};

/**
 * The level which the audio channel is expected to receive signal at.
 * This is used to perform additional amplification within the device as needed
 * for signal to be intelligible.
 */
export type AudioChannelAnalogLevel = {
	/** Refer to https://www.shure.com/en-MEA/conferencing-meetings/ignite/tell-me-about-mic-and-line-level for the differences between levels */
	level: AnalogLevel | `${AnalogLevel}`;
};

export type AudioChannelAnalogLevelChange = {
	analogLevel: Maybe<AudioChannelAnalogLevel>;
};

export type AudioChannelAnalogLevelInput = {
	level: AnalogLevel | `${AnalogLevel}`;
};

export type AudioChannelAutoLevel = {
	configuration: Maybe<AutoLevelConfiguration | `${AutoLevelConfiguration}`>;
	mode: Maybe<AutoLevelMode | `${AutoLevelMode}`>;
	position: Maybe<AutoLevelPosition | `${AutoLevelPosition}`>;
	tone: Maybe<AutoLevelTone | `${AutoLevelTone}`>;
};

export type AudioChannelAutoLevelChange = {
	autoLevel: Maybe<AudioChannelAutoLevel>;
};

export type AudioChannelAutoLevelConstraints = {
	configuration: AutoLevelConfigurationConstraints;
	mode: AutoLevelModeConstraints;
	position: AutoLevelPositionConstraints;
	tone: AutoLevelToneConstraints;
};

export type AudioChannelAutoLevelUpdateInput = {
	configuration?: InputMaybe<AutoLevelConfiguration | `${AutoLevelConfiguration}`>;
	mode?: InputMaybe<AutoLevelMode | `${AutoLevelMode}`>;
	position?: InputMaybe<AutoLevelPosition | `${AutoLevelPosition}`>;
	tone?: InputMaybe<AutoLevelTone | `${AutoLevelTone}`>;
};

export type AudioChannelAutoMute = {
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelAutoMuteChange = {
	autoMute: Maybe<AudioChannelAutoMute>;
};

export type AudioChannelAutoMuteUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelAutoPosition = {
	status: AutoPositionStatus | `${AutoPositionStatus}`;
};

export type AudioChannelAutoPositionChange = {
	autoPosition: Maybe<AudioChannelAutoPosition>;
};

export type AudioChannelAutoPositionConstraints = {
	status: AudioChannelAutoPositionStatusConstraints;
};

export type AudioChannelAutoPositionStatusConstraints = {
	options: Array<AutoPositionStatus | `${AutoPositionStatus}`>;
};

export type AudioChannelAutomaticGainConstraints = IIntRangeConstraints & {
	range: IntRange;
};

/**
 * Automatic gain control adjusts audio channel levels to ensure consistent volume for all talkers, in all scenarios.
 * For quieter voices, it increases gain; for louder voices, it attenuates the signal.
 *
 * Automatic gain control is postfader, and adjusts the audio channel level after the input level has been adjusted.
 * Enable it on audio channels where the distance between the talker and the microphone may vary, or in rooms where many different
 * people will use the conferencing system.
 */
export type AudioChannelAutomaticGainControl = {
	/** Indicates whether or not the AGC feature is enabled on the audio channel. */
	enabled: Scalars['Boolean']['output'];
	/** The maximum amount of gain that can be applied (dB). */
	maxBoost: Scalars['Float']['output'];
	/** The maximum amount of attenuation that can be applied (dB). */
	maxCut: Scalars['Float']['output'];
	/**
	 * The level that you want the signal to be normalized to (dBFS).
	 * This level is different from adjusting the input fader according to peak levels to avoid clipping.
	 */
	targetLevel: Scalars['Float']['output'];
};

export type AudioChannelAutomaticGainControlChange = {
	automaticGainControl: Maybe<AudioChannelAutomaticGainControl>;
};

export type AudioChannelAutomaticGainControlConstraints = {
	/** Describes the range of values for the maximum gain that can be applied to the audio channel (dB). */
	maxBoost: AudioChannelAutomaticGainMaxBoostConstraints;
	/** Describes the range of values for the maximum attenuation that can be applied to the audio channel (dB). */
	maxCut: AudioChannelAutomaticGainMaxCutConstraints;
	/** Describes the range of values which the AGC can target for the audio channel by applying gain or attenutation. (dB) */
	targetLevel: AudioChannelAutomaticGainTargetLevelConstraints;
};

export type AudioChannelAutomaticGainControlPeakMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelAutomaticGainControlPeakMeterConstraints = {
	level: AudioChannelAutomaticGainControlPeakMeterLevelConstraints;
};

export type AudioChannelAutomaticGainControlPeakMeterLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAutomaticGainControlRmsMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelAutomaticGainControlRmsMeterConstraints = {
	level: AudioChannelAutomaticGainControlRmsMeterLevelConstraints;
};

export type AudioChannelAutomaticGainControlRmsMeterLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAutomaticGainControlUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	maxBoost?: InputMaybe<Scalars['Float']['input']>;
	maxCut?: InputMaybe<Scalars['Float']['input']>;
	targetLevel?: InputMaybe<Scalars['Float']['input']>;
};

export type AudioChannelAutomaticGainMaxBoostConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAutomaticGainMaxCutConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAutomaticGainMeter = {
	gain: Scalars['Int']['output'];
};

export type AudioChannelAutomaticGainMeterConstraints = {
	gain: AudioChannelAutomaticGainConstraints;
};

export type AudioChannelAutomaticGainTargetLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAutomixGainConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAutomixGainMeter = {
	gain: Scalars['Float']['output'];
};

export type AudioChannelAutomixGainMeterConstraints = {
	gain: AudioChannelAutomixGainConstraints;
};

export type AudioChannelAutomixSourceSelector = {
	/**
	 * When an audio channel is selected, the corresponding automixed content will be replaced with the source.
	 * When null, the automixer will decide which channels it mixes based on the automixer capability.
	 */
	source: Maybe<AudioChannel>;
	sourceOptions: Array<AudioChannel>;
};

export type AudioChannelAutomixSourceSelectorChange = {
	automixSourceSelector: Maybe<AudioChannelAutomixSourceSelector>;
};

/**
 * Automixer takes in multiple audio channels and automatically mixes them into a singular output channel.
 * This helps improve intelligibility of meeting audio by focusing on the most relevant audio content, and removing
 * less relevant audio content.
 */
export type AudioChannelAutomixer = {
	enabled: Maybe<Scalars['Boolean']['output']>;
	/**
	 * The threshold of the level at which the gate is opened, as a numeric level.
	 * Lower is less sensitive (meaning fewer false detections due to room noise or talkers outside the meeting room, at the expense of missing desired talkers).
	 * Higher is more sensitive (meaning more false detections due to room noise or talkers outside the meeting room, but better at catching the start of every talker).
	 */
	gatingSensitivity: Scalars['Int']['output'];
	/** The duration for which the audio channel remains open after the level drops below the gate threshold. */
	holdTime: Scalars['Duration']['output'];
	/**
	 * Indicates whether or not the most recently used audio channel should remain active.
	 * This keeps natural room sound in the signal so that meeting participants at the far end know the
	 * audio signal has not been interrupted.
	 */
	leaveLastChannelOn: Scalars['Boolean']['output'];
	/** The maximum number of simultaneously active audio channels. */
	maximumOpenChannels: Scalars['Int']['output'];
	/** How the automix should be performed */
	mode: AutomixMode | `${AutomixMode}`;
	/** The amount of signal reduction that is applied when an audio channel is not active (dB). */
	offAttenuation: Scalars['Float']['output'];
};

export type AudioChannelAutomixerChange = {
	automixer: Maybe<AudioChannelAutomixer>;
};

export type AudioChannelAutomixerConstraints = {
	enabled: FieldSupport;
	/**
	 * Describes the range of thresholds at which the gate is opened.
	 * Lower is less sensitive, and higher is more sensitive.
	 */
	gatingSensitivity: AutomixGatingSensitivityConstraints;
	/** Describes the range of values for the hold time. */
	holdTime: AutomixHoldTimeConstraints;
	/** Describes the range of audio channels which can remain simultaneously active. */
	maximumOpenChannels: AutomixMaximumOpenChannelsConstraints;
	/** Describes the supported automix modes on this audio channel's automixer. */
	mode: AutomixModeConstraints;
	/** Describes the range of attenutation that can be applied when an audio channel is not active (dB). */
	offAttenuation: AutomixOffAttenuationConstraints;
};

export type AudioChannelAutomixerGateMeter = {
	open: Scalars['Boolean']['output'];
};

/** Automixer input describes the features which correspond to an audio channel's participation in the device's automixer. */
export type AudioChannelAutomixerInput = {
	/**
	 * When enabled, this audio channel will always be active in the automixer.
	 * When the capability exists, but this field is null, the audio channel cannot be assumed to always be sent to the automix.
	 */
	alwaysOn: Maybe<Scalars['Boolean']['output']>;
	/**
	 * When enabled, this audio channel's gate activates within the automixer,
	 * regardless of the number of maximum open channels configured on this device's automixer.
	 */
	priority: Scalars['Boolean']['output'];
	/**
	 * When enabled, sends this channel to the automix channel.
	 * When this capability exists, but this field is null, the audio channel is always assumed to be sent to the automix.
	 */
	sendToMix: Maybe<Scalars['Boolean']['output']>;
};

export type AudioChannelAutomixerInputChange = {
	automixerInput: Maybe<AudioChannelAutomixerInput>;
};

export type AudioChannelAutomixerInputConstraints = {
	/** Describes whether or not this audio channel can always be active in the automixer. */
	alwaysOn: FieldSupport;
	/** Describes whether or not this audio channel supports being conditionally sent to the automixer. */
	sendToMix: FieldSupport;
};

export type AudioChannelAutomixerInputPeakLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAutomixerInputPeakMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelAutomixerInputPeakMeterConstraints = {
	level: AudioChannelAutomixerInputPeakLevelConstraints;
};

export type AudioChannelAutomixerInputRmsLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelAutomixerInputRmsMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelAutomixerInputRmsMeterConstraints = {
	level: AudioChannelAutomixerInputRmsLevelConstraints;
};

export type AudioChannelAutomixerInputUpdateInput = {
	alwaysOn?: InputMaybe<Scalars['Boolean']['input']>;
	priority?: InputMaybe<Scalars['Boolean']['input']>;
	sendToMix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelAutomixerUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	gatingSensitivity?: InputMaybe<Scalars['Int']['input']>;
	holdTime?: InputMaybe<Scalars['Duration']['input']>;
	leaveLastChannelOn?: InputMaybe<Scalars['Boolean']['input']>;
	maximumOpenChannels?: InputMaybe<Scalars['Int']['input']>;
	mode?: InputMaybe<AutomixMode | `${AutomixMode}`>;
	offAttenuation?: InputMaybe<Scalars['Int']['input']>;
};

export type AudioChannelAutomixerVoiceActivityDetection = {
	noiseDetectionSensitivity: NoiseDetectionSensitivity | `${NoiseDetectionSensitivity}`;
	noiseRemovalLevel: NoiseRemovalLevel | `${NoiseRemovalLevel}`;
};

export type AudioChannelAutomixerVoiceActivityDetectionChange = {
	automixerVoiceActivityDetection: Maybe<AudioChannelAutomixerVoiceActivityDetection>;
};

export type AudioChannelAutomixerVoiceActivityDetectionConstraints = {
	noiseDetectionSensitivity: NoiseDetectionSensitivityConstraints;
	noiseRemovalLevel: NoiseRemovalLevelConstraints;
};

export type AudioChannelAutomixerVoiceActivityDetectionUpdateInput = {
	noiseDetectionSensitivity?: InputMaybe<NoiseDetectionSensitivity | `${NoiseDetectionSensitivity}`>;
	noiseRemovalLevel?: InputMaybe<NoiseRemovalLevel | `${NoiseRemovalLevel}`>;
};

export type AudioChannelCarriedByRfChannels = {
	rfChannels: Array<RfChannel>;
};

export type AudioChannelCarriedByRfChannelsChange = {
	carriedByRfChannels: Maybe<AudioChannelCarriedByRfChannels>;
};

export type AudioChannelChange = {
	features: AudioChannelFeatureChange;
	id: Scalars['ID']['output'];
};

export type AudioChannelChannelConfiguration = {
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelChannelConfigurationChange = {
	channelConfiguration: Maybe<AudioChannelChannelConfiguration>;
};

export type AudioChannelClipMeter = {
	clip: Scalars['Boolean']['output'];
};

export type AudioChannelCompressor = {
	/** Indicates whether or not the compressor is enabled. */
	enabled: Maybe<Scalars['Boolean']['output']>;
	/** Level of compression applied to the audio signal */
	level: Maybe<CompressorLevel | `${CompressorLevel}`>;
	/**
	 * The amount of attenuation that is applied when the signal exceeds the threshold.
	 *
	 * A lower ratio of 2:1 means that for every 2 dB above the threshold, the output signal will only exceed the threshold by 1 dB.
	 *
	 * A higher ratio of 10:1 means a loud sound that exceeds the threshold by 10 dB will only exceed the threshold by 1 dB, effectively reducing the signal by 9 dB.
	 */
	ratio: Maybe<Scalars['Float']['output']>;
	/** The signal level at which compressor takes effect (dB). */
	threshold: Maybe<Scalars['Float']['output']>;
};

export type AudioChannelCompressorChange = {
	compressor: Maybe<AudioChannelCompressor>;
};

export type AudioChannelCompressorConstraints = {
	/** Indicates whether compressor can be enabled on the audio channel. */
	enabled: FieldSupport;
	/** Describes the range of supported values for the compressor level on the audio channel. */
	level: AudioChannelCompressorLevelConstraints;
	/** Describes the range of supported values for the compressor ratio on the audio channel. */
	ratio: AudioChannelCompressorRatioConstraints;
	/** Describes the range of supported values for the compressor threshold on the audio channel. */
	threshold: AudioChannelCompressorThresholdConstraints;
};

export type AudioChannelCompressorEngagedMeter = {
	engaged: Scalars['Boolean']['output'];
};

export type AudioChannelCompressorLevelConstraints = IFieldSupportInfo & {
	options: Maybe<Array<CompressorLevel | `${CompressorLevel}`>>;
	supported: Scalars['Boolean']['output'];
};

export type AudioChannelCompressorRatioConstraints = IFieldSupportInfo & {
	range: Maybe<FloatRange>;
	supported: Scalars['Boolean']['output'];
};

export type AudioChannelCompressorThresholdConstraints = IFieldSupportInfo & {
	range: Maybe<FloatRange>;
	supported: Scalars['Boolean']['output'];
};

export type AudioChannelCompressorUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	level?: InputMaybe<CompressorLevel | `${CompressorLevel}`>;
	ratio?: InputMaybe<Scalars['Float']['input']>;
	threshold?: InputMaybe<Scalars['Float']['input']>;
};

export type AudioChannelConnectionDetection = {
	connected: Scalars['Boolean']['output'];
};

export type AudioChannelConnectionDetectionChange = {
	connectionDetection: Maybe<AudioChannelConnectionDetection>;
};

export type AudioChannelConstraints = {
	acousticEchoCancellation: Maybe<AcousticEchoCancellationConstraints>;
	acousticEchoCancellationErle: Maybe<AudioChannelAcousticEchoCancellationErleMeterConstraints>;
	acousticEchoCancellationMonoReference: Maybe<AudioChannelAcousticEchoCancellationMonoReferenceConstraints>;
	acousticEchoCancellationPeak: Maybe<AudioChannelAcousticEchoCancellationPeakMeterConstraints>;
	acousticEchoCancellationRms: Maybe<AudioChannelAcousticEchoCancellationRmsMeterConstraints>;
	analogLevel: Maybe<AnalogLevelConstraints>;
	autoLevel: Maybe<AudioChannelAutoLevelConstraints>;
	autoPosition: Maybe<AudioChannelAutoPositionConstraints>;
	automaticGain: Maybe<AudioChannelAutomaticGainMeterConstraints>;
	automaticGainControl: Maybe<AudioChannelAutomaticGainControlConstraints>;
	automaticGainControlPeak: Maybe<AudioChannelAutomaticGainControlPeakMeterConstraints>;
	automaticGainControlRms: Maybe<AudioChannelAutomaticGainControlRmsMeterConstraints>;
	automixGain: Maybe<AudioChannelAutomixGainMeterConstraints>;
	automixer: Maybe<AudioChannelAutomixerConstraints>;
	automixerInput: Maybe<AudioChannelAutomixerInputConstraints>;
	automixerInputPeak: Maybe<AudioChannelAutomixerInputPeakMeterConstraints>;
	automixerInputRms: Maybe<AudioChannelAutomixerInputRmsMeterConstraints>;
	automixerVoiceActivityDetection: Maybe<AudioChannelAutomixerVoiceActivityDetectionConstraints>;
	compressor: Maybe<AudioChannelCompressorConstraints>;
	danteRouteReceiver: Maybe<AudioChannelDanteRouteReceiverConstraints>;
	danteRouteTransmitter: Maybe<AudioChannelDanteRouteTransmitterConstraints>;
	delay: Maybe<AudioChannelDelayConstraints>;
	denoiser: Maybe<AudioChannelDenoiserLevelConstraints>;
	deverb: Maybe<AudioChannelDeverbConstraints>;
	deviceSpecificEqualizerContour: Maybe<AudioChannelDeviceSpecificEqualizerContourConstraints>;
	directOutputTapPoint: Maybe<AudioChannelDirectOutputTapPointConstraints>;
	equalizer: Maybe<AudioChannelEqualizerConstraints>;
	equalizerContour: Maybe<AudioChannelEqualizerContourConstraints>;
	equalizerPreset: Maybe<AudioChannelEqualizerPresetConstraints>;
	gain: Maybe<AudioChannelGainConstraints>;
	inputSource: Maybe<AudioChannelInputSourceConstraints>;
	lightingBehavior: Maybe<AudioChannelLightingBehaviorConstraints>;
	limiter: Maybe<AudioChannelLimiterConstraints>;
	lobePosition: Maybe<AudioChannelLobePositionConstraints>;
	lobeTalkerHeight: Maybe<AudioChannelLobeTalkerHeightConstraints>;
	lobeWidth: Maybe<AudioChannelLobeWidthConstraints>;
	matrixMixerInputPeak: Maybe<AudioChannelMatrixMixerInputPeakMeterConstraints>;
	matrixMixerInputRms: Maybe<AudioChannelMatrixMixerInputRmsMeterConstraints>;
	matrixMixerOutputPeak: Maybe<AudioChannelMatrixMixerOutputPeakMeterConstraints>;
	matrixMixerOutputRms: Maybe<AudioChannelMatrixMixerOutputRmsMeterConstraints>;
	matrixMixerStereoInputPeak: Maybe<AudioChannelMatrixMixerStereoInputPeakMeterConstraints>;
	matrixMixerStereoInputRms: Maybe<AudioChannelMatrixMixerStereoInputRmsMeterConstraints>;
	micOptimization: Maybe<AudioChannelMicOptimizationConstraints>;
	micPolarPattern: Maybe<AudioChannelMicPolarPatternConstraints>;
	microphoneAngle: Maybe<AudioChannelMicrophoneAngleConstraints>;
	monitorMix: Maybe<AudioChannelMonitorMixConstraints>;
	name: Maybe<AudioChannelNameConstraints>;
	noiseReduction: Maybe<AudioChannelNoiseReductionConstraints>;
	outputPath: Maybe<AudioChannelOutputPathConstraints>;
	pan: Maybe<AudioChannelPanConstraints>;
	peak: Maybe<AudioChannelPeakMeterConstraints>;
	phantomPower: Maybe<AudioChannelPhantomPowerConstraints>;
	postGateGain: Maybe<AudioChannelPostGateGainConstraints>;
	reverb: Maybe<AudioChannelReverbConstraints>;
	rms: Maybe<AudioChannelRmsMeterConstraints>;
	selectedLoudspeaker: Maybe<AudioChannelSelectedLoudspeakerConstraints>;
	signalGenerator: Maybe<AudioChannelSignalGeneratorConstraints>;
	stereoGain: Maybe<AudioChannelStereoGainConstraints>;
	stereoPeak: Maybe<AudioChannelStereoPeakMeterConstraints>;
	stereoRms: Maybe<AudioChannelStereoRmsMeterConstraints>;
	stereoWidth: Maybe<AudioChannelStereoWidthConstraints>;
	/** @deprecated Use 'lobeTalkerHeight' constraints instead. */
	talkerHeight: Maybe<AudioChannelTalkerHeightConstraints>;
	usbOutputMode: Maybe<AudioChannelUsbOutputModeConstraints>;
};

export type AudioChannelCountInput = {
	count: Scalars['Int']['input'];
	id: Scalars['ID']['input'];
};

export type AudioChannelDanteEncryptionStatus = {
	/** Status of audio encryption for a routed audio channel over Dante. */
	status: DanteAudioEncryptionStatus | `${DanteAudioEncryptionStatus}`;
};

export type AudioChannelDanteEncryptionStatusChange = {
	danteEncryptionStatus: Maybe<AudioChannelDanteEncryptionStatus>;
};

export type AudioChannelDanteRouteReceiver = {
	/**
	 * The Dante channel name for uniquely identifying a channel within this device.
	 * Dante channel names are not guaranteed unique throughout a network.
	 */
	danteChannelName: Scalars['String']['output'];
	/** The subscription which the receiver is listening to. Null when no subscription exists. */
	subscription: Maybe<DanteAudioRouteSubscription>;
};

export type AudioChannelDanteRouteReceiverChange = {
	danteRouteReceiver: Maybe<AudioChannelDanteRouteReceiver>;
};

export type AudioChannelDanteRouteReceiverConstraints = {
	danteChannelName: AudioChannelDanteRouteReceiverDanteChannelNameConstraints;
};

export type AudioChannelDanteRouteReceiverDanteChannelNameConstraints = IStringPatternRangeConstraints & {
	pattern: Scalars['Regex']['output'];
};

export type AudioChannelDanteRouteReceiverUpdateInput = {
	danteChannelName: Scalars['String']['input'];
};

export type AudioChannelDanteRouteTransmitter = {
	/**
	 * The Dante channel name for uniquely identifying a channel within this device.
	 * Dante channel names are not guaranteed unique throughout a network.
	 */
	danteChannelName: Scalars['String']['output'];
};

export type AudioChannelDanteRouteTransmitterChange = {
	danteRouteTransmitter: Maybe<AudioChannelDanteRouteTransmitter>;
};

export type AudioChannelDanteRouteTransmitterConstraints = {
	danteChannelName: AudioChannelDanteRouteTransmitterDanteChannelNameConstraints;
};

export type AudioChannelDanteRouteTransmitterDanteChannelNameConstraints = IStringPatternRangeConstraints & {
	pattern: Scalars['Regex']['output'];
};

export type AudioChannelDanteRouteTransmitterUpdateInput = {
	danteChannelName: Scalars['String']['input'];
};

export type AudioChannelDelay = {
	/**
	 * The amount of signal delay being applied to the channel (ms).
	 *
	 * This is used for synchronizing audio and video, or audio from multiple talkers.
	 */
	delay: Scalars['Duration']['output'];
	/** Indicates whether or not the delay feature is enabled */
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelDelayChange = {
	delay: Maybe<AudioChannelDelay>;
};

export type AudioChannelDelayConstraints = {
	/** Describes the range of values for the signal delay that are supported on the channel. */
	delay: AudioChannelDelayFieldConstraints;
};

export type AudioChannelDelayFieldConstraints = IDurationRangeConstraints & {
	/** Describes the range of values for the signal delay that are supported on the channel. */
	range: DurationRange;
};

export type AudioChannelDelayUpdateInput = {
	delay?: InputMaybe<Scalars['Duration']['input']>;
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelDenoiser = {
	enabled: Scalars['Boolean']['output'];
	level: DenoiserLevel | `${DenoiserLevel}`;
};

export type AudioChannelDenoiserChange = {
	denoiser: Maybe<AudioChannelDenoiser>;
};

export type AudioChannelDenoiserLevelConstraints = {
	level: AudioChannelValidDenoiserLevelConstraints;
};

export type AudioChannelDenoiserNoiseReductionMeter = {
	percentage: Scalars['Int']['output'];
};

export type AudioChannelDenoiserUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	level?: InputMaybe<DenoiserLevel | `${DenoiserLevel}`>;
};

export type AudioChannelDescription = {
	constraints: AudioChannelConstraints;
	features: AudioChannelSupportedFeatures;
	/** @deprecated Use the 'groupName' field instead to ensure your code is open to extension to new audio channel groups. This enum is no longer preferred since it regularly breaks on expanding the available enum members to cover new channel groupings for new devices. */
	group: AudioChannelGroup | `${AudioChannelGroup}`;
	groupName: Scalars['AudioChannelGroupName']['output'];
	/** Ordering is relative within audio channels sharing the same group name */
	index: Scalars['Int']['output'];
	role: AudioChannelRole | `${AudioChannelRole}`;
};

export type AudioChannelDeverb = {
	enabled: Scalars['Boolean']['output'];
	intensity: DeverbIntensity | `${DeverbIntensity}`;
};

export type AudioChannelDeverbChange = {
	deverb: Maybe<AudioChannelDeverb>;
};

export type AudioChannelDeverbConstraints = {
	intensity: AudioChannelDeverbIntensityConstraints;
};

export type AudioChannelDeverbIntensityConstraints = {
	options: Array<DeverbIntensity | `${DeverbIntensity}`>;
};

export type AudioChannelDeverbUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	intensity?: InputMaybe<DeverbIntensity | `${DeverbIntensity}`>;
};

export type AudioChannelDeviceSpecificEqualizerContour = {
	/**
	 * Built-in equalizer contours optimized to routed audio from the devices specified by the contour options.
	 * Applying both the device-level 'equalizerContour' as well as any audio-channel level 'equalizer' changes will be cumulative.
	 *
	 * Refer to your device's user guide for more. E.g. https://pubs.shure.com/guide/IntelliMixRoom/en-US
	 */
	deviceSpecificEqualizerContour: DeviceSpecificEqualizerContour | `${DeviceSpecificEqualizerContour}`;
};

export type AudioChannelDeviceSpecificEqualizerContourChange = {
	deviceSpecificEqualizerContour: Maybe<AudioChannelDeviceSpecificEqualizerContour>;
};

export type AudioChannelDeviceSpecificEqualizerContourConstraints = {
	deviceSpecificEqualizerContour: Maybe<AudioChannelDeviceSpecificEqualizerContourTypeConstraints>;
};

export type AudioChannelDeviceSpecificEqualizerContourTypeConstraints = {
	options: Array<DeviceSpecificEqualizerContour | `${DeviceSpecificEqualizerContour}`>;
};

export type AudioChannelDeviceSpecificEqualizerContourUpdateInput = {
	deviceSpecificEqualizerContour: DeviceSpecificEqualizerContour | `${DeviceSpecificEqualizerContour}`;
};

export type AudioChannelDirectOutputTapPoint = {
	/** Indicates where the signal to the matrix mixer should come from. */
	tapPoint: DirectOutputTapPoint | `${DirectOutputTapPoint}`;
};

export type AudioChannelDirectOutputTapPointChange = {
	directOutputTapPoint: Maybe<AudioChannelDirectOutputTapPoint>;
};

export type AudioChannelDirectOutputTapPointConstraints = {
	tapPoint: Maybe<AudioChannelDirectOutputTapPointTypeConstraints>;
};

export type AudioChannelDirectOutputTapPointTypeConstraints = {
	options: Array<DirectOutputTapPoint | `${DirectOutputTapPoint}`>;
};

export type AudioChannelDirectOutputTapPointUpdateInput = {
	tapPoint: DirectOutputTapPoint | `${DirectOutputTapPoint}`;
};

export type AudioChannelEqualizer = {
	enabled: Maybe<Scalars['Boolean']['output']>;
	filters: Array<EqualizerFilter>;
	tone: Maybe<Scalars['Int']['output']>;
};

export type AudioChannelEqualizerChange = {
	equalizer: Maybe<AudioChannelEqualizer>;
};

export type AudioChannelEqualizerConstraints = {
	enabled: EqualizerEnabledConstraints;
	filters: EqualizerFiltersConstraints;
	tone: EqualizerToneConstraints;
};

export type AudioChannelEqualizerContour = {
	equalizerContour: EqualizerContour | `${EqualizerContour}`;
};

export type AudioChannelEqualizerContourChange = {
	equalizerContour: Maybe<AudioChannelEqualizerContour>;
};

export type AudioChannelEqualizerContourConstraints = {
	equalizerContour: Maybe<AudioChannelEqualizerContourTypeConstraints>;
};

export type AudioChannelEqualizerContourTypeConstraints = {
	options: Array<EqualizerContour | `${EqualizerContour}`>;
};

export type AudioChannelEqualizerContourUpdateInput = {
	equalizerContour: EqualizerContour | `${EqualizerContour}`;
};

export type AudioChannelEqualizerPreset = {
	/** Equalizer preset mode applied to the audio channel. Value of `null` implies that the currently set audio settings for the audio channel does not correspond to any preset mode. */
	equalizerPreset: Maybe<EqualizerPreset | `${EqualizerPreset}`>;
};

export type AudioChannelEqualizerPresetChange = {
	equalizerPreset: Maybe<AudioChannelEqualizerPreset>;
};

export type AudioChannelEqualizerPresetConstraints = {
	equalizerPreset: Maybe<AudioChannelEqualizerPresetTypeConstraints>;
};

export type AudioChannelEqualizerPresetTypeConstraints = {
	options: Array<EqualizerPreset | `${EqualizerPreset}`>;
	/**
	 * Indicates whether the device actively monitors changes to audio settings and adjusts the equalizer preset mode automatically if the new audio settings corresponds to any known preset mode setting.
	 * If true, the `equalizerPreset` feature field can be null to represent cases where the new audio settings does not correspond to any known preset mode setting. If false, the `equalizerPreset` value can never be null
	 * and it will always reflect either the value set on the device or its default value.
	 */
	tracksAudioSettingChanges: Scalars['Boolean']['output'];
};

export type AudioChannelEqualizerPresetUpdateInput = {
	equalizerPreset: EqualizerPreset | `${EqualizerPreset}`;
	/** Audio channel node ID */
	id: Scalars['ID']['input'];
};

export type AudioChannelEqualizerUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	tone?: InputMaybe<Scalars['Int']['input']>;
};

export type AudioChannelFaderGroup = {
	/** Indicates whether or not the audio channel is participating in the fader group for linking gain adjustments across multiple audio channels */
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelFaderGroupChange = {
	faderGroup: Maybe<AudioChannelFaderGroup>;
};

export type AudioChannelFaderGroupUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelFeatureChange =
	| AudioChannelAcousticEchoCancellationChange
	| AudioChannelAcousticEchoCancellationMonoReferenceChange
	| AudioChannelAcousticEchoCancellationStereoReferenceChange
	| AudioChannelAnalogLevelChange
	| AudioChannelAutoLevelChange
	| AudioChannelAutoMuteChange
	| AudioChannelAutoPositionChange
	| AudioChannelAutomaticGainControlChange
	| AudioChannelAutomixSourceSelectorChange
	| AudioChannelAutomixerChange
	| AudioChannelAutomixerInputChange
	| AudioChannelAutomixerVoiceActivityDetectionChange
	| AudioChannelCarriedByRfChannelsChange
	| AudioChannelChannelConfigurationChange
	| AudioChannelCompressorChange
	| AudioChannelConnectionDetectionChange
	| AudioChannelDanteEncryptionStatusChange
	| AudioChannelDanteRouteReceiverChange
	| AudioChannelDanteRouteTransmitterChange
	| AudioChannelDelayChange
	| AudioChannelDenoiserChange
	| AudioChannelDeverbChange
	| AudioChannelDeviceSpecificEqualizerContourChange
	| AudioChannelDirectOutputTapPointChange
	| AudioChannelEqualizerChange
	| AudioChannelEqualizerContourChange
	| AudioChannelEqualizerPresetChange
	| AudioChannelFaderGroupChange
	| AudioChannelGainChange
	| AudioChannelGainLockChange
	| AudioChannelIdentifyChange
	| AudioChannelInputSourceChange
	| AudioChannelLightingBehaviorChange
	| AudioChannelLimiterChange
	| AudioChannelLobeConfigurationChange
	| AudioChannelLobePositionChange
	| AudioChannelLobeTalkerHeightChange
	| AudioChannelLobeWidthChange
	| AudioChannelLogicReceiverChange
	| AudioChannelMicOptimizationChange
	| AudioChannelMicPolarPatternChange
	| AudioChannelMicrophoneAngleChange
	| AudioChannelMonitorMixChange
	| AudioChannelMonitorMixReverbChange
	| AudioChannelMuteChange
	| AudioChannelMuteGroupChange
	| AudioChannelMuteLockChange
	| AudioChannelNameChange
	| AudioChannelNoiseReductionChange
	| AudioChannelOutputPathChange
	| AudioChannelPanChange
	| AudioChannelPcPeripheralChange
	| AudioChannelPhantomPowerChange
	| AudioChannelPlosiveReductionChange
	| AudioChannelPostGateGainChange
	| AudioChannelPostGateMuteChange
	| AudioChannelPostGateSoloChange
	| AudioChannelReverbChange
	| AudioChannelSelectedLoudspeakerChange
	| AudioChannelSignalGeneratorChange
	| AudioChannelSoloChange
	| AudioChannelStereoGainChange
	| AudioChannelStereoReverseChange
	| AudioChannelStereoWidthChange
	| AudioChannelTalkerHeightChange
	| AudioChannelUsbOutputModeChange;

export type AudioChannelFeatureUpdateInput = {
	acousticEchoCancellation?: InputMaybe<AudioChannelAcousticEchoCancellationInput>;
	analogLevel?: InputMaybe<AudioChannelAnalogLevelInput>;
	autoLevel?: InputMaybe<AudioChannelAutoLevelUpdateInput>;
	autoMute?: InputMaybe<AudioChannelAutoMuteUpdateInput>;
	automaticGainControl?: InputMaybe<AudioChannelAutomaticGainControlUpdateInput>;
	automixer?: InputMaybe<AudioChannelAutomixerUpdateInput>;
	automixerInput?: InputMaybe<AudioChannelAutomixerInputUpdateInput>;
	automixerVoiceActivityDetection?: InputMaybe<AudioChannelAutomixerVoiceActivityDetectionUpdateInput>;
	compressor?: InputMaybe<AudioChannelCompressorUpdateInput>;
	danteRouteReceiver?: InputMaybe<AudioChannelDanteRouteReceiverUpdateInput>;
	danteRouteTransmitter?: InputMaybe<AudioChannelDanteRouteTransmitterUpdateInput>;
	delay?: InputMaybe<AudioChannelDelayUpdateInput>;
	denoiser?: InputMaybe<AudioChannelDenoiserUpdateInput>;
	deverb?: InputMaybe<AudioChannelDeverbUpdateInput>;
	deviceSpecificEqualizerContour?: InputMaybe<AudioChannelDeviceSpecificEqualizerContourUpdateInput>;
	directOutputTapPoint?: InputMaybe<AudioChannelDirectOutputTapPointUpdateInput>;
	equalizer?: InputMaybe<AudioChannelEqualizerUpdateInput>;
	equalizerContour?: InputMaybe<AudioChannelEqualizerContourUpdateInput>;
	faderGroup?: InputMaybe<AudioChannelFaderGroupUpdateInput>;
	gain?: InputMaybe<AudioChannelGainUpdateInput>;
	gainLock?: InputMaybe<AudioChannelGainLockUpdateInput>;
	inputSource?: InputMaybe<AudioChannelInputSourceUpdateInput>;
	limiter?: InputMaybe<AudioChannelLimiterUpdateInput>;
	lobeConfiguration?: InputMaybe<AudioChannelLobeConfigurationUpdateInput>;
	lobePosition?: InputMaybe<AudioChannelLobePositionUpdateInput>;
	lobeTalkerHeight?: InputMaybe<AudioChannelFixedLobeTalkerHeightUpdateInput>;
	lobeWidth?: InputMaybe<AudioChannelLobeWidthUpdateInput>;
	micOptimization?: InputMaybe<AudioChannelMicOptimizationUpdateInput>;
	micPolarPattern?: InputMaybe<AudioChannelMicPolarPatternUpdateInput>;
	microphoneAngle?: InputMaybe<AudioChannelFixedMicrophoneAngleUpdateInput>;
	monitorMix?: InputMaybe<AudioChannelMonitorMixUpdateInput>;
	monitorMixReverb?: InputMaybe<AudioChannelMonitorMixReverbUpdateInput>;
	mute?: InputMaybe<AudioChannelMuteUpdateInput>;
	muteGroup?: InputMaybe<AudioChannelMuteGroupUpdateInput>;
	muteLock?: InputMaybe<AudioChannelMuteLockUpdateInput>;
	name?: InputMaybe<AudioChannelNameUpdateInput>;
	noiseReduction?: InputMaybe<AudioChannelNoiseReductionUpdateInput>;
	outputPath?: InputMaybe<AudioChannelOutputPathUpdateInput>;
	pan?: InputMaybe<AudioChannelPanUpdateInput>;
	pcPeripheral?: InputMaybe<AudioChannelPcPeripheralUpdateInput>;
	phantomPower?: InputMaybe<AudioChannelPhantomPowerUpdateInput>;
	plosiveReduction?: InputMaybe<AudioChannelPlosiveReductionUpdateInput>;
	postGateGain?: InputMaybe<AudioChannelPostGateGainUpdateInput>;
	postGateMute?: InputMaybe<AudioChannelPostGateMuteUpdateInput>;
	postGateSolo?: InputMaybe<AudioChannelPostGateSoloUpdateInput>;
	reverb?: InputMaybe<AudioChannelReverbUpdateInput>;
	selectedLoudspeaker?: InputMaybe<AudioChannelSelectedLoudspeakerInput>;
	signalGenerator?: InputMaybe<AudioChannelSignalGeneratorUpdateInput>;
	solo?: InputMaybe<AudioChannelSoloUpdateInput>;
	stereoGain?: InputMaybe<AudioChannelStereoGainUpdateInput>;
	stereoReverse?: InputMaybe<AudioChannelStereoReverseUpdateInput>;
	stereoWidth?: InputMaybe<AudioChannelStereoWidthUpdateInput>;
	talkerHeight?: InputMaybe<AudioChannelTalkerHeightUpdateInput>;
	usbOutputMode?: InputMaybe<AudioChannelUsbOutputModeUpdateInput>;
};

export type AudioChannelFeatures = {
	acousticEchoCancellation: Maybe<AudioChannelAcousticEchoCancellation>;
	/** Acoustic Echo Cancellation Echo Return Loss Enhancement Meter level (dB) */
	acousticEchoCancellationErle: Maybe<AudioChannelAcousticEchoCancellationErleMeter>;
	acousticEchoCancellationMonoReference: Maybe<AudioChannelAcousticEchoCancellationMonoReference>;
	acousticEchoCancellationPeak: Maybe<AudioChannelAcousticEchoCancellationPeakMeter>;
	acousticEchoCancellationRms: Maybe<AudioChannelAcousticEchoCancellationRmsMeter>;
	acousticEchoCancellationStereoReference: Maybe<AudioChannelAcousticEchoCancellationStereoReference>;
	activity: Maybe<AudioChannelActivityMeter>;
	analogLevel: Maybe<AudioChannelAnalogLevel>;
	autoLevel: Maybe<AudioChannelAutoLevel>;
	autoMute: Maybe<AudioChannelAutoMute>;
	autoPosition: Maybe<AudioChannelAutoPosition>;
	automaticGain: Maybe<AudioChannelAutomaticGainMeter>;
	automaticGainControl: Maybe<AudioChannelAutomaticGainControl>;
	automaticGainControlPeak: Maybe<AudioChannelAutomaticGainControlPeakMeter>;
	automaticGainControlRms: Maybe<AudioChannelAutomaticGainControlRmsMeter>;
	automixGain: Maybe<AudioChannelAutomixGainMeter>;
	automixSourceSelector: Maybe<AudioChannelAutomixSourceSelector>;
	automixer: Maybe<AudioChannelAutomixer>;
	automixerGate: Maybe<AudioChannelAutomixerGateMeter>;
	automixerInput: Maybe<AudioChannelAutomixerInput>;
	/**
	 * The preferred metering point for input channels participating in automixing.
	 * For devices that don't support this feature, an earlier metering point must be used, such as peak.
	 */
	automixerInputPeak: Maybe<AudioChannelAutomixerInputPeakMeter>;
	/**
	 * The preferred metering point for input channels participating in automixing.
	 * For devices that don't support this feature, an earlier metering point must be used, such as rms.
	 */
	automixerInputRms: Maybe<AudioChannelAutomixerInputRmsMeter>;
	automixerVoiceActivityDetection: Maybe<AudioChannelAutomixerVoiceActivityDetection>;
	carriedByRfChannels: Maybe<AudioChannelCarriedByRfChannels>;
	channelConfiguration: Maybe<AudioChannelChannelConfiguration>;
	clip: Maybe<AudioChannelClipMeter>;
	compressor: Maybe<AudioChannelCompressor>;
	compressorEngaged: Maybe<AudioChannelCompressorEngagedMeter>;
	connectionDetection: Maybe<AudioChannelConnectionDetection>;
	danteEncryptionStatus: Maybe<AudioChannelDanteEncryptionStatus>;
	danteRouteReceiver: Maybe<AudioChannelDanteRouteReceiver>;
	danteRouteTransmitter: Maybe<AudioChannelDanteRouteTransmitter>;
	delay: Maybe<AudioChannelDelay>;
	denoiser: Maybe<AudioChannelDenoiser>;
	denoiserNoiseReduction: Maybe<AudioChannelDenoiserNoiseReductionMeter>;
	deverb: Maybe<AudioChannelDeverb>;
	deviceSpecificEqualizerContour: Maybe<AudioChannelDeviceSpecificEqualizerContour>;
	directOutputTapPoint: Maybe<AudioChannelDirectOutputTapPoint>;
	equalizer: Maybe<AudioChannelEqualizer>;
	equalizerContour: Maybe<AudioChannelEqualizerContour>;
	equalizerPreset: Maybe<AudioChannelEqualizerPreset>;
	faderGroup: Maybe<AudioChannelFaderGroup>;
	gain: Maybe<AudioChannelGain>;
	gainLock: Maybe<AudioChannelGainLock>;
	identify: Maybe<AudioChannelIdentify>;
	inputSource: Maybe<AudioChannelInputSource>;
	lightingBehavior: Maybe<AudioChannelLightingBehavior>;
	limiter: Maybe<AudioChannelLimiter>;
	limiterEngaged: Maybe<AudioChannelLimiterEngagedMeter>;
	lobeConfiguration: Maybe<AudioChannelLobeConfiguration>;
	lobePosition: Maybe<AudioChannelLobePosition>;
	lobeTalkerHeight: Maybe<AudioChannelLobeTalkerHeight>;
	lobeWidth: Maybe<AudioChannelLobeWidth>;
	logicReceiver: Maybe<AudioChannelLogicReceiver>;
	/**
	 * The preferred metering point for input channels participating in matrix mixing.
	 * For devices that don't support this feature, an earlier metering point must be used, such as peak.
	 */
	matrixMixerInputPeak: Maybe<AudioChannelMatrixMixerInputPeakMeter>;
	/**
	 * The preferred metering point for input channels participating in matrix mixing.
	 * For devices that don't support this feature, an earlier metering point must be used, such as rms.
	 */
	matrixMixerInputRms: Maybe<AudioChannelMatrixMixerInputRmsMeter>;
	matrixMixerOutput: Maybe<AudioChannelMatrixMixerOutput>;
	/**
	 * The preferred metering point for output channels participating in matrix mixing.
	 * For devices that don't support this feature, a later metering point must be used, such as peak.
	 */
	matrixMixerOutputPeak: Maybe<AudioChannelMatrixMixerOutputPeakMeter>;
	/**
	 * The preferred metering point for output channels participating in matrix mixing.
	 * For devices that don't support this feature, a later metering point must be used, such as rms.
	 */
	matrixMixerOutputRms: Maybe<AudioChannelMatrixMixerOutputRmsMeter>;
	/** When the related device feature, 'stereoAudio''s enabled field is 'false', the 'rightLevel' field will be "negative infinity" and unchanging (e.g -60 dBFS on IMX-R). E.g. The 'leftLevel' will continue to return the Mono signal for the audio channel. */
	matrixMixerStereoInputPeak: Maybe<AudioChannelMatrixMixerStereoInputPeakMeter>;
	/** When the related device feature, 'stereoAudio''s enabled field is 'false', the 'rightLevel' field will be "negative infinity" and unchanging (e.g -60 dBFS on IMX-R). E.g. The 'leftLevel' will continue to return the Mono signal for the audio channel. */
	matrixMixerStereoInputRms: Maybe<AudioChannelMatrixMixerStereoInputRmsMeter>;
	micOptimization: Maybe<AudioChannelMicOptimization>;
	micPolarPattern: Maybe<AudioChannelMicPolarPattern>;
	microphoneAngle: Maybe<AudioChannelMicrophoneAngle>;
	monitorMix: Maybe<AudioChannelMonitorMix>;
	monitorMixReverb: Maybe<AudioChannelMonitorMixReverb>;
	mute: Maybe<AudioChannelMute>;
	muteGroup: Maybe<AudioChannelMuteGroup>;
	muteLock: Maybe<AudioChannelMuteLock>;
	name: Maybe<AudioChannelName>;
	noiseReduction: Maybe<AudioChannelNoiseReduction>;
	outputPath: Maybe<AudioChannelOutputPath>;
	pan: Maybe<AudioChannelPan>;
	pcPeripheral: Maybe<AudioChannelPcPeripheral>;
	/**
	 * When the device-level feature stereoAudio's enabled field is true, the left channel of that stereo signal will be available here and in the stereoPeak capability.
	 * This capability always represents a mono send (a.k.a L/Mono).
	 */
	peak: Maybe<AudioChannelPeakMeter>;
	phantomPower: Maybe<AudioChannelPhantomPower>;
	plosiveReduction: Maybe<AudioChannelPlosiveReduction>;
	postGateGain: Maybe<AudioChannelPostGateGain>;
	postGateMute: Maybe<AudioChannelPostGateMute>;
	postGateSolo: Maybe<AudioChannelPostGateSolo>;
	reverb: Maybe<AudioChannelReverb>;
	/**
	 * When the device-level feature stereoAudio's enabled field is true, the left channel of that stereo signal will be available here and in the stereoPeak capability.
	 * This capability always represents a mono send (a.k.a L/Mono).
	 */
	rms: Maybe<AudioChannelRmsMeter>;
	selectedLoudspeaker: Maybe<AudioChannelSelectedLoudspeaker>;
	signalGenerator: Maybe<AudioChannelSignalGenerator>;
	solo: Maybe<AudioChannelSolo>;
	stereoGain: Maybe<AudioChannelStereoGain>;
	/** When the related device feature, 'stereoAudio''s enabled field is 'false', the 'rightLevel' field will be "negative infinity" and unchanging (e.g -110 dBFS on IMX-R). E.g. The 'leftLevel' will continue to return the Mono signal for the audio channel. */
	stereoPeak: Maybe<AudioChannelStereoPeakMeter>;
	/** Reverse stereo - also known as Left-Right (L/R) Swap - indicates whether the left and right stereo audio is swapped */
	stereoReverse: Maybe<AudioChannelStereoReverse>;
	/** When the related device feature, 'stereoAudio''s enabled field is 'false', the 'rightLevel' field will be "negative infinity" and unchanging (e.g -110 dBFS on IMX-R). E.g. The 'leftLevel' will continue to return the Mono signal for the audio channel. */
	stereoRms: Maybe<AudioChannelStereoRmsMeter>;
	stereoWidth: Maybe<AudioChannelStereoWidth>;
	/** @deprecated Use 'lobeTalkerHeight' feature instead. */
	talkerHeight: Maybe<AudioChannelTalkerHeight>;
	usbOutputMode: Maybe<AudioChannelUsbOutputMode>;
};

export type AudioChannelFixedLobeTalkerHeightUpdateInput = {
	fixed: AudioChannelLobeTalkerHeightUpdateInput;
};

export type AudioChannelFixedMicrophoneAngleUpdateInput = {
	fixed: AudioChannelMicrophoneAngleUpdateInput;
};

export type AudioChannelGain = {
	/** Signal gain (dB) */
	gain: Scalars['Float']['output'];
};

export type AudioChannelGainChange = {
	gain: Maybe<AudioChannelGain>;
};

export type AudioChannelGainConstraints = {
	/** Describes the range of values for the signal gain that are supported on the channel. */
	gain: AudioChannelGainFieldConstraints;
};

export type AudioChannelGainFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelGainLock = {
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelGainLockChange = {
	gainLock: Maybe<AudioChannelGainLock>;
};

export type AudioChannelGainLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelGainUpdateInput = {
	gain: Scalars['Float']['input'];
};

export enum AudioChannelGroup {
	AecReference = 'AEC_REFERENCE',
	Analog_70VOutput = 'ANALOG_70V_OUTPUT',
	AnalogAuxInput = 'ANALOG_AUX_INPUT',
	AnalogOutput = 'ANALOG_OUTPUT',
	Automix = 'AUTOMIX',
	AuxInput = 'AUX_INPUT',
	AuxOutput = 'AUX_OUTPUT',
	Back = 'BACK',
	BluetoothInput = 'BLUETOOTH_INPUT',
	BluetoothOutput = 'BLUETOOTH_OUTPUT',
	DanteAnalogInput = 'DANTE_ANALOG_INPUT',
	DanteAuxInput = 'DANTE_AUX_INPUT',
	DanteInput = 'DANTE_INPUT',
	DanteLineInput = 'DANTE_LINE_INPUT',
	DanteLineOutput = 'DANTE_LINE_OUTPUT',
	DanteMicInput = 'DANTE_MIC_INPUT',
	DanteOutput = 'DANTE_OUTPUT',
	DirectOutput = 'DIRECT_OUTPUT',
	Generic = 'GENERIC',
	Headphone = 'HEADPHONE',
	Lobe = 'LOBE',
	Loudspeaker = 'LOUDSPEAKER',
	Mic = 'MIC',
	MixOutput = 'MIX_OUTPUT',
	MobileInput = 'MOBILE_INPUT',
	MobileOutput = 'MOBILE_OUTPUT',
	PcInput = 'PC_INPUT',
	PcOutput = 'PC_OUTPUT',
	SignalGeneratorInput = 'SIGNAL_GENERATOR_INPUT',
	Sum = 'SUM',
	UsbInput = 'USB_INPUT',
	UsbOutput = 'USB_OUTPUT',
	VirtualAudioInput = 'VIRTUAL_AUDIO_INPUT',
	VirtualAudioOutput = 'VIRTUAL_AUDIO_OUTPUT'
}

export type AudioChannelIdentify = {
	identifying: Scalars['Boolean']['output'];
};

export type AudioChannelIdentifyChange = {
	identify: Maybe<AudioChannelIdentify>;
};

/**
 * Determines the source of the input for this audio channel.
 * This feature is only supported for audio channels which have multiple input sources
 * (e.g. digital and analog input sources, combination inputs, etc)
 */
export type AudioChannelInputSource = {
	source: AudioChannelInputSourceType | `${AudioChannelInputSourceType}`;
};

export type AudioChannelInputSourceChange = {
	inputSource: Maybe<AudioChannelInputSource>;
};

export type AudioChannelInputSourceConstraints = {
	source: Maybe<AudioChannelInputSourceTypeConstraints>;
};

export enum AudioChannelInputSourceType {
	Analog = 'ANALOG',
	Digital = 'DIGITAL'
}

export type AudioChannelInputSourceTypeConstraints = {
	options: Array<AudioChannelInputSourceType | `${AudioChannelInputSourceType}`>;
};

export type AudioChannelInputSourceUpdateInput = {
	source?: InputMaybe<AudioChannelInputSourceType | `${AudioChannelInputSourceType}`>;
};

export type AudioChannelLightingBehavior = {
	color: Maybe<LightingColor | `${LightingColor}`>;
	mode: Maybe<LightingMode | `${LightingMode}`>;
	state: Maybe<LightingState | `${LightingState}`>;
};

export type AudioChannelLightingBehaviorChange = {
	lightingBehavior: Maybe<AudioChannelLightingBehavior>;
};

export type AudioChannelLightingBehaviorColorConstraints = {
	options: Array<LightingColor | `${LightingColor}`>;
};

export type AudioChannelLightingBehaviorConstraints = {
	color: AudioChannelLightingBehaviorColorConstraints;
	mode: AudioChannelLightingBehaviorModeConstraints;
	state: AudioChannelLightingBehaviorStateConstraints;
};

export type AudioChannelLightingBehaviorModeConstraints = {
	options: Array<LightingMode | `${LightingMode}`>;
};

export type AudioChannelLightingBehaviorStateConstraints = {
	options: Array<LightingState | `${LightingState}`>;
};

/** Automixer input describes the features which correspond to an audio channel's participation in the device's automixer. */
export type AudioChannelLimiter = {
	bypassed: Maybe<Scalars['Boolean']['output']>;
	enabled: Scalars['Boolean']['output'];
	threshold: Maybe<Scalars['Float']['output']>;
};

export type AudioChannelLimiterChange = {
	limiter: Maybe<AudioChannelLimiter>;
};

export type AudioChannelLimiterConstraints = {
	bypassed: FieldSupport;
	threshold: AudioChannelLimiterThresholdConstraints;
};

export type AudioChannelLimiterEngagedMeter = {
	engaged: Scalars['Boolean']['output'];
};

export type AudioChannelLimiterThresholdConstraints = IFieldSupportInfo &
	INullableFloatRangeConstraints & {
		range: Maybe<FloatRange>;
		supported: Scalars['Boolean']['output'];
	};

export type AudioChannelLimiterUpdateInput = {
	bypassed?: InputMaybe<Scalars['Boolean']['input']>;
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	threshold?: InputMaybe<Scalars['Float']['input']>;
};

export type AudioChannelLobeConfiguration = {
	/** Indicates whether lobe is enabled */
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelLobeConfigurationChange = {
	lobeConfiguration: Maybe<AudioChannelLobeConfiguration>;
};

export type AudioChannelLobeConfigurationUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelLobePosition = {
	/** Auto-focus lobe displacement in millimeters (mm) relative to the center of the device */
	autoFocus: Maybe<FloatPosition>;
	/** Lobe displacement in millimeters (mm) relative to the center of the device */
	fixed: FloatPosition;
};

export type AudioChannelLobePositionAutoFocusConstraints = IFieldSupportInfo & {
	supported: Scalars['Boolean']['output'];
	xPosition: Maybe<AudioChannelLobePositionAutoFocusFieldConstraints>;
	yPosition: Maybe<AudioChannelLobePositionAutoFocusFieldConstraints>;
	zPosition: Maybe<AudioChannelLobePositionAutoFocusFieldConstraints>;
};

export type AudioChannelLobePositionAutoFocusFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelLobePositionChange = {
	lobePosition: Maybe<AudioChannelLobePosition>;
};

export type AudioChannelLobePositionConstraints = {
	autoFocus: AudioChannelLobePositionAutoFocusConstraints;
	fixed: AudioChannelLobePositionFixedConstraints;
};

export type AudioChannelLobePositionFixedConstraints = {
	xPosition: AudioChannelLobePositionFixedFieldConstraints;
	yPosition: AudioChannelLobePositionFixedFieldConstraints;
	zPosition: AudioChannelLobePositionFixedFieldConstraints;
};

export type AudioChannelLobePositionFixedFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelLobePositionUpdateInput = {
	fixed?: InputMaybe<FloatPositionUpdateInput>;
};

export type AudioChannelLobeTalkerHeight = {
	/** Height of the talker's head in millimeters (mm), measured from the floor */
	autoFocus: Maybe<TalkerHeight>;
	/** Height of the talker's head in millimeters (mm), measured from the floor */
	fixed: TalkerHeight;
};

export type AudioChannelLobeTalkerHeightChange = {
	lobeTalkerHeight: Maybe<AudioChannelLobeTalkerHeight>;
};

export type AudioChannelLobeTalkerHeightConstraints = {
	autoFocus: AudioChannelTalkerHeightAutoFocusConstraints;
	fixed: AudioChannelTalkerHeightFixedConstraints;
};

export type AudioChannelLobeTalkerHeightUpdateInput = {
	height?: InputMaybe<Scalars['Float']['input']>;
};

export type AudioChannelLobeWidth = {
	/** Specifies the lobe width */
	width: LobeWidth | `${LobeWidth}`;
};

export type AudioChannelLobeWidthChange = {
	lobeWidth: Maybe<AudioChannelLobeWidth>;
};

export type AudioChannelLobeWidthConstraints = {
	/** Describes the range of supported values for the lobe width on the audio channel. */
	width: LobeWidthConstraints;
};

export type AudioChannelLobeWidthUpdateInput = {
	width?: InputMaybe<LobeWidth | `${LobeWidth}`>;
};

/**
 * Represents the logic signal sending and receiving happening between devices participating in the device-enabled logic feature.
 * This feature optimizes mute status synchronization at the optimal places in the signal path between particapting microphones and digital signal processors.
 */
export type AudioChannelLogicReceiver = {
	inputDeviceType: Maybe<Scalars['String']['output']>;
	/** Indicates whether or not the channel is receiving a logic input signal from a logic-enabled microphone */
	inputSignal: Scalars['Boolean']['output'];
	/** Indicates whether or not the channel is sending a logic output signal to a logic-enabled microphone */
	outputGated: Scalars['Boolean']['output'];
};

export type AudioChannelLogicReceiverChange = {
	logicReceiver: Maybe<AudioChannelLogicReceiver>;
};

export type AudioChannelMatrixMixerInputPeakLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelMatrixMixerInputPeakMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelMatrixMixerInputPeakMeterConstraints = {
	level: AudioChannelMatrixMixerInputPeakLevelConstraints;
};

export type AudioChannelMatrixMixerInputRmsLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelMatrixMixerInputRmsMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelMatrixMixerInputRmsMeterConstraints = {
	level: AudioChannelMatrixMixerInputRmsLevelConstraints;
};

export type AudioChannelMatrixMixerOutput = {
	crossPoints: Array<CrossPoint>;
};

export type AudioChannelMatrixMixerOutputPeakLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelMatrixMixerOutputPeakMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelMatrixMixerOutputPeakMeterConstraints = {
	level: AudioChannelMatrixMixerOutputPeakLevelConstraints;
};

export type AudioChannelMatrixMixerOutputRmsLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelMatrixMixerOutputRmsMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelMatrixMixerOutputRmsMeterConstraints = {
	level: AudioChannelMatrixMixerOutputRmsLevelConstraints;
};

export type AudioChannelMatrixMixerStereoInputPeakLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelMatrixMixerStereoInputPeakMeter = {
	leftLevel: Scalars['Float']['output'];
	rightLevel: Scalars['Float']['output'];
};

export type AudioChannelMatrixMixerStereoInputPeakMeterConstraints = {
	leftLevel: AudioChannelMatrixMixerStereoInputPeakLevelConstraints;
	rightLevel: AudioChannelMatrixMixerStereoInputPeakLevelConstraints;
};

export type AudioChannelMatrixMixerStereoInputRmsLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelMatrixMixerStereoInputRmsMeter = {
	leftLevel: Scalars['Float']['output'];
	rightLevel: Scalars['Float']['output'];
};

export type AudioChannelMatrixMixerStereoInputRmsMeterConstraints = {
	leftLevel: AudioChannelMatrixMixerStereoInputRmsLevelConstraints;
	rightLevel: AudioChannelMatrixMixerStereoInputRmsLevelConstraints;
};

export type AudioChannelMeterChange = {
	features: Maybe<AudioChannelMeterFeatureChange>;
	id: Scalars['ID']['output'];
};

export type AudioChannelMeterFeatureChange = {
	acousticEchoCancellationErle: Maybe<AudioChannelAcousticEchoCancellationErleMeter>;
	acousticEchoCancellationPeak: Maybe<AudioChannelAcousticEchoCancellationPeakMeter>;
	acousticEchoCancellationRms: Maybe<AudioChannelAcousticEchoCancellationRmsMeter>;
	activity: Maybe<AudioChannelActivityMeter>;
	automaticGain: Maybe<AudioChannelAutomaticGainMeter>;
	automaticGainControlPeak: Maybe<AudioChannelAutomaticGainControlPeakMeter>;
	automaticGainControlRms: Maybe<AudioChannelAutomaticGainControlRmsMeter>;
	automixGain: Maybe<AudioChannelAutomixGainMeter>;
	automixerGate: Maybe<AudioChannelAutomixerGateMeter>;
	automixerInputPeak: Maybe<AudioChannelAutomixerInputPeakMeter>;
	automixerInputRms: Maybe<AudioChannelAutomixerInputRmsMeter>;
	clip: Maybe<AudioChannelClipMeter>;
	compressorEngaged: Maybe<AudioChannelCompressorEngagedMeter>;
	denoiserNoiseReduction: Maybe<AudioChannelDenoiserNoiseReductionMeter>;
	limiterEngaged: Maybe<AudioChannelLimiterEngagedMeter>;
	matrixMixerInputPeak: Maybe<AudioChannelMatrixMixerInputPeakMeter>;
	matrixMixerInputRms: Maybe<AudioChannelMatrixMixerInputRmsMeter>;
	matrixMixerOutputPeak: Maybe<AudioChannelMatrixMixerOutputPeakMeter>;
	matrixMixerOutputRms: Maybe<AudioChannelMatrixMixerOutputRmsMeter>;
	matrixMixerStereoInputPeak: Maybe<AudioChannelMatrixMixerStereoInputPeakMeter>;
	matrixMixerStereoInputRms: Maybe<AudioChannelMatrixMixerStereoInputRmsMeter>;
	peak: Maybe<AudioChannelPeakMeter>;
	rms: Maybe<AudioChannelRmsMeter>;
	stereoPeak: Maybe<AudioChannelStereoPeakMeter>;
	stereoRms: Maybe<AudioChannelStereoRmsMeter>;
};

export type AudioChannelMicOptimization = {
	mode: MicOptimizationMode | `${MicOptimizationMode}`;
};

export type AudioChannelMicOptimizationChange = {
	micOptimization: Maybe<AudioChannelMicOptimization>;
};

export type AudioChannelMicOptimizationConstraints = {
	mode: AudioChannelMicOptimizationModeConstraints;
};

export type AudioChannelMicOptimizationModeConstraints = {
	options: Array<MicOptimizationMode | `${MicOptimizationMode}`>;
};

export type AudioChannelMicOptimizationUpdateInput = {
	mode: MicOptimizationMode | `${MicOptimizationMode}`;
};

export type AudioChannelMicPolarPattern = {
	pattern: MicPolarPattern | `${MicPolarPattern}`;
};

export type AudioChannelMicPolarPatternChange = {
	micPolarPattern: Maybe<AudioChannelMicPolarPattern>;
};

export type AudioChannelMicPolarPatternConstraints = {
	pattern: AudioChannelMicPolarPatternTypeConstraints;
};

export type AudioChannelMicPolarPatternTypeConstraints = {
	options: Array<MicPolarPattern | `${MicPolarPattern}`>;
};

export type AudioChannelMicPolarPatternUpdateInput = {
	pattern: MicPolarPattern | `${MicPolarPattern}`;
};

export type AudioChannelMicrophoneAngle = {
	/** Auto-focused position of the microphone angle in degrees. The zero position will be indicated physically on the device. */
	autoFocus: Maybe<MicrophoneAngle>;
	/** Statically positioned microphone angle in degrees. The zero position will be indicated physically on the device. */
	fixed: MicrophoneAngle;
};

export type AudioChannelMicrophoneAngleAutoFocusConstraints = IFieldSupportInfo & {
	angle: Maybe<AudioChannelMicrophoneAngleAutoFocusFieldConstraints>;
	supported: Scalars['Boolean']['output'];
};

export type AudioChannelMicrophoneAngleAutoFocusFieldConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type AudioChannelMicrophoneAngleChange = {
	microphoneAngle: Maybe<AudioChannelMicrophoneAngle>;
};

export type AudioChannelMicrophoneAngleConstraints = {
	autoFocus: AudioChannelMicrophoneAngleAutoFocusConstraints;
	fixed: AudioChannelMicrophoneAngleFixedConstraints;
};

export type AudioChannelMicrophoneAngleFixedConstraints = {
	angle: AudioChannelMicrophoneAngleFixedFieldConstraints;
};

export type AudioChannelMicrophoneAngleFixedFieldConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type AudioChannelMicrophoneAngleUpdateInput = {
	angle?: InputMaybe<Scalars['Int']['input']>;
};

export type AudioChannelMonitorMix = {
	/**
	 * A percentage describing a configurable blend of audio from a microphone, and inbound playback audio.
	 * This is used for direct monitoring of multiple simultaneous audio signals.
	 *
	 * 100% - All audio output is currently being captured by the microphone (direct monitoring).
	 *       (e.g. a podcast host can here themselves talk with headphones on with minimal latency without processing from device receiving the signal)
	 * 50%  - Equal amounts of audio from both the microphone signal and the host device playback signal
	 * 0%   - All audio output is the playback audio from the host device (input monitoring).
	 *       (e.g. USB audio input back into the microphone from the operating system for listening to a co-host on a podcast)
	 *       (e.g. a podcast host can listen to their audio post processed by a denoiser on their computer, or from DSP in their DAW)
	 */
	percentage: Scalars['Int']['output'];
};

export type AudioChannelMonitorMixChange = {
	monitorMix: Maybe<AudioChannelMonitorMix>;
};

export type AudioChannelMonitorMixConstraints = {
	/** Describes the range of values for the monitoring mix that are supported on the channel. */
	percentage: AudioChannelMonitorMixTypeConstraints;
};

export type AudioChannelMonitorMixReverb = {
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelMonitorMixReverbChange = {
	monitorMixReverb: Maybe<AudioChannelMonitorMixReverb>;
};

export type AudioChannelMonitorMixReverbUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioChannelMonitorMixTypeConstraints = IIntRangeConstraints & {
	/** Describes the range of values for the monitoring mix that are supported on the channel. */
	range: IntRange;
};

export type AudioChannelMonitorMixUpdateInput = {
	percentage: Scalars['Int']['input'];
};

export type AudioChannelMute = {
	/** Indicates whether or not the channel is muted */
	muted: Scalars['Boolean']['output'];
};

export type AudioChannelMuteChange = {
	mute: Maybe<AudioChannelMute>;
};

export type AudioChannelMuteGroup = {
	/** Indicates whether or not the audio channel is participating in the mute group for linking mute state across multiple channels */
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelMuteGroupChange = {
	muteGroup: Maybe<AudioChannelMuteGroup>;
};

export type AudioChannelMuteGroupUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelMuteLock = {
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelMuteLockChange = {
	muteLock: Maybe<AudioChannelMuteLock>;
};

export type AudioChannelMuteLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type AudioChannelName = {
	/** Name assigned to the channel */
	name: Scalars['String']['output'];
};

export type AudioChannelNameChange = {
	name: Maybe<AudioChannelName>;
};

export type AudioChannelNameConflict = {
	audioChannel: AudioChannel;
	/** Channel name that is in conflict with another channel on the device */
	channelName: Scalars['String']['output'];
};

export type AudioChannelNameConstraints = {
	name: AudioChannelNameFieldConstraints;
};

export type AudioChannelNameFieldConstraints = IStringPatternRangeConstraints & {
	pattern: Scalars['Regex']['output'];
};

export type AudioChannelNameUpdateInput = {
	name: Scalars['String']['input'];
};

export type AudioChannelNoiseReduction = {
	/** Indicates whether or not the noise reduction feature is enabled */
	enabled: Scalars['Boolean']['output'];
	/** Amount of noise reduction applied to the channel */
	level: Maybe<NoiseReductionLevel | `${NoiseReductionLevel}`>;
};

export type AudioChannelNoiseReductionChange = {
	noiseReduction: Maybe<AudioChannelNoiseReduction>;
};

export type AudioChannelNoiseReductionConstraints = {
	level: NoiseReductionLevelConstraints;
};

export type AudioChannelNoiseReductionUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	level?: InputMaybe<NoiseReductionLevel | `${NoiseReductionLevel}`>;
};

export type AudioChannelOperationResult = NodeOperationResult & {
	audioChannel: Maybe<AudioChannel>;
	error: Maybe<OperationError>;
};

export type AudioChannelOutputPath = {
	path: OutputSignalPath | `${OutputSignalPath}`;
};

export type AudioChannelOutputPathChange = {
	outputPath: Maybe<AudioChannelOutputPath>;
};

export type AudioChannelOutputPathConstraints = {
	path: AudioChannelValidOutputPathConstraints;
};

export type AudioChannelOutputPathUpdateInput = {
	path: OutputSignalPath | `${OutputSignalPath}`;
};

export type AudioChannelPan = {
	/** Stereo Channel Pan */
	percentage: Scalars['Int']['output'];
};

export type AudioChannelPanChange = {
	pan: Maybe<AudioChannelPan>;
};

export type AudioChannelPanConstraints = {
	/** Describes the range of values for stereo pan that are supported on this channel. */
	percentage: AudioChannelPanFieldConstraints;
};

export type AudioChannelPanFieldConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type AudioChannelPanUpdateInput = {
	percentage: Scalars['Int']['input'];
};

export type AudioChannelPcPeripheral = {
	peripheralOptions: Array<PcPeripheral>;
	selectedPeripheral: Maybe<PcPeripheral>;
};

export type AudioChannelPcPeripheralChange = {
	pcPeripheral: Maybe<AudioChannelPcPeripheral>;
};

export type AudioChannelPcPeripheralIdUpdateInput = {
	id: Scalars['String']['input'];
};

export type AudioChannelPcPeripheralUpdateInput = {
	selectedPeripheral: AudioChannelPcPeripheralIdUpdateInput;
};

export type AudioChannelPeakMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelPeakMeterConstraints = {
	level: AudioChannelPeakMeterLevelConstraints;
};

export type AudioChannelPeakMeterLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelPhantomPower = {
	/** Phantom power setting in volts */
	volts: Scalars['Int']['output'];
};

export type AudioChannelPhantomPowerChange = {
	phantomPower: Maybe<AudioChannelPhantomPower>;
};

export type AudioChannelPhantomPowerConstraints = {
	/** Describes the list of supported values for the phantom power on the audio channel. */
	volts: PhantomPowerConstraints;
};

export type AudioChannelPhantomPowerUpdateInput = {
	volts: Scalars['Int']['input'];
};

export type AudioChannelPlosiveReduction = {
	/** Indicates whether or not plosive reduction is enabled */
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelPlosiveReductionChange = {
	plosiveReduction: Maybe<AudioChannelPlosiveReduction>;
};

export type AudioChannelPlosiveReductionUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelPostGateFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelPostGateGain = {
	/** Signal gain (dB) after the automixer gating decision has occurred */
	gain: Scalars['Float']['output'];
};

export type AudioChannelPostGateGainChange = {
	postGateGain: Maybe<AudioChannelPostGateGain>;
};

export type AudioChannelPostGateGainConstraints = {
	gain: AudioChannelPostGateFieldConstraints;
};

export type AudioChannelPostGateGainUpdateInput = {
	gain: Scalars['Float']['input'];
};

export type AudioChannelPostGateMute = {
	/** Audio mute status after the automixer gating decision has occurred */
	muted: Scalars['Boolean']['output'];
};

export type AudioChannelPostGateMuteChange = {
	postGateMute: Maybe<AudioChannelPostGateMute>;
};

export type AudioChannelPostGateMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type AudioChannelPostGateSolo = {
	/** Whether or not the audio channel direct out is soloed after the automixer gating decision has occurred. */
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelPostGateSoloChange = {
	postGateSolo: Maybe<AudioChannelPostGateSolo>;
};

export type AudioChannelPostGateSoloUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelReverb = {
	enabled: Scalars['Boolean']['output'];
	intensity: Scalars['Int']['output'];
	type: ReverbType | `${ReverbType}`;
};

export type AudioChannelReverbChange = {
	reverb: Maybe<AudioChannelReverb>;
};

export type AudioChannelReverbConstraints = {
	intensity: AudioChannelReverbIntensityConstraints;
	type: AudioChannelReverbTypeConstraints;
};

export type AudioChannelReverbIntensityConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type AudioChannelReverbTypeConstraints = {
	options: Array<ReverbType | `${ReverbType}`>;
};

export type AudioChannelReverbUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	intensity?: InputMaybe<Scalars['Int']['input']>;
	type?: InputMaybe<ReverbType | `${ReverbType}`>;
};

export type AudioChannelRmsLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelRmsMeter = {
	level: Scalars['Float']['output'];
};

export type AudioChannelRmsMeterConstraints = {
	level: AudioChannelRmsLevelConstraints;
};

export enum AudioChannelRole {
	Filter = 'FILTER',
	Sink = 'SINK',
	Source = 'SOURCE'
}

export type AudioChannelSelectedLoudspeaker = {
	loudspeaker: Loudspeaker | `${Loudspeaker}`;
	loudspeakerOptions: Array<Loudspeaker | `${Loudspeaker}`>;
};

export type AudioChannelSelectedLoudspeakerChange = {
	selectedLoudspeaker: Maybe<AudioChannelSelectedLoudspeaker>;
};

export type AudioChannelSelectedLoudspeakerConstraints = {
	/** @deprecated Use loudspeakerOptions field for dynamic list */
	loudspeaker: LoudspeakerConstraints;
};

export type AudioChannelSelectedLoudspeakerInput = {
	loudspeaker: Loudspeaker | `${Loudspeaker}`;
};

export type AudioChannelSignalGenerator = {
	enabled: Scalars['Boolean']['output'];
	frequency: Scalars['Float']['output'];
	gain: Scalars['Float']['output'];
	signalType: SignalType | `${SignalType}`;
};

export type AudioChannelSignalGeneratorChange = {
	signalGenerator: Maybe<AudioChannelSignalGenerator>;
};

export type AudioChannelSignalGeneratorConstraints = {
	frequency: FloatRangeConstraints;
	gain: Maybe<Array<SignalGeneratorPowerSourceGainConstraints>>;
	signalType: SignalTypeConstraints;
};

export type AudioChannelSignalGeneratorUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	frequency?: InputMaybe<Scalars['Float']['input']>;
	gain?: InputMaybe<Scalars['Float']['input']>;
	signalType?: InputMaybe<SignalType | `${SignalType}`>;
};

export type AudioChannelSolo = {
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelSoloChange = {
	solo: Maybe<AudioChannelSolo>;
};

export type AudioChannelSoloUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelStereoGain = {
	/** Stereo gain (dB) */
	gain: Scalars['Float']['output'];
};

export type AudioChannelStereoGainChange = {
	stereoGain: Maybe<AudioChannelStereoGain>;
};

export type AudioChannelStereoGainConstraints = {
	/** Describes the range of gain values that are supported on the channel while on stereo mode. */
	gain: AudioChannelStereoGainFieldConstraints;
};

export type AudioChannelStereoGainFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelStereoGainUpdateInput = {
	gain: Scalars['Float']['input'];
};

export type AudioChannelStereoPeakLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelStereoPeakMeter = {
	leftLevel: Scalars['Float']['output'];
	rightLevel: Scalars['Float']['output'];
};

export type AudioChannelStereoPeakMeterConstraints = {
	leftLevel: AudioChannelStereoPeakLevelConstraints;
	rightLevel: AudioChannelStereoPeakLevelConstraints;
};

export type AudioChannelStereoReverse = {
	/** True, if the left and right stereo audio is swapped, false otherwise */
	enabled: Scalars['Boolean']['output'];
};

export type AudioChannelStereoReverseChange = {
	stereoReverse: Maybe<AudioChannelStereoReverse>;
};

export type AudioChannelStereoReverseUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type AudioChannelStereoRmsLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelStereoRmsMeter = {
	leftLevel: Scalars['Float']['output'];
	rightLevel: Scalars['Float']['output'];
};

export type AudioChannelStereoRmsMeterConstraints = {
	leftLevel: AudioChannelStereoRmsLevelConstraints;
	rightLevel: AudioChannelStereoRmsLevelConstraints;
};

export type AudioChannelStereoWidth = {
	/** The width of the stereo image expressed as an angle in degrees. */
	angle: Scalars['Int']['output'];
};

export type AudioChannelStereoWidthAngleConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type AudioChannelStereoWidthChange = {
	stereoWidth: Maybe<AudioChannelStereoWidth>;
};

export type AudioChannelStereoWidthConstraints = {
	angle: AudioChannelStereoWidthAngleConstraints;
};

export type AudioChannelStereoWidthUpdateInput = {
	angle: Scalars['Int']['input'];
};

export type AudioChannelSupportedFeatures = {
	acousticEchoCancellation: FeatureSupport;
	acousticEchoCancellationErle: FeatureSupport;
	acousticEchoCancellationMonoReference: FeatureSupport;
	acousticEchoCancellationPeak: FeatureSupport;
	acousticEchoCancellationRms: FeatureSupport;
	acousticEchoCancellationStereoReference: FeatureSupport;
	activity: FeatureSupport;
	analogLevel: FeatureSupport;
	autoLevel: FeatureSupport;
	autoMute: FeatureSupport;
	autoPosition: FeatureSupport;
	automaticGain: FeatureSupport;
	automaticGainControl: FeatureSupport;
	automaticGainControlPeak: FeatureSupport;
	automaticGainControlRms: FeatureSupport;
	automixGain: FeatureSupport;
	automixSourceSelector: FeatureSupport;
	automixer: FeatureSupport;
	automixerGate: FeatureSupport;
	automixerInput: FeatureSupport;
	automixerInputPeak: FeatureSupport;
	automixerInputRms: FeatureSupport;
	automixerVoiceActivityDetection: FeatureSupport;
	carriedByRfChannels: FeatureSupport;
	channelConfiguration: FeatureSupport;
	clip: FeatureSupport;
	compressor: FeatureSupport;
	compressorEngaged: FeatureSupport;
	connectionDetection: FeatureSupport;
	danteEncryptionStatus: FeatureSupport;
	danteRouteReceiver: FeatureSupport;
	danteRouteTransmitter: FeatureSupport;
	delay: FeatureSupport;
	denoiser: FeatureSupport;
	denoiserNoiseReduction: FeatureSupport;
	deverb: FeatureSupport;
	deviceSpecificEqualizerContour: FeatureSupport;
	directOutputTapPoint: FeatureSupport;
	equalizer: FeatureSupport;
	equalizerContour: FeatureSupport;
	equalizerPreset: FeatureSupport;
	faderGroup: FeatureSupport;
	gain: FeatureSupport;
	gainLock: FeatureSupport;
	identify: FeatureSupport;
	inputSource: FeatureSupport;
	lightingBehavior: FeatureSupport;
	limiter: FeatureSupport;
	limiterEngaged: FeatureSupport;
	lobeConfiguration: FeatureSupport;
	lobePosition: FeatureSupport;
	lobeTalkerHeight: FeatureSupport;
	lobeWidth: FeatureSupport;
	logicReceiver: FeatureSupport;
	matrixMixerInputPeak: FeatureSupport;
	matrixMixerInputRms: FeatureSupport;
	matrixMixerOutput: FeatureSupport;
	matrixMixerOutputPeak: FeatureSupport;
	matrixMixerOutputRms: FeatureSupport;
	matrixMixerStereoInputPeak: FeatureSupport;
	matrixMixerStereoInputRms: FeatureSupport;
	micOptimization: FeatureSupport;
	micPolarPattern: FeatureSupport;
	microphoneAngle: FeatureSupport;
	monitorMix: FeatureSupport;
	monitorMixReverb: FeatureSupport;
	mute: FeatureSupport;
	muteGroup: FeatureSupport;
	muteLock: FeatureSupport;
	name: FeatureSupport;
	noiseReduction: FeatureSupport;
	outputPath: FeatureSupport;
	pan: FeatureSupport;
	pcPeripheral: FeatureSupport;
	peak: FeatureSupport;
	phantomPower: FeatureSupport;
	plosiveReduction: FeatureSupport;
	postGateGain: FeatureSupport;
	postGateMute: FeatureSupport;
	postGateSolo: FeatureSupport;
	reverb: FeatureSupport;
	rms: FeatureSupport;
	selectedLoudspeaker: FeatureSupport;
	signalGenerator: FeatureSupport;
	solo: FeatureSupport;
	stereoGain: FeatureSupport;
	stereoPeak: FeatureSupport;
	stereoReverse: FeatureSupport;
	stereoRms: FeatureSupport;
	stereoWidth: FeatureSupport;
	talkerHeight: FeatureSupport;
	usbOutputMode: FeatureSupport;
};

/** height values are scaled to represent in millimeters (mm) */
export type AudioChannelTalkerHeight = {
	height: Scalars['Float']['output'];
};

export type AudioChannelTalkerHeightAutoFocusConstraints = IFieldSupportInfo & {
	height: Maybe<AudioChannelTalkerHeightAutoFocusFieldConstraints>;
	supported: Scalars['Boolean']['output'];
};

export type AudioChannelTalkerHeightAutoFocusFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelTalkerHeightChange = {
	talkerHeight: Maybe<AudioChannelTalkerHeight>;
};

export type AudioChannelTalkerHeightConstraints = {
	height: Maybe<AudioChannelTalkerHeightFieldConstraints>;
};

export type AudioChannelTalkerHeightFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelTalkerHeightFixedConstraints = {
	height: AudioChannelTalkerHeightFixedFieldConstraints;
};

export type AudioChannelTalkerHeightFixedFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioChannelTalkerHeightUpdateInput = {
	height: Scalars['Float']['input'];
};

export type AudioChannelUpdateInput = {
	features?: InputMaybe<AudioChannelFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

/**
 * The USB output mode indicates how the output signal is processed by the USB audio output channel.
 *
 *
 * Mixdown Mode: USB sends “summed” input signals as one mono stream. Great for live-streaming interviews.
 *
 *
 * Tracking Mode: USB sends discrete streams for each input channel which are rendered as separate mono audio files. Great for multitrack recording and musicians recording voice and instrument who want to process their signals separately in post.
 *
 *
 * Stereo Mode: USB sends one Stereo stream that renders as a stereo file with preserved Left & Right stereo contents. Great for voiceover with stereo backing content, or emceeing over a stereo mixdown from a board.
 */
export type AudioChannelUsbOutputMode = {
	mode: UsbOutputMode | `${UsbOutputMode}`;
};

export type AudioChannelUsbOutputModeChange = {
	usbOutputMode: Maybe<AudioChannelUsbOutputMode>;
};

export type AudioChannelUsbOutputModeConstraints = {
	mode: Maybe<AudioChannelUsbOutputModeTypeConstraints>;
};

export type AudioChannelUsbOutputModeTypeConstraints = {
	options: Array<UsbOutputMode | `${UsbOutputMode}`>;
};

export type AudioChannelUsbOutputModeUpdateInput = {
	mode: UsbOutputMode | `${UsbOutputMode}`;
};

export type AudioChannelValidDenoiserLevelConstraints = {
	options: Array<DenoiserLevel | `${DenoiserLevel}`>;
};

export type AudioChannelValidOutputPathConstraints = {
	options: Array<OutputSignalPath | `${OutputSignalPath}`>;
};

export type AudioCoverageArea = Fragment &
	Node & {
		description: AudioCoverageAreaDescription;
		features: AudioCoverageAreaFeatures;
		fragmentId: Scalars['ID']['output'];
		id: Scalars['ID']['output'];
	};

export type AudioCoverageAreaAutomixerGateMeter = {
	open: Scalars['Boolean']['output'];
};

export type AudioCoverageAreaChange = {
	features: AudioCoverageAreaFeatureChange;
	id: Scalars['ID']['output'];
};

export type AudioCoverageAreaConfiguration = {
	/** Indicates whether or not the coverage area is enabled or disabled */
	active: Scalars['Boolean']['output'];
	/** Coordinates of the rectangular bounds of the coverage area */
	coordinates: RectangularCoordinates;
	/**
	 * Dynamic coverage areas have flexible coverage; the microphone intelligently adapts to cover all talkers in the coverage area. Any talker within the boundaries of the coverage area will have microphone coverage (even as they move).
	 *
	 * With Dedicated coverage areas, the microphone assigns coverage to a fixed location all the time, whether a talker is there or not. Dedicated coverage is perfect for a podium or VIP seat that needs priority coverage at all times.
	 */
	type: AudioCoverageAreaType | `${AudioCoverageAreaType}`;
};

export type AudioCoverageAreaConfigurationChange = {
	configuration: Maybe<AudioCoverageAreaConfiguration>;
};

export type AudioCoverageAreaConfigurationConstraints = {
	type: AudioCoverageAreaTypeConstraints;
};

export type AudioCoverageAreaConstraints = {
	configuration: Maybe<AudioCoverageAreaConfigurationConstraints>;
	gain: Maybe<AudioCoverageAreaGainConstraints>;
	peak: Maybe<AudioCoverageAreaPeakMeterConstraints>;
	rms: Maybe<AudioCoverageAreaRmsMeterConstraints>;
};

export type AudioCoverageAreaDescription = {
	constraints: AudioCoverageAreaConstraints;
	features: AudioCoverageAreaSupportedFeatures;
	index: Scalars['Int']['output'];
};

export type AudioCoverageAreaFeatureChange =
	| AudioCoverageAreaConfigurationChange
	| AudioCoverageAreaGainChange
	| AudioCoverageAreaMuteChange;

export type AudioCoverageAreaFeatureUpdateInput = {
	gain?: InputMaybe<AudioCoverageAreaGainUpdateInput>;
	mute?: InputMaybe<AudioCoverageAreaMuteUpdateInput>;
};

export type AudioCoverageAreaFeatures = {
	automixerGate: Maybe<AudioCoverageAreaAutomixerGateMeter>;
	configuration: Maybe<AudioCoverageAreaConfiguration>;
	gain: Maybe<AudioCoverageAreaGain>;
	mute: Maybe<AudioCoverageAreaMute>;
	peak: Maybe<AudioCoverageAreaPeakMeter>;
	rms: Maybe<AudioCoverageAreaRmsMeter>;
};

export type AudioCoverageAreaGain = {
	gain: Scalars['Float']['output'];
};

export type AudioCoverageAreaGainChange = {
	gain: Maybe<AudioCoverageAreaGain>;
};

export type AudioCoverageAreaGainConstraints = {
	gain: Maybe<AudioCoverageAreaGainFieldConstraints>;
};

export type AudioCoverageAreaGainFieldConstraints = IFloatRangeConstraints & {
	/** Describes the range of values for the signal gain that are supported on the coverage area. */
	range: FloatRange;
};

export type AudioCoverageAreaGainUpdateInput = {
	gain: Scalars['Float']['input'];
};

export type AudioCoverageAreaMeterChange = {
	features: Maybe<AudioCoverageAreaMeterFeatureChange>;
	id: Scalars['ID']['output'];
};

export type AudioCoverageAreaMeterFeatureChange = {
	automixerGate: Maybe<AudioCoverageAreaAutomixerGateMeter>;
	peak: Maybe<AudioCoverageAreaPeakMeter>;
	rms: Maybe<AudioCoverageAreaRmsMeter>;
};

export type AudioCoverageAreaMute = {
	/** Indicates whether or not the coverage area is muted */
	muted: Scalars['Boolean']['output'];
};

export type AudioCoverageAreaMuteChange = {
	mute: Maybe<AudioCoverageAreaMute>;
};

export type AudioCoverageAreaMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type AudioCoverageAreaOperationResult = NodeOperationResult & {
	audioCoverageArea: Maybe<AudioCoverageArea>;
	error: Maybe<OperationError>;
};

export type AudioCoverageAreaPeakMeter = {
	level: Scalars['Float']['output'];
};

export type AudioCoverageAreaPeakMeterConstraints = {
	level: AudioCoverageAreaPeakMeterLevelConstraints;
};

export type AudioCoverageAreaPeakMeterLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioCoverageAreaRmsMeter = {
	level: Scalars['Float']['output'];
};

export type AudioCoverageAreaRmsMeterConstraints = {
	level: Maybe<AudioCoverageRmsMeterLevelConstraints>;
};

export type AudioCoverageAreaSupportedFeatures = {
	automixerGate: FeatureSupport;
	configuration: FeatureSupport;
	gain: FeatureSupport;
	mute: FeatureSupport;
	peak: FeatureSupport;
	rms: FeatureSupport;
};

export enum AudioCoverageAreaType {
	Dedicated = 'DEDICATED',
	Dynamic = 'DYNAMIC'
}

export type AudioCoverageAreaTypeConstraints = {
	options: Array<RectangularAreaConstraints>;
};

export type AudioCoverageAreaUpdateInput = {
	features?: InputMaybe<AudioCoverageAreaFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export type AudioCoverageRmsMeterLevelConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AudioEncryptionSettingsInput = {
	id: Scalars['ID']['input'];
	passphrase: Scalars['String']['input'];
};

export enum AudioMetersLightingMode {
	Breathing = 'BREATHING',
	Live = 'LIVE',
	Solid = 'SOLID'
}

export type AudioNetworkConfiguration = {
	gateway?: InputMaybe<Scalars['IpAddress']['input']>;
	ipAddress?: InputMaybe<Scalars['IpAddress']['input']>;
	ipMode: IpMode | `${IpMode}`;
	subnetMask?: InputMaybe<Scalars['IpAddress']['input']>;
};

export type AudioNetworkNameSyncInput = {
	id: Scalars['ID']['input'];
	/**
	 * By default, prior to setting Audio Network Channel Names, Shure (generic) channel names will be checked for conflicts.
	 * This validation can be skipped (if, perhaps, it has already been performed prior to invoking
	 * synchronization).
	 */
	validateChannelNameConflicts?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * By default, prior to setting Audio Network Device Name, discovered devices will be checked for conflicts.
	 * This validation can be skipped (if, perhaps, it has already been performed prior to invoking synchronization).
	 */
	validateDeviceNameConflicts?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AudioNetworkNameSyncResult = {
	audioChannelNameConflicts: Maybe<Array<AudioChannelNameConflict>>;
	deviceNameConflicts: Maybe<Array<DeviceNameConflict>>;
	success: Scalars['Boolean']['output'];
};

export enum AutoLevelConfiguration {
	Loud = 'LOUD',
	Normal = 'NORMAL',
	Quiet = 'QUIET'
}

export type AutoLevelConfigurationConstraints = IFieldSupportInfo & {
	options: Maybe<Array<AutoLevelConfiguration | `${AutoLevelConfiguration}`>>;
	subscribe: Scalars['Boolean']['output'];
	supported: Scalars['Boolean']['output'];
};

export enum AutoLevelMode {
	Auto = 'AUTO',
	Manual = 'MANUAL'
}

export type AutoLevelModeConstraints = IFieldSupportInfo & {
	options: Maybe<Array<AutoLevelMode | `${AutoLevelMode}`>>;
	supported: Scalars['Boolean']['output'];
};

export enum AutoLevelPosition {
	Far = 'FAR',
	Near = 'NEAR'
}

export type AutoLevelPositionConstraints = IFieldSupportInfo & {
	options: Maybe<Array<AutoLevelPosition | `${AutoLevelPosition}`>>;
	supported: Scalars['Boolean']['output'];
};

export enum AutoLevelTone {
	Bright = 'BRIGHT',
	Dark = 'DARK',
	Natural = 'NATURAL'
}

export type AutoLevelToneConstraints = IFieldSupportInfo & {
	options: Maybe<Array<AutoLevelTone | `${AutoLevelTone}`>>;
	supported: Scalars['Boolean']['output'];
};

export enum AutoPositionStatus {
	Failed = 'FAILED',
	Inactive = 'INACTIVE',
	Processing = 'PROCESSING',
	Recording = 'RECORDING'
}

export type AutomixGatingSensitivityConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type AutomixHoldTimeConstraints = IDurationRangeConstraints & {
	range: DurationRange;
};

export type AutomixMaximumOpenChannelsConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export enum AutomixMode {
	/**
	 * Emulates the Shure SCM820 automixer default settings. It is renowned for fast-acting, seamless channel gating and consistent perceived ambient sound levels.
	 * Off-attenuation in this mode is fixed at -12dB per channel, regardless of the number of open channels.
	 */
	Classic = 'CLASSIC',
	/** Provides manual control over all automixing parameters. This mode is useful when adjustments must be made to one of the preset modes to fit a particular application. */
	Custom = 'CUSTOM',
	/**
	 * Dynamically balances system gain between open and closed audio channels.
	 * The system gain remains consistent by distributing gain across audio channels to equal one open audio channel.
	 * The scaled gain structure helps to reduce noise when there is a high audio channel count.
	 * When fewer audio channels are used, the off attenuation setting is lower and provides transparent gating.
	 */
	GainSharing = 'GAIN_SHARING',
	/**
	 * Delivers fast-acting, seamless audio channel gating and consistent perceived ambient sound levels.
	 * Off attenuation  is fixed at -20 dB, regardless of the number of open audio channels.
	 */
	Gating = 'GATING',
	/**
	 * Sums all active audio channels and sends the summed signal over a single Dante output.
	 * The gain of all audio channels apply to the summed output.
	 */
	Manual = 'MANUAL',
	/**
	 * Off-attenuation settings for each channel are scaled, depending on the number of open channels.
	 * The scaled gain structure helps to reduce noise when there is a high channel count.
	 * When fewer channels are used, the lower off-attenuation provides transparent gating.
	 */
	Smooth = 'SMOOTH'
}

export type AutomixModeConstraints = {
	options: Array<AutomixMode | `${AutomixMode}`>;
};

export type AutomixOffAttenuationConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type AutomixSourceSelectorInput = {
	/** Node Id of the channel where automixing is performed on a source channel */
	automix: Scalars['ID']['input'];
	/** Node Id of the source channel to be used as selector for automixing. Optional/Null represents a behavior to deselect a source channel if selected */
	source?: InputMaybe<Scalars['ID']['input']>;
};

export enum AutomixerInputDisengagedBehavior {
	FollowMuteColor = 'FOLLOW_MUTE_COLOR',
	Off = 'OFF'
}

export type AutomixerInputDisengagedBehaviorConstraints = {
	options: Array<AutomixerInputDisengagedBehavior | `${AutomixerInputDisengagedBehavior}`>;
};

/** The state machine describing the lifecycle of a battery-powered device. */
export enum BatteryState {
	Calculating = 'CALCULATING',
	Charging = 'CHARGING',
	Discharging = 'DISCHARGING',
	Empty = 'EMPTY',
	Full = 'FULL',
	OptimalStorage = 'OPTIMAL_STORAGE'
}

export type BetaDeviceIdentitity = {
	category: Scalars['String']['input'];
	versions: Array<Scalars['SemanticVersion']['input']>;
};

export enum BodypackMicrophoneMode {
	External = 'EXTERNAL',
	Internal = 'INTERNAL'
}

export type BodypackMicrophoneModeConstraints = {
	options: Array<BodypackMicrophoneMode | `${BodypackMicrophoneMode}`>;
};

export type BypassCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
};

export type CancelPendingActivationLicenseInput = {
	activationKey: Scalars['String']['input'];
	id: Scalars['ID']['input'];
};

export type Certificate8021XValidationResult = {
	certificateInfo: Maybe<CertificateInfo8021X>;
	error: Maybe<OperationError>;
};

export type CertificateInfo8021X = {
	expirationDate: Scalars['DateTime']['output'];
	hash: Maybe<Scalars['String']['output']>;
	issuer: Maybe<Scalars['String']['output']>;
	validFromDate: Scalars['DateTime']['output'];
};

export type CertificateInput = {
	anonymousId?: InputMaybe<Scalars['String']['input']>;
	clientCertificate?: InputMaybe<Scalars['String']['input']>;
	passphrase: Scalars['String']['input'];
	rootCertificate: Scalars['String']['input'];
	username: Scalars['String']['input'];
};

export type ChargerBay = Fragment &
	Node & {
		description: ChargerBayDescription;
		features: ChargerBayFeatures;
		fragmentId: Scalars['ID']['output'];
		id: Scalars['ID']['output'];
	};

export type ChargerBayAssociatedAccessPoint = {
	/** device and rfChannel fields are null when there is an association established with an access point but the access point isn't discovered */
	device: Maybe<Device>;
	rfChannel: Maybe<RfChannel>;
};

export type ChargerBayAssociation = {
	/** associatedAccessPoint field is null when there is no association established with an access point */
	associatedAccessPoint: Maybe<ChargerBayAssociatedAccessPoint>;
};

export type ChargerBayAssociationChange = {
	association: Maybe<ChargerBayAssociation>;
};

export type ChargerBayBatteryPresent = {
	batteryPresent: Scalars['Boolean']['output'];
};

export type ChargerBayBatteryPresentChange = {
	batteryPresent: Maybe<ChargerBayBatteryPresent>;
};

export type ChargerBayChange = {
	features: ChargerBayFeatureChange;
	id: Scalars['ID']['output'];
};

export type ChargerBayChargingMode = {
	mode: Maybe<ChargingMode | `${ChargingMode}`>;
};

export type ChargerBayChargingModeChange = {
	chargingMode: Maybe<ChargerBayChargingMode>;
};

export type ChargerBayChargingModeConstraints = {
	mode: ChargingModeConstraints;
};

export type ChargerBayConstraints = {
	/** @deprecated Proof of concept of charger bay on device.  Do not use. */
	chargingMode: Maybe<ChargerBayChargingModeConstraints>;
};

export type ChargerBayDescription = {
	constraints: ChargerBayConstraints;
	features: ChargerBaySupportedFeatures;
	index: Scalars['Int']['output'];
};

export type ChargerBayDockedDevice = {
	/** device field is null when there is a docked transmitter on the charger bay but the transmitter isn't discovered */
	device: Maybe<Device>;
};

export type ChargerBayDockedDeviceChange = {
	dockedDevice: Maybe<ChargerBayDockedDevice>;
};

export type ChargerBayFeatureChange =
	| ChargerBayAssociationChange
	| ChargerBayBatteryPresentChange
	| ChargerBayChargingModeChange
	| ChargerBayDockedDeviceChange;

export type ChargerBayFeatures = {
	association: Maybe<ChargerBayAssociation>;
	/** @deprecated Proof of concept of charger bay on device.  Do not use. */
	batteryPresent: Maybe<ChargerBayBatteryPresent>;
	/** @deprecated Proof of concept of charger bay on device.  Do not use. */
	chargingMode: Maybe<ChargerBayChargingMode>;
	dockedDevice: Maybe<ChargerBayDockedDevice>;
};

export type ChargerBayOperationResult = NodeOperationResult & {
	chargerBay: Maybe<ChargerBay>;
	error: Maybe<OperationError>;
};

export type ChargerBaySupportedFeatures = {
	association: FeatureSupport;
	/** @deprecated Proof of concept of charger bay on device.  Do not use. */
	batteryPresent: FeatureSupport;
	/** @deprecated Proof of concept of charger bay on device.  Do not use. */
	chargingMode: FeatureSupport;
	dockedDevice: FeatureSupport;
	linking: FeatureSupport;
};

export enum ChargingMode {
	Calculating = 'CALCULATING',
	Full = 'FULL',
	Normal = 'NORMAL',
	Warm = 'WARM',
	Warmandfull = 'WARMANDFULL'
}

export type ChargingModeConstraints = {
	options: Maybe<Array<ChargingMode | `${ChargingMode}`>>;
};

export type ClearAssociationResult = NodeOperationResult & {
	error: Maybe<OperationError>;
	result: Maybe<ClearedAssociation>;
};

export type ClearedAssociation = ChargerBay | RfChannel;

export type CloneRoomAudioRouteUpdateEvent = {
	audioRouteUpdate: CloneRoomAudioRouteUpdateEventDetails;
	originalRoom: Scalars['ID']['output'];
	progressPercentage: Maybe<Scalars['Int']['output']>;
};

export type CloneRoomAudioRouteUpdateEventDetails = {
	clonedAudioRoutes: Scalars['Int']['output'];
	totalAudioRoutes: Scalars['Int']['output'];
};

export type CloneRoomControlRouteUpdateEvent = {
	controlRouteUpdate: CloneRoomControlRouteUpdateEventDetails;
	originalRoom: Scalars['ID']['output'];
	progressPercentage: Maybe<Scalars['Int']['output']>;
};

export type CloneRoomControlRouteUpdateEventDetails = {
	clonedControlRoutes: Scalars['Int']['output'];
	totalControlRoutes: Scalars['Int']['output'];
};

export type CloneRoomDeviceUpdateEvent = {
	deviceUpdate: CloneRoomDeviceUpdateEventDetails;
	originalRoom: Scalars['ID']['output'];
	progressPercentage: Maybe<Scalars['Int']['output']>;
};

export type CloneRoomDeviceUpdateEventDetails = {
	clonedDevices: Scalars['Int']['output'];
	totalDevices: Scalars['Int']['output'];
};

export type CloneRoomEvent =
	| CloneRoomAudioRouteUpdateEvent
	| CloneRoomControlRouteUpdateEvent
	| CloneRoomDeviceUpdateEvent
	| CloneRoomResultEvent
	| CloneRoomStartedEvent;

export type CloneRoomInput = {
	cloningStrategy?: InputMaybe<RoomCloningStrategy | `${RoomCloningStrategy}`>;
	description?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
	sourceRoom: Scalars['ID']['input'];
};

export type CloneRoomResultEvent = {
	originalRoom: Scalars['ID']['output'];
	result: CloneRoomResultEventDetails;
};

export type CloneRoomResultEventDetails = {
	clonedRoom: Maybe<Scalars['ID']['output']>;
	conflict: Scalars['Boolean']['output'];
	status: CloneRoomStatus | `${CloneRoomStatus}`;
};

export type CloneRoomStartEventDetails = {
	clonedRoom: Scalars['ID']['output'];
};

export type CloneRoomStartedEvent = {
	originalRoom: Scalars['ID']['output'];
	progressPercentage: Maybe<Scalars['Int']['output']>;
	started: CloneRoomStartEventDetails;
};

export enum CloneRoomStatus {
	AudioRouteCloningFailed = 'AUDIO_ROUTE_CLONING_FAILED',
	Canceled = 'CANCELED',
	ControlRouteCloningFailed = 'CONTROL_ROUTE_CLONING_FAILED',
	DeviceCloningFailed = 'DEVICE_CLONING_FAILED',
	InternalPrecondFailed = 'INTERNAL_PRECOND_FAILED',
	OriginRoomCompletePrecondFailed = 'ORIGIN_ROOM_COMPLETE_PRECOND_FAILED',
	OriginRoomConflictPrecondFailed = 'ORIGIN_ROOM_CONFLICT_PRECOND_FAILED',
	OriginRoomExistsprecondFailed = 'ORIGIN_ROOM_EXISTSPRECOND_FAILED',
	OriginRoomUnavailablePrecondFailed = 'ORIGIN_ROOM_UNAVAILABLE_PRECOND_FAILED',
	OrigRoomNonEmptyPrecondFailed = 'ORIG_ROOM_NON_EMPTY_PRECOND_FAILED',
	RoomCloningTimeout = 'ROOM_CLONING_TIMEOUT',
	RoomCreationFailed = 'ROOM_CREATION_FAILED',
	Success = 'SUCCESS'
}

export enum ColorTheme {
	Custom = 'CUSTOM',
	Default = 'DEFAULT',
	Fruity = 'FRUITY',
	Seaside = 'SEASIDE',
	Shure = 'SHURE',
	Space = 'SPACE'
}

export type ColorThemeConstraints = {
	options: Array<ColorTheme | `${ColorTheme}`>;
};

/** The means by which this API is communicating with the device */
export type CommunicationProtocol = {
	/** An address which uniquely identifies this device via its given communication protocol */
	address: Maybe<Scalars['String']['output']>;
	dmp: Maybe<DmpCommunicationProtocol>;
	type: Scalars['String']['output'];
};

/**
 * Describes the state of compatibility between this software, and the discovered device.
 * Different states may indicate a need to upgrade either the device firmware, or this software,
 * in order to use the latest available features.
 */
export enum CompatibilityLevel {
	/** The device firmware is the latest version tested with this software version. */
	CompatibleLatest = 'COMPATIBLE_LATEST',
	/**
	 * The device firmware is ahead of the latest version this software was tested with, but can be completely interacted with.
	 * There may be newer versions of this software which may interact with new features of this device.
	 */
	CompatibleNew = 'COMPATIBLE_NEW',
	/**
	 * The device firmware is behind the latest available, but can be completely interacted with.
	 * There are likely newer versions of firmware available for this device which may have additional features which could be interacted with.
	 * Updating the device's firmware is recommended.
	 */
	CompatibleOld = 'COMPATIBLE_OLD',
	/**
	 * The device firmware is not supported, and therefore no capability interaction is supported.
	 * Update this software to interact with the device.
	 */
	IncompatibleTooNew = 'INCOMPATIBLE_TOO_NEW',
	/**
	 * The device firmware is not supported, and therefore no capability interaction is supported.
	 * Update the device's firmware to interact with the device.
	 */
	IncompatibleTooOld = 'INCOMPATIBLE_TOO_OLD'
}

export enum CompressorLevel {
	Heavy = 'HEAVY',
	Light = 'LIGHT',
	Medium = 'MEDIUM',
	Off = 'OFF'
}

export enum ConferencingOperationMode {
	Conference = 'CONFERENCE',
	Custom = 'CUSTOM',
	Direct = 'DIRECT',
	Presentation = 'PRESENTATION'
}

export type ConferencingOperationModeConstraints = {
	options: Array<ConferencingOperationMode | `${ConferencingOperationMode}`>;
};

export type ConfigInput8021X = {
	eapMd5?: InputMaybe<UsernamePassphraseInput>;
	peapGtc?: InputMaybe<CertificateInput>;
	peapMschapv2?: InputMaybe<CertificateInput>;
	pwd?: InputMaybe<UsernamePassphraseInput>;
	tls?: InputMaybe<TlsCertificateInput>;
	ttlsGtc?: InputMaybe<CertificateInput>;
	ttlsMd5?: InputMaybe<CertificateInput>;
	ttlsMschapv2?: InputMaybe<CertificateInput>;
	ttlsPap?: InputMaybe<CertificateInput>;
};

export type ConfigurationFilterTypeConstraints = {
	options: Maybe<Array<EqFilterType | `${EqFilterType}`>>;
	supported: Scalars['Boolean']['output'];
};

export type ConnectedDeviceLabel = {
	label: Scalars['String']['output'];
};

export type ConnectedDeviceLabelChange = {
	connectedDeviceLabel: Maybe<ConnectedDeviceLabel>;
};

export type ConnectedDeviceLabelConstraints = {
	label: ConnectedDeviceLabelFieldConstraints;
};

export type ConnectedDeviceLabelFieldConstraints = IUtf8StringMaxBytesConstraints & {
	utf8MaxBytes: Scalars['Int']['output'];
};

export type ConnectedDeviceLabelUpdateInput = {
	label: Scalars['String']['input'];
};

export enum CoverageMode {
	Automatic = 'AUTOMATIC',
	Manual = 'MANUAL'
}

export type CreateCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
	passphrase: Scalars['String']['input'];
};

export type CreateRoomInput = {
	created?: InputMaybe<Scalars['DateTime']['input']>;
	description: Scalars['String']['input'];
	devices: Array<Scalars['ID']['input']>;
	dimensions?: InputMaybe<RoomDimensionsInput>;
	name: Scalars['String']['input'];
};

export type CreateRouteOperationResult = NodeOperationResult & {
	error: Maybe<OperationError>;
	receiveDevice: Maybe<Device>;
	transmitDevice: Maybe<Device>;
};

export enum CredentialsState {
	InitializedNotSet = 'INITIALIZED_NOT_SET',
	Set = 'SET',
	Uninitialized = 'UNINITIALIZED'
}

export type CrossPoint = Fragment &
	Node & {
		description: CrossPointDescription;
		features: CrossPointFeatures;
		fragmentId: Scalars['ID']['output'];
		id: Scalars['ID']['output'];
		inputChannel: CrossPointInputAudioChannel;
	};

export type CrossPointChange = {
	features: CrossPointFeatureChange;
	id: Scalars['ID']['output'];
};

export type CrossPointConfiguration = {
	/**
	 * Whether or not to route the input audio channel specified by this cross point node to
	 * the output audio channel specified by the audio channel implementing 'matrixMixerOutput' capability
	 */
	enabled: Maybe<Scalars['Boolean']['output']>;
};

export type CrossPointConfigurationChange = {
	configuration: Maybe<CrossPointConfiguration>;
};

export type CrossPointConfigurationUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrossPointConstraints = {
	gain: Maybe<CrossPointGainConstraints>;
};

export type CrossPointDescription = {
	constraints: CrossPointConstraints;
	features: CrossPointSupportedFeatures;
};

export type CrossPointFeatureChange = CrossPointConfigurationChange | CrossPointGainChange;

export type CrossPointFeatureUpdateInput = {
	configuration?: InputMaybe<CrossPointConfigurationUpdateInput>;
	gain?: InputMaybe<CrossPointGainUpdateInput>;
};

export type CrossPointFeatures = {
	configuration: Maybe<CrossPointConfiguration>;
	gain: Maybe<CrossPointGain>;
};

/** Gain applied to the input audio sent to the output channel */
export type CrossPointGain = {
	gain: Maybe<Scalars['Float']['output']>;
};

export type CrossPointGainChange = {
	gain: Maybe<CrossPointGain>;
};

export type CrossPointGainConstraints = {
	gain: Maybe<CrossPointGainFieldConstraints>;
};

export type CrossPointGainFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type CrossPointGainUpdateInput = {
	gain?: InputMaybe<Scalars['Float']['input']>;
};

export type CrossPointInputAudioChannel = AudioChannel | StereoAudioChannel;

export type CrossPointOperationResult = NodeOperationResult & {
	crossPoint: Maybe<CrossPoint>;
	error: Maybe<OperationError>;
};

export type CrossPointSupportedFeatures = {
	configuration: FeatureSupport;
	gain: FeatureSupport;
};

export type CrossPointUpdateInput = {
	features?: InputMaybe<CrossPointFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export enum DanteAudioEncryptionStatus {
	/** Audio encryption is enabled between the routed devices, and the symmetric encryption/decryiption key matches between the routed devices. */
	Active = 'ACTIVE',
	/**
	 * Audio encryption is inactive because of one or more of the following:
	 * 1) It is not enabled on this device, and/or the routed device
	 * 2) There is no route between this audio channel and another Shure device which supports Dante audio encryption.
	 */
	Inactive = 'INACTIVE',
	/**
	 * Audio encryption is enabled on this audio channel, but the symmetric encryption/decryiption does not match between the devices. No audio shall pass.
	 * This state will be represented on the audio receive channel, and not on the transmit audio channel (as the transmit audio channel can go to multiple receive channels, and the key may be a match for other receivers).
	 */
	KeyMismatch = 'KEY_MISMATCH'
}

export type DanteAudioRouteSubscription = {
	/** Whether or not the subscription is transmitting audio. */
	active: Scalars['Boolean']['output'];
	/**
	 * The name of the Dante subscription this receiver is subscribed to. E.g. DanteChannelName@DanteDeviceName.
	 * Replaces a self-subscription with the actual Dante device name.
	 */
	name: Scalars['String']['output'];
	/**
	 * Information about the Shure device transmitting audio. Null in the following conditions:
	 * 1) Rx is discovered before Tx which Rx is listening to
	 * 2) Third party device
	 */
	transmitter: Maybe<DanteAudioRouteTransmitter>;
};

export type DanteAudioRouteTransmitter = {
	/** AudioChannel of the Shure device transmitting audio. */
	audioChannel: AudioChannel;
	/** The Shure device transmitting audio. */
	device: Device;
};

export type DeactivateLicenseInput = {
	activationKey: Scalars['String']['input'];
	count: Scalars['Int']['input'];
	id: Scalars['ID']['input'];
};

export type DeleteRouteOperationResult = NodeOperationResult & {
	error: Maybe<OperationError>;
	receiveDevice: Maybe<Device>;
};

export enum DenoiserLevel {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM'
}

export enum DeverbIntensity {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM'
}

export type Device = Node & {
	/** The interface used by the device to advertise it's traits. */
	advertisedInterface: InterfaceDetails;
	compatibility: CompatibilityLevel | `${CompatibilityLevel}`;
	description: DeviceDescription;
	features: DeviceFeatures;
	hardwareId: Scalars['Guid']['output'];
	id: Scalars['ID']['output'];
	/**
	 * The most applicable interface known which is being used to interact with the device.
	 * When a matching interface is not available to the application,
	 * the application assumes forwards compatibility within the same interface ID major version.
	 * If the device's advertisedInterface is in a greater major version than is known by this API,
	 * then the interface presented here will be a different interface used to preserve the lowest
	 * common denominator of interactivity.
	 */
	interface: InterfaceDetails;
	protocol: CommunicationProtocol;
	status: DeviceStatus | `${DeviceStatus}`;
	/** Whether or not the device being interacted with is virtualized to simulate the behavior of real hardware */
	virtual: Scalars['Boolean']['output'];
};

export type DeviceActivePreset = {
	activePreset: Maybe<UserPreset>;
};

export type DeviceActivePresetChange = {
	activePreset: Maybe<DeviceActivePreset>;
};

/**
 * Event emitted whenever a device is added under management
 * (e.g. discovered for the first time as DeviceState.Discovered)
 */
export type DeviceAdded = {
	added: Device;
};

export type DeviceAllEqualizersBypass = {
	bypassed: Scalars['Boolean']['output'];
};

export type DeviceAllEqualizersBypassChange = {
	allEqualizersBypass: Maybe<DeviceAllEqualizersBypass>;
};

export type DeviceAllEqualizersBypassUpdateInput = {
	bypassed: Scalars['Boolean']['input'];
};

export type DeviceAllIntellimixBypass = {
	bypassed: Scalars['Boolean']['output'];
};

export type DeviceAllIntellimixBypassChange = {
	allIntellimixBypass: Maybe<DeviceAllIntellimixBypass>;
};

export type DeviceAllIntellimixBypassUpdateInput = {
	bypassed: Scalars['Boolean']['input'];
};

export type DeviceAnalogAudioOutputMode = {
	mode: AnalogAudioOutputMode | `${AnalogAudioOutputMode}`;
};

export type DeviceAnalogAudioOutputModeChange = {
	analogAudioOutputMode: Maybe<DeviceAnalogAudioOutputMode>;
};

export type DeviceAnalogAudioOutputModeConstraints = {
	mode: Maybe<AnalogAudioOutputModeConstraints>;
};

export type DeviceAnalogAudioOutputModeUpdateInput = {
	mode: AnalogAudioOutputMode | `${AnalogAudioOutputMode}`;
};

export type DeviceAnalogLogic = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceAnalogLogicChange = {
	analogLogic: Maybe<DeviceAnalogLogic>;
};

export type DeviceAnalogLogicPresetSwitch = {
	/** When analogLogic is enabled, a user can select which preset(1 or 2) will be loaded when there is signal. */
	mode: AnalogLogicPresetSwitch | `${AnalogLogicPresetSwitch}`;
};

export type DeviceAnalogLogicPresetSwitchChange = {
	analogLogicPresetSwitch: Maybe<DeviceAnalogLogicPresetSwitch>;
};

export type DeviceAnalogLogicPresetSwitchConstraints = {
	mode: Maybe<DeviceAnalogLogicPresetSwitchModeConstraints>;
};

export type DeviceAnalogLogicPresetSwitchModeConstraints = {
	options: Array<AnalogLogicPresetSwitch | `${AnalogLogicPresetSwitch}`>;
};

export type DeviceAnalogLogicPresetSwitchStatus = {
	status: AnalogLogicPresetSwitchStatus | `${AnalogLogicPresetSwitchStatus}`;
};

export type DeviceAnalogLogicPresetSwitchStatusChange = {
	analogLogicPresetSwitchStatus: Maybe<DeviceAnalogLogicPresetSwitchStatus>;
};

export type DeviceAnalogLogicPresetSwitchStatusConstraints = {
	status: Maybe<AnalogLogicPresetSwitchStatusConstraints>;
};

export type DeviceAnalogLogicPresetSwitchUpdateInput = {
	mode: AnalogLogicPresetSwitch | `${AnalogLogicPresetSwitch}`;
};

export type DeviceAnalogLogicUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceAssociationInput = {
	chargerBay: Scalars['ID']['input'];
	rfChannel: Scalars['ID']['input'];
};

export type DeviceAssociationResult = {
	chargerBay: Maybe<ChargerBay>;
	rfChannel: Maybe<RfChannel>;
	status: AssociationStatus | `${AssociationStatus}`;
};

export type DeviceAudioAutoFocus = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceAudioAutoFocusChange = {
	audioAutoFocus: Maybe<DeviceAudioAutoFocus>;
};

export type DeviceAudioAutoFocusUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceAudioChannelCount = {
	count: Scalars['Int']['output'];
};

export type DeviceAudioChannelCountChange = {
	audioChannelCount: Maybe<DeviceAudioChannelCount>;
};

export type DeviceAudioChannelCountConstraints = {
	count: DeviceAudioChannelCountCountConstraints;
};

export type DeviceAudioChannelCountCountConstraints = {
	options: Array<Scalars['Int']['output']>;
};

export type DeviceAudioChannels = {
	/** Audio channels present on the device */
	audioChannels: Array<AudioChannel>;
};

export type DeviceAudioChannelsChange = {
	audioChannels: Maybe<DeviceAudioChannels>;
};

export type DeviceAudioChannelsConstraints = {
	groupNames: Array<Scalars['AudioChannelGroupName']['output']>;
	/** @deprecated Use the 'groupName' field instead to ensure your code is open to extension to new audio channel groups. This enum is no longer preferred since it regularly breaks on expanding the available enum members to cover new channel groupings for new devices. */
	groups: Array<AudioChannelGroup | `${AudioChannelGroup}`>;
};

export type DeviceAudioCoverageAreas = {
	audioCoverageAreas: Array<AudioCoverageArea>;
};

export type DeviceAudioMute = {
	muted: Scalars['Boolean']['output'];
};

export type DeviceAudioMuteChange = {
	audioMute: Maybe<DeviceAudioMute>;
};

export type DeviceAudioMuteControl = {
	function: MuteControlFunction | `${MuteControlFunction}`;
	mode: MuteButtonMode | `${MuteButtonMode}`;
};

export type DeviceAudioMuteControlChange = {
	audioMuteControl: Maybe<DeviceAudioMuteControl>;
};

export type DeviceAudioMuteControlConstraints = {
	function: Maybe<DeviceAudioMuteControlFunctionConstraints>;
	mode: Maybe<DeviceAudioMuteControlModeConstraints>;
};

export type DeviceAudioMuteControlFunctionConstraints = {
	options: Array<MuteControlFunction | `${MuteControlFunction}`>;
};

export type DeviceAudioMuteControlGroup = {
	associatedDevices: Array<Device>;
};

export type DeviceAudioMuteControlGroupChange = {
	audioMuteControlGroup: Maybe<DeviceAudioMuteControlGroup>;
};

export type DeviceAudioMuteControlModeConstraints = {
	options: Array<MuteButtonMode | `${MuteButtonMode}`>;
};

export type DeviceAudioMuteControlUpdateInput = {
	function?: InputMaybe<MuteControlFunction | `${MuteControlFunction}`>;
	mode?: InputMaybe<MuteButtonMode | `${MuteButtonMode}`>;
};

export type DeviceAudioMuteDefault = {
	muted: Scalars['Boolean']['output'];
};

export type DeviceAudioMuteDefaultChange = {
	audioMuteDefault: Maybe<DeviceAudioMuteDefault>;
};

export type DeviceAudioMuteDefaultUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type DeviceAudioMuteLock = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceAudioMuteLockChange = {
	audioMuteLock: Maybe<DeviceAudioMuteLock>;
};

export type DeviceAudioMuteLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceAudioMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type DeviceAudioNetwork = {
	interface: NetworkInterfaceInfo;
};

export type DeviceAudioNetworkInputChannelCount = {
	count: Scalars['Int']['output'];
};

export type DeviceAudioNetworkInterface = {
	interfaceOptions: Array<DeviceNetworkInterface>;
	selectedInterface: Maybe<DeviceNetworkInterface>;
};

export type DeviceAudioNetworkInterfaceChange = {
	audioNetworkInterface: Maybe<DeviceAudioNetworkInterface>;
};

export type DeviceAudioNetworkOutputChannelCount = {
	count: Scalars['Int']['output'];
};

export type DeviceAuthenticationChange = {
	authentication: Maybe<DeviceAuthenticationStateChange>;
};

export type DeviceAuthenticationCredential = {
	accessLevel: Scalars['String']['output'];
	credentialsState: CredentialsState | `${CredentialsState}`;
};

export type DeviceAuthenticationState = {
	credentials: Maybe<Array<DeviceAuthenticationCredential>>;
};

export type DeviceAuthenticationStateChange = {
	credentials: Maybe<Array<DeviceAuthenticationCredential>>;
	passphraseUpdated: Maybe<Scalars['Boolean']['output']>;
};

export type DeviceAutomixGainMetering = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceAutomixGainMeteringChange = {
	automixGainMetering: Maybe<DeviceAutomixGainMetering>;
};

export type DeviceAutomixGainMeteringUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceAvailablePackages = {
	hostedPackages: Array<FirmwarePackage>;
	primaryPackages: Array<FirmwarePackage>;
};

export type DeviceAvailablePackagesChange = {
	availablePackages: Maybe<DeviceAvailablePackages>;
};

export type DeviceBatteryHealth = {
	cycleCount: Scalars['Int']['output'];
	percentage: Scalars['Int']['output'];
};

export type DeviceBatteryHealthChange = {
	batteryHealth: Maybe<DeviceBatteryHealth>;
};

export type DeviceBatteryHealthConstraints = {
	percentage: DeviceBatteryHealthPercentageConstraints;
};

export type DeviceBatteryHealthPercentageConstraints = IIntRangeConstraints & {
	range: IntRange;
};

/** Describes the battery level of a battery-powered device */
export type DeviceBatteryLevel = {
	/** The current level of charge held by the device's battery, as a percentage */
	percentage: Scalars['Int']['output'];
	/** Status about the battery's remaining time to the state it is targetting, whether that be charging or discharging partially or completely. */
	status: DeviceBatteryStatus;
};

export type DeviceBatteryLevelChange = {
	batteryLevel: Maybe<DeviceBatteryLevel>;
};

export type DeviceBatteryLevelConstraints = {
	/**
	 * Describes the range of valid percentage values which can be reported by the battery-powered device.
	 * E.g. Shure smart batteries can provide greater granularity about charge percentage than traditional AAs
	 * when a device is on-air.
	 */
	percentage: DeviceBatteryLevelPercentageConstraints;
	/** Describes the current status regarding the battery level, and any changes which are expected based on whether it is charging or discharging. */
	status: DeviceBatteryLevelStatusConstraints;
};

export type DeviceBatteryLevelPercentageConstraints = IIntRangeConstraints & {
	/** Describes the range of values for battery charge percentage */
	range: IntRange;
};

export type DeviceBatteryLevelStatusConstraints = {
	targetState: DeviceBatteryLevelTargetStateConstraints;
};

export type DeviceBatteryLevelTargetStateConstraints = {
	/**
	 * The possible states in the battery state machine for this device.
	 * E.g. Not all devices support "OPTIMAL_STORAGE", which allows for long time preservation of unused batteries.
	 */
	options: Array<BatteryState | `${BatteryState}`>;
};

export type DeviceBatteryStatus = {
	currentState: BatteryState | `${BatteryState}`;
	/** Describes the state machine of the battery both when the device is on the air or on the charger. */
	targetState: Maybe<BatteryState | `${BatteryState}`>;
	/**
	 * The amount of time remaining for the battery to enter one of the following states expressed through the 'BatteryState' enum.
	 * When transitioning between states (docking on a charger, undocking from a charger), a temporary calculating state will be entered.
	 * During this time, the time can be null.
	 */
	timeToTargetState: Maybe<Scalars['Duration']['output']>;
};

export type DeviceBodypackMicrophoneMode = {
	mode: BodypackMicrophoneMode | `${BodypackMicrophoneMode}`;
};

export type DeviceBodypackMicrophoneModeChange = {
	bodypackMicrophoneMode: Maybe<DeviceBodypackMicrophoneMode>;
};

export type DeviceBodypackMicrophoneModeConstraints = {
	mode: Maybe<BodypackMicrophoneModeConstraints>;
};

export type DeviceBodypackMicrophoneModeUpdateInput = {
	mode: BodypackMicrophoneMode | `${BodypackMicrophoneMode}`;
};

export type DeviceCallStatus = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceCallStatusChange = {
	callStatus: Maybe<DeviceCallStatus>;
};

export type DeviceCallStatusUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceChange = {
	features: DeviceFeatureChange;
	id: Scalars['ID']['output'];
};

export type DeviceChargerBays = {
	chargerBays: Array<ChargerBay>;
};

export type DeviceCommandStringsService = {
	enabled: Scalars['Boolean']['output'];
	port: Scalars['Int']['output'];
};

export type DeviceCommandStringsServiceChange = {
	commandStringsService: Maybe<DeviceCommandStringsService>;
};

export type DeviceCommandStringsServiceConstraints = {
	port: DeviceCommandStringsServicePortConstraints;
};

export type DeviceCommandStringsServicePortConstraints = IIntRangeConstraints & {
	mutate: Scalars['Boolean']['output'];
	range: IntRange;
};

export type DeviceCommandStringsServiceUpdateInput = {
	enabled: Scalars['Boolean']['input'];
	port?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceConferencingOperationMode = {
	mode: ConferencingOperationMode | `${ConferencingOperationMode}`;
};

export type DeviceConferencingOperationModeButtonLock = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceConferencingOperationModeButtonLockChange = {
	conferencingOperationModeButtonLock: Maybe<DeviceConferencingOperationModeButtonLock>;
};

export type DeviceConferencingOperationModeButtonLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceConferencingOperationModeChange = {
	conferencingOperationMode: Maybe<DeviceConferencingOperationMode>;
};

export type DeviceConferencingOperationModeConstraints = {
	mode: ConferencingOperationModeConstraints;
};

export type DeviceConferencingOperationModeUpdateInput = {
	mode: ConferencingOperationMode | `${ConferencingOperationMode}`;
};

export type DeviceConfigurationLock = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceConfigurationLockChange = {
	configurationLock: Maybe<DeviceConfigurationLock>;
};

export type DeviceConfigurationLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceConstraints = {
	analogAudioOutputMode: Maybe<DeviceAnalogAudioOutputModeConstraints>;
	analogLogicPresetSwitch: Maybe<DeviceAnalogLogicPresetSwitchConstraints>;
	analogLogicPresetSwitchStatus: Maybe<DeviceAnalogLogicPresetSwitchStatusConstraints>;
	audioChannelCount: Maybe<DeviceAudioChannelCountConstraints>;
	audioChannels: Maybe<DeviceAudioChannelsConstraints>;
	audioMuteControl: Maybe<DeviceAudioMuteControlConstraints>;
	batteryHealth: Maybe<DeviceBatteryHealthConstraints>;
	batteryLevel: Maybe<DeviceBatteryLevelConstraints>;
	bodypackMicrophoneMode: Maybe<DeviceBodypackMicrophoneModeConstraints>;
	commandStringsService: Maybe<DeviceCommandStringsServiceConstraints>;
	conferencingOperationMode: Maybe<DeviceConferencingOperationModeConstraints>;
	connectedDeviceLabel: Maybe<ConnectedDeviceLabelConstraints>;
	danteAudioNetwork: Maybe<DeviceDanteAudioNetworkConstraints>;
	dnsClientConfiguration: Maybe<DeviceDnsClientConfigurationConstraints>;
	equalizerContour: Maybe<DeviceEqualizerContourConstraints>;
	ethernetPowerSource: Maybe<DeviceEthernetPowerSourceConstraints>;
	height: Maybe<DeviceHeightConstraints>;
	lightingAudioMeters: Maybe<DeviceLightingAudioMetersConstraints>;
	lightingAutomixGating: Maybe<DeviceLightingAutomixGatingConstraints>;
	lightingBehavior: Maybe<DeviceLightingBehaviorConstraints>;
	lightingBrightness: Maybe<DeviceLightingBrightnessConstraints>;
	lightingMuteBehavior: Maybe<DeviceLightingMuteBehaviorConstraints>;
	lightingRing: Maybe<DeviceLightingRingConstraints>;
	lightingSplitModeBreathingMeterColor: Maybe<DeviceLightingSplitModeMeterColorConstraints>;
	lightingSplitModeSolidMeterColor: Maybe<DeviceLightingSplitModeMeterColorConstraints>;
	lightingTouchPanelMode: Maybe<DeviceLightingTouchPanelModeConstraints>;
	lightingUnmuteBehavior: Maybe<DeviceLightingUnmuteBehaviorConstraints>;
	listenerHeight: Maybe<DeviceListenerHeightConstraints>;
	name: Maybe<DeviceNameConstraints>;
	placement: Maybe<DevicePlacementConstraints>;
	rfDensityMode: Maybe<DeviceRfDensityModeConstraints>;
	rfPower: Maybe<DeviceRfPowerConstraints>;
	rotation: Maybe<DeviceRotationConstraints>;
	security8021X: Maybe<DeviceSecurity8021XConstraints>;
	switchConfiguration: Maybe<DeviceSwitchConfigurationConstraints>;
	transmitterInitialStateFromCharger: Maybe<DeviceTransmitterInitialStateFromChargerConstraints>;
	transmitterLightingBehavior: Maybe<DeviceTransmitterLightingBehaviorConstraints>;
	usbTerminalType: Maybe<DeviceUsbTerminalTypeConstraints>;
	virtualAcousticBoundary: Maybe<DeviceVirtualAcousticBoundaryConstraints>;
	webService: DeviceWebServiceConstraints;
};

export type DeviceControlNetwork = {
	interface: NetworkInterfaceInfo;
};

export type DeviceControlNetworkInterface = {
	interfaceOptions: Array<DeviceNetworkInterface>;
	selectedInterface: Maybe<DeviceNetworkInterface>;
};

export type DeviceControlNetworkInterfaceChange = {
	controlNetworkInterface: Maybe<DeviceControlNetworkInterface>;
};

export type DeviceCoverageMode = {
	/**
	 * Automatic coverage mode will automatically adjust lobes within pre-defined coverage areas
	 * Manual coverage mode will require the user to manually adjust the position and width of each microphone lobe
	 */
	coverageMode: CoverageMode | `${CoverageMode}`;
};

export type DeviceCoverageModeChange = {
	coverageMode: Maybe<DeviceCoverageMode>;
};

export type DeviceCoverageModeUpdateInput = {
	coverageMode: CoverageMode | `${CoverageMode}`;
};

export type DeviceCoveragePositionChange = {
	coveragePosition: Maybe<DevicePosition>;
};

export type DeviceCoveragePositionInput = {
	xPosition: Scalars['Float']['input'];
	yPosition: Scalars['Float']['input'];
};

export type DeviceDanteAudioEncryption = {
	enabled: Scalars['Boolean']['output'];
	passphraseSet: Scalars['Boolean']['output'];
};

export type DeviceDanteAudioEncryptionChange = {
	danteAudioEncryption: Maybe<DeviceDanteAudioEncryption>;
};

export type DeviceDanteAudioNetwork = {
	clockSyncError: Maybe<Scalars['Boolean']['output']>;
	name: Scalars['String']['output'];
	nameConflict: Maybe<Scalars['Boolean']['output']>;
};

export type DeviceDanteAudioNetworkAccess = {
	changesAllowed: Scalars['Boolean']['output'];
	factoryResetAllowed: Scalars['Boolean']['output'];
};

export type DeviceDanteAudioNetworkAccessChange = {
	danteAudioNetworkAccess: Maybe<DeviceDanteAudioNetworkAccess>;
};

export type DeviceDanteAudioNetworkChange = {
	danteAudioNetwork: Maybe<DeviceDanteAudioNetwork>;
};

export type DeviceDanteAudioNetworkConstraints = {
	clockSyncError: FieldSupport;
	name: DeviceDanteAudioNetworkNameConstraints;
	nameConflict: FieldSupport;
};

export type DeviceDanteAudioNetworkNameConstraints = IStringPatternRangeConstraints & {
	pattern: Scalars['Regex']['output'];
};

export type DeviceDanteAudioNetworkUpdateInput = {
	name: Scalars['String']['input'];
};

export type DeviceDectChannelCount = {
	input: Scalars['Int']['output'];
	output: Scalars['Int']['output'];
};

export type DeviceDectRfSpectrumScan = {
	inProgress: Scalars['Boolean']['output'];
	results: DeviceDectRfSpectrumScanResults;
};

export type DeviceDectRfSpectrumScanChange = {
	dectRfSpectrumScan: Maybe<DeviceDectRfSpectrumScan>;
};

export type DeviceDectRfSpectrumScanChannelCountRange = {
	max: Scalars['Int']['output'];
	min: Scalars['Int']['output'];
};

export type DeviceDectRfSpectrumScanResult = {
	channelCounts: DeviceDectRfSpectrumScanResultByRfDensityMode;
	rfAvailability: DeviceDectRfSpectrumScanRfAvailability;
};

export type DeviceDectRfSpectrumScanResultByRfDensityMode = {
	highDensity: DeviceDectRfSpectrumScanResultChannelCounts;
	standardDensity: DeviceDectRfSpectrumScanResultChannelCounts;
};

export type DeviceDectRfSpectrumScanResultChannelCounts = {
	aggressive: DeviceDectRfSpectrumScanChannelCountRange;
	conservative: DeviceDectRfSpectrumScanChannelCountRange;
};

export type DeviceDectRfSpectrumScanResults = {
	cumulative: DeviceDectRfSpectrumScanResult;
	current: DeviceDectRfSpectrumScanResult;
};

export type DeviceDectRfSpectrumScanRfAvailability = {
	high: Scalars['Int']['output'];
	low: Scalars['Int']['output'];
	moderate: Scalars['Int']['output'];
};

/** The list of physical devices that can be deployed to from this (virtual) device. */
export type DeviceDeploymentCandidates = {
	candidates: Array<Device>;
};

export type DeviceDeploymentCandidatesChange = {
	deployment: Maybe<DeviceDeploymentCandidates>;
};

export type DeviceDeploymentInput = {
	/** Determines the name of the target device after deployment. If not set, the target device name will not change. */
	deviceName?: InputMaybe<Scalars['String']['input']>;
	sourceDevice: Scalars['ID']['input'];
	/**
	 * Syncs the Shure device and Channel names with the Dante device and channel names.
	 * If not set, Dante names on the target device will remain the same.
	 */
	syncDanteNamesWithShureNames?: InputMaybe<Scalars['Boolean']['input']>;
	targetDevice?: InputMaybe<Scalars['ID']['input']>;
};

export type DeviceDeploymentResult = {
	failureStage: DeviceDeploymentStage | `${DeviceDeploymentStage}`;
	sourceDevice: Device;
	targetDevice: Device;
};

export enum DeviceDeploymentStage {
	/** Inter-device deployment(Eg: Routing) */
	AssociationDeployment = 'ASSOCIATION_DEPLOYMENT',
	/** Generic handling of a device(Eg: Name) */
	GenericDeployment = 'GENERIC_DEPLOYMENT',
	/** Specialized handling of a device */
	SpecificDeployment = 'SPECIFIC_DEPLOYMENT'
}

export type DeviceDescription = {
	/** @deprecated Use advertisedInterface.category or interface.category instead */
	category: Scalars['String']['output'];
	constraints: DeviceConstraints;
	features: DeviceSupportedFeatures;
	/**
	 * The interface used to determine whether or not a feature is supported for the device.
	 *
	 * This often differs from the advertised interface of a device, and the interface
	 * used to communicate with the features of a device.
	 * If a discovered device is not in the range of supported interface IDs,
	 * most device features will be unavailable as means of preventing unsupported user interaction.
	 */
	interface: InterfaceDetails;
	/** @deprecated Use advertisedInterface.model or interface.model instead */
	model: Scalars['String']['output'];
	/** @deprecated Use CompatibilityLevel type for feature support */
	unsupported: Scalars['Boolean']['output'];
};

export type DeviceDisassociationInput = {
	chargerBay: Scalars['ID']['input'];
	rfChannel: Scalars['ID']['input'];
};

export type DeviceDisassociationResult = {
	chargerBay: Maybe<ChargerBay>;
	rfChannel: Maybe<RfChannel>;
	status: DisassociationStatus | `${DisassociationStatus}`;
};

export type DeviceDnsClientConfiguration = {
	/**
	 * The first array item is the primary server IP address
	 * and the second array item is the secondary server IP address
	 */
	automaticConfiguration: Array<Scalars['IpAddress']['output']>;
	/**
	 * The first array item is the primary server IP address
	 * and the second array item is the secondary server IP address
	 */
	manualConfiguration: Array<Scalars['IpAddress']['output']>;
	mode: DeviceDnsClientConfigurationMode | `${DeviceDnsClientConfigurationMode}`;
};

export type DeviceDnsClientConfigurationChange = {
	dnsClientConfiguration: Maybe<DeviceDnsClientConfiguration>;
};

export type DeviceDnsClientConfigurationConstraints = {
	automaticConfiguration: DeviceDnsClientConfigurationIpAddressArrayConstraints;
	manualConfiguration: DeviceDnsClientConfigurationIpAddressArrayConstraints;
	mode: DeviceDnsClientConfigurationModeConstraints;
};

export type DeviceDnsClientConfigurationInput = {
	/**
	 * The first array item is the primary server IP address
	 * and the second array item is the secondary server IP address
	 * Missing any array item or providing more than 2 items will cause INVALID_INPUT error.
	 * This field is required for MANUAL mode
	 */
	manualConfiguration?: InputMaybe<Array<Scalars['IpAddress']['input']>>;
	mode: DeviceDnsClientConfigurationMode | `${DeviceDnsClientConfigurationMode}`;
};

export type DeviceDnsClientConfigurationIpAddressArrayConstraints = {
	length: Scalars['Int']['output'];
};

export enum DeviceDnsClientConfigurationMode {
	Automatic = 'AUTOMATIC',
	Manual = 'MANUAL'
}

export type DeviceDnsClientConfigurationModeConstraints = {
	options: Array<DeviceDnsClientConfigurationMode | `${DeviceDnsClientConfigurationMode}`>;
};

export type DeviceDoubleStuffProxiedTransmitters = {
	mic1: Maybe<DoubleStuffProxiedTransmitter>;
	mic2: Maybe<DoubleStuffProxiedTransmitter>;
};

export type DeviceDoubleStuffProxiedTransmittersChange = {
	doublestuffProxiedTransmitters: Maybe<DeviceDoubleStuffProxiedTransmitters>;
};

export type DeviceEdge = {
	cursor: Scalars['String']['output'];
	node: Maybe<Device>;
};

export type DeviceEqualizerContour = {
	equalizerContour: EqualizerContour | `${EqualizerContour}`;
};

export type DeviceEqualizerContourChange = {
	equalizerContour: Maybe<DeviceEqualizerContour>;
};

export type DeviceEqualizerContourConstraints = {
	equalizerContour: Maybe<DeviceEqualizerContourTypeConstraints>;
};

export type DeviceEqualizerContourTypeConstraints = {
	options: Array<EqualizerContour | `${EqualizerContour}`>;
};

export type DeviceEqualizerContourUpdateInput = {
	equalizerContour: EqualizerContour | `${EqualizerContour}`;
};

export type DeviceErrorIndicator = {
	state: ErrorIndicator | `${ErrorIndicator}`;
};

export type DeviceErrorIndicatorChange = {
	errorIndicator: Maybe<DeviceErrorIndicator>;
};

export type DeviceEthernetLights = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceEthernetLightsChange = {
	ethernetLights: Maybe<DeviceEthernetLights>;
};

export type DeviceEthernetLightsUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceEthernetPowerSource = {
	type: EthernetPowerSourceType | `${EthernetPowerSourceType}`;
};

export type DeviceEthernetPowerSourceConstraints = {
	type: DeviceValidEthernetPowerSourceConstraints;
};

export type DeviceFeatureChange =
	| ConnectedDeviceLabelChange
	| DeviceActivePresetChange
	| DeviceAllEqualizersBypassChange
	| DeviceAllIntellimixBypassChange
	| DeviceAnalogAudioOutputModeChange
	| DeviceAnalogLogicChange
	| DeviceAnalogLogicPresetSwitchChange
	| DeviceAnalogLogicPresetSwitchStatusChange
	| DeviceAudioAutoFocusChange
	| DeviceAudioChannelCountChange
	| DeviceAudioChannelsChange
	| DeviceAudioMuteChange
	| DeviceAudioMuteControlChange
	| DeviceAudioMuteControlGroupChange
	| DeviceAudioMuteDefaultChange
	| DeviceAudioMuteLockChange
	| DeviceAudioNetworkInterfaceChange
	| DeviceAuthenticationChange
	| DeviceAutomixGainMeteringChange
	| DeviceAvailablePackagesChange
	| DeviceBatteryHealthChange
	| DeviceBatteryLevelChange
	| DeviceBodypackMicrophoneModeChange
	| DeviceCallStatusChange
	| DeviceCommandStringsServiceChange
	| DeviceConferencingOperationModeButtonLockChange
	| DeviceConferencingOperationModeChange
	| DeviceConfigurationLockChange
	| DeviceControlNetworkInterfaceChange
	| DeviceCoverageModeChange
	| DeviceCoveragePositionChange
	| DeviceDanteAudioEncryptionChange
	| DeviceDanteAudioNetworkAccessChange
	| DeviceDanteAudioNetworkChange
	| DeviceDectRfSpectrumScanChange
	| DeviceDeploymentCandidatesChange
	| DeviceDnsClientConfigurationChange
	| DeviceDoubleStuffProxiedTransmittersChange
	| DeviceEqualizerContourChange
	| DeviceErrorIndicatorChange
	| DeviceEthernetLightsChange
	| DeviceFirmwareUpdatePolicyChange
	| DeviceGatewayDeviceChange
	| DeviceGlobalMicStatusChange
	| DeviceHeightChange
	| DeviceIdentifyChange
	| DeviceInternetOfThingsChange
	| DeviceLicenseChange
	| DeviceLicenseChangeV2
	| DeviceLightingAudioActivityChange
	| DeviceLightingAudioMetersChange
	| DeviceLightingAudioMuteIndicatorOverrideChange
	| DeviceLightingAutomixGatingChange
	| DeviceLightingBehaviorChange
	| DeviceLightingBrightnessChange
	| DeviceLightingLiveAudioMetersChange
	| DeviceLightingMuteBehaviorChange
	| DeviceLightingRingChange
	| DeviceLightingSplitModeBreathingMeterColorChange
	| DeviceLightingSplitModeSolidMeterColorChange
	| DeviceLightingTouchPanelModeChange
	| DeviceLightingUnmuteBehaviorChange
	| DeviceLinkButtonLockChange
	| DeviceLinkedAccessPointChange
	| DeviceListenerHeightChange
	| DeviceLogicMuteChange
	| DeviceLogicReceiverChange
	| DeviceLogicTransmitterChange
	| DeviceLoudspeakerChange
	| DeviceMdnsServiceChange
	| DeviceMeteringModesChange
	| DeviceMicStatusChange
	| DeviceNameChange
	| DeviceOnChargerChange
	| DevicePlacementChange
	| DeviceProxiedDevicesChange
	| DeviceRedundantAudioNetworkChange
	| DeviceRfDensityModeChange
	| DeviceRfPowerChange
	| DeviceRoomChange
	| DeviceRotationChange
	| DeviceRoutingPositionChange
	| DeviceSecurity8021XChange
	| DeviceSmartGateChange
	| DeviceStereoAudioChange
	| DeviceSwitchConfigurationChange
	| DeviceTagsChange
	| DeviceTransmitterIdentifyBehaviorChange
	| DeviceTransmitterInitialStateFromChargerChange
	| DeviceTransmitterLightingBehaviorChange
	| DeviceTransmitterMuteBehaviorChange
	| DeviceTransmitterOutOfRangeBehaviorChange
	| DeviceTransmitterRejoinBehaviorChange
	| DeviceTransmitterSwitchBehaviorChange
	| DeviceUpdateProgressChange
	| DeviceUptimeChange
	| DeviceUsbMuteSyncChange
	| DeviceUsbTerminalTypeChange
	| DeviceUserPresetsChange
	| DeviceVirtualAcousticBoundaryChange
	| DeviceWebServiceChange;

export type DeviceFeatureUpdateInput = {
	allEqualizersBypass?: InputMaybe<DeviceAllEqualizersBypassUpdateInput>;
	allIntellimixBypass?: InputMaybe<DeviceAllIntellimixBypassUpdateInput>;
	analogAudioOutputMode?: InputMaybe<DeviceAnalogAudioOutputModeUpdateInput>;
	analogLogic?: InputMaybe<DeviceAnalogLogicUpdateInput>;
	analogLogicPresetSwitch?: InputMaybe<DeviceAnalogLogicPresetSwitchUpdateInput>;
	audioAutoFocus?: InputMaybe<DeviceAudioAutoFocusUpdateInput>;
	audioMute?: InputMaybe<DeviceAudioMuteUpdateInput>;
	audioMuteControl?: InputMaybe<DeviceAudioMuteControlUpdateInput>;
	audioMuteDefault?: InputMaybe<DeviceAudioMuteDefaultUpdateInput>;
	audioMuteLock?: InputMaybe<DeviceAudioMuteLockUpdateInput>;
	automixGainMetering?: InputMaybe<DeviceAutomixGainMeteringUpdateInput>;
	bodypackMicrophoneMode?: InputMaybe<DeviceBodypackMicrophoneModeUpdateInput>;
	callStatus?: InputMaybe<DeviceCallStatusUpdateInput>;
	commandStringsService?: InputMaybe<DeviceCommandStringsServiceUpdateInput>;
	conferencingOperationMode?: InputMaybe<DeviceConferencingOperationModeUpdateInput>;
	conferencingOperationModeButtonLock?: InputMaybe<DeviceConferencingOperationModeButtonLockUpdateInput>;
	configurationLock?: InputMaybe<DeviceConfigurationLockUpdateInput>;
	connectedDeviceLabel?: InputMaybe<ConnectedDeviceLabelUpdateInput>;
	coverageMode?: InputMaybe<DeviceCoverageModeUpdateInput>;
	coveragePosition?: InputMaybe<DeviceCoveragePositionInput>;
	danteAudioNetwork?: InputMaybe<DeviceDanteAudioNetworkUpdateInput>;
	dnsClientConfiguration?: InputMaybe<DeviceDnsClientConfigurationInput>;
	equalizerContour?: InputMaybe<DeviceEqualizerContourUpdateInput>;
	ethernetLights?: InputMaybe<DeviceEthernetLightsUpdateInput>;
	firmwareUpdatePolicy?: InputMaybe<DeviceFirmwareUpdatePolicyUpdateInput>;
	height?: InputMaybe<DeviceHeightUpdateInput>;
	internetOfThings?: InputMaybe<DeviceInternetOfThingsUpdateInput>;
	lightingAudioActivity?: InputMaybe<DeviceLightingAudioActivityUpdateInput>;
	lightingAudioMeters?: InputMaybe<DeviceLightingAudioMetersUpdateInput>;
	lightingAudioMuteIndicatorOverride?: InputMaybe<DeviceLightingAudioMuteIndicatorOverrideUpdateInput>;
	lightingAutomixGating?: InputMaybe<DeviceLightingAutomixGatingUpdateInput>;
	lightingLiveAudioMeters?: InputMaybe<DeviceLightingLiveAudioMetersUpdateInput>;
	lightingMuteBehavior?: InputMaybe<DeviceLightingBehaviorUpdateInput>;
	lightingRing?: InputMaybe<DeviceLightingRingUpdateInput>;
	lightingSplitModeBreathingMeterColor?: InputMaybe<DeviceLightingSplitModeMeterColorUpdateInput>;
	lightingSplitModeSolidMeterColor?: InputMaybe<DeviceLightingSplitModeMeterColorUpdateInput>;
	lightingTouchPanelMode?: InputMaybe<DeviceLightingTouchPanelModeUpdateInput>;
	lightingUnmuteBehavior?: InputMaybe<DeviceLightingBehaviorUpdateInput>;
	linkButtonLock?: InputMaybe<DeviceLinkButtonLockUpdateInput>;
	listenerHeight?: InputMaybe<DeviceListenerHeightUpdateInput>;
	logicMute?: InputMaybe<DeviceLogicMuteUpdateInput>;
	logicReceiver?: InputMaybe<DeviceLogicReceiverUpdateInput>;
	loudspeaker?: InputMaybe<DeviceLoudspeakerUpdateInput>;
	mdnsService?: InputMaybe<DeviceMdnsServiceUpdateInput>;
	meteringModes?: InputMaybe<DeviceMeteringModesUpdateInput>;
	micStatus?: InputMaybe<DeviceMicStatusUpdateInput>;
	name?: InputMaybe<DeviceNameUpdateInput>;
	placement?: InputMaybe<DevicePlacementUpdateInput>;
	rfDensityMode?: InputMaybe<DeviceRfDensityModeUpdateInput>;
	rfPower?: InputMaybe<DeviceRfPowerUpdateInput>;
	rotation?: InputMaybe<DeviceRotationUpdateInput>;
	routingPosition?: InputMaybe<DeviceRoutingPositionInput>;
	smartGate?: InputMaybe<DeviceSmartGateUpdateInput>;
	stereoAudio?: InputMaybe<DeviceStereoAudioUpdateInput>;
	transmitterIdentifyBehavior?: InputMaybe<DeviceTransmitterIdentifyBehaviorInput>;
	transmitterInitialStateFromCharger?: InputMaybe<DeviceTransmitterInitialStateFromChargerInput>;
	transmitterLightingBehavior?: InputMaybe<DeviceTransmitterLightingBehaviorUpdateInput>;
	transmitterMuteBehavior?: InputMaybe<DeviceTransmitterMuteBehaviorUpdateInput>;
	transmitterOutOfRangeBehavior?: InputMaybe<DeviceTransmitterOutOfRangeBehaviorInput>;
	transmitterRejoinBehavior?: InputMaybe<DeviceTransmitterRejoinBehaviorInput>;
	transmitterSwitchBehavior?: InputMaybe<DeviceTransmitterSwitchBehaviorUpdateInput>;
	usbMuteSync?: InputMaybe<DeviceUsbMuteSyncUpdateInput>;
	usbTerminalType?: InputMaybe<DeviceUsbTerminalTypeUpdateInput>;
	virtualAcousticBoundary?: InputMaybe<DeviceVirtualAcousticBoundaryUpdateInput>;
	webService?: InputMaybe<DeviceWebServiceUpdateInput>;
};

export type DeviceFeatures = {
	activePreset: Maybe<DeviceActivePreset>;
	allEqualizersBypass: Maybe<DeviceAllEqualizersBypass>;
	allIntellimixBypass: Maybe<DeviceAllIntellimixBypass>;
	/**
	 * Determines how the device should amplify all outputted analog audio to provide sound reinforcement in the space.
	 * Different output modes require different wiring, and potentially different speakers to handle the given output.
	 */
	analogAudioOutputMode: Maybe<DeviceAnalogAudioOutputMode>;
	/**
	 * Determines how the device should amplify all outputted analog audio to provide sound reinforcement in the space.
	 * Different output modes (whether analogLogic is enabled) specify the behavior for what to do with a high or a low signal over the analog logic connection.
	 */
	analogLogic: Maybe<DeviceAnalogLogic>;
	analogLogicPresetSwitch: Maybe<DeviceAnalogLogicPresetSwitch>;
	analogLogicPresetSwitchStatus: Maybe<DeviceAnalogLogicPresetSwitchStatus>;
	audioAutoFocus: Maybe<DeviceAudioAutoFocus>;
	audioChannelCount: Maybe<DeviceAudioChannelCount>;
	audioChannels: Maybe<DeviceAudioChannels>;
	audioCoverageAreas: Maybe<DeviceAudioCoverageAreas>;
	audioMute: Maybe<DeviceAudioMute>;
	audioMuteControl: Maybe<DeviceAudioMuteControl>;
	audioMuteControlGroup: Maybe<DeviceAudioMuteControlGroup>;
	audioMuteDefault: Maybe<DeviceAudioMuteDefault>;
	audioMuteLock: Maybe<DeviceAudioMuteLock>;
	audioNetwork: Maybe<DeviceAudioNetwork>;
	audioNetworkInputChannelCount: Maybe<DeviceAudioNetworkInputChannelCount>;
	audioNetworkInterface: Maybe<DeviceAudioNetworkInterface>;
	audioNetworkOutputChannelCount: Maybe<DeviceAudioNetworkOutputChannelCount>;
	authentication: Maybe<DeviceAuthenticationState>;
	automixGainMetering: Maybe<DeviceAutomixGainMetering>;
	availablePackages: Maybe<DeviceAvailablePackages>;
	batteryHealth: Maybe<DeviceBatteryHealth>;
	batteryLevel: Maybe<DeviceBatteryLevel>;
	bodypackMicrophoneMode: Maybe<DeviceBodypackMicrophoneMode>;
	callStatus: Maybe<DeviceCallStatus>;
	chargerBays: Maybe<DeviceChargerBays>;
	commandStringsService: Maybe<DeviceCommandStringsService>;
	conferencingOperationMode: Maybe<DeviceConferencingOperationMode>;
	conferencingOperationModeButtonLock: Maybe<DeviceConferencingOperationModeButtonLock>;
	configurationLock: Maybe<DeviceConfigurationLock>;
	/** A user-settable digital label name for a device which is connected to this device */
	connectedDeviceLabel: Maybe<ConnectedDeviceLabel>;
	controlNetwork: Maybe<DeviceControlNetwork>;
	controlNetworkInterface: Maybe<DeviceControlNetworkInterface>;
	coverageMode: Maybe<DeviceCoverageMode>;
	coveragePosition: Maybe<DevicePosition>;
	danteAudioEncryption: Maybe<DeviceDanteAudioEncryption>;
	danteAudioNetwork: Maybe<DeviceDanteAudioNetwork>;
	danteAudioNetworkAccess: Maybe<DeviceDanteAudioNetworkAccess>;
	dectChannelCount: Maybe<DeviceDectChannelCount>;
	dectRfSpectrumScan: Maybe<DeviceDectRfSpectrumScan>;
	deployment: Maybe<DeviceDeploymentCandidates>;
	dnsClientConfiguration: Maybe<DeviceDnsClientConfiguration>;
	/** @deprecated For Cloud Managed Rooms Use Only. */
	doublestuffProxiedTransmitters: Maybe<DeviceDoubleStuffProxiedTransmitters>;
	equalizerContour: Maybe<DeviceEqualizerContour>;
	errorIndicator: Maybe<DeviceErrorIndicator>;
	ethernetLights: Maybe<DeviceEthernetLights>;
	ethernetPowerSource: Maybe<DeviceEthernetPowerSource>;
	firmware: Maybe<DeviceFirmware>;
	firmwareUpdatePolicy: Maybe<DeviceFirmwareUpdatePolicy>;
	gatewayDevice: Maybe<DeviceGatewayDevice>;
	globalMicStatus: Maybe<DeviceGlobalMicStatus>;
	height: Maybe<DeviceHeight>;
	identify: Maybe<DeviceIdentify>;
	internetOfThings: Maybe<DeviceInternetOfThings>;
	license: Maybe<DeviceLicense>;
	licenseV2: Maybe<DeviceLicenseV2>;
	lightingAudioActivity: Maybe<DeviceLightingAudioActivity>;
	lightingAudioMeters: Maybe<DeviceLightingAudioMeters>;
	lightingAudioMuteIndicatorOverride: Maybe<DeviceLightingAudioMuteIndicatorOverride>;
	lightingAutomixGating: Maybe<DeviceLightingAutomixGating>;
	lightingBehavior: Maybe<DeviceLightingBehavior>;
	lightingBrightness: Maybe<DeviceLightingBrightness>;
	lightingLiveAudioMeters: Maybe<DeviceLightingLiveAudioMeters>;
	lightingMuteBehavior: Maybe<DeviceLightingMuteBehavior>;
	lightingRing: Maybe<DeviceLightingRing>;
	lightingSplitModeBreathingMeterColor: Maybe<DeviceLightingSplitModeMeterColor>;
	lightingSplitModeSolidMeterColor: Maybe<DeviceLightingSplitModeMeterColor>;
	lightingTouchPanelMode: Maybe<DeviceLightingTouchPanelMode>;
	lightingUnmuteBehavior: Maybe<DeviceLightingUnmuteBehavior>;
	linkButtonLock: Maybe<DeviceLinkButtonLock>;
	linkedAccessPoint: Maybe<DeviceLinkedAccessPoint>;
	listenerHeight: Maybe<DeviceListenerHeight>;
	logicMute: Maybe<DeviceLogicMute>;
	logicReceiver: Maybe<DeviceLogicReceiver>;
	logicTransmitter: Maybe<DeviceLogicTransmitter>;
	loudspeaker: Maybe<DeviceLoudspeaker>;
	mdnsService: Maybe<DeviceMdnsService>;
	meteringModes: Maybe<DeviceMeteringModes>;
	micStatus: Maybe<DeviceMicStatus>;
	name: Maybe<DeviceName>;
	onCharger: Maybe<DeviceOnCharger>;
	pcSpecification: Maybe<DevicePcSpecification>;
	placement: Maybe<DevicePlacement>;
	proxiedDevices: Maybe<DeviceProxiedDevices>;
	redundantAudioNetwork: Maybe<DeviceRedundantAudioNetwork>;
	rfBand: Maybe<DeviceRfBand>;
	rfChannels: Maybe<DeviceRfChannels>;
	rfDensityMode: Maybe<DeviceRfDensityMode>;
	rfPower: Maybe<DeviceRfPower>;
	room: Maybe<DeviceRoom>;
	rotation: Maybe<DeviceRotation>;
	routingPosition: Maybe<DevicePosition>;
	security8021X: Maybe<DeviceSecurity8021X>;
	serialNumber: Maybe<DeviceSerialNumber>;
	smartGate: Maybe<DeviceSmartGate>;
	stereoAudio: Maybe<DeviceStereoAudio>;
	switchConfiguration: Maybe<DeviceSwitchConfiguration>;
	tags: Maybe<DeviceTags>;
	transmitterIdentifyBehavior: Maybe<DeviceTransmitterIdentifyBehavior>;
	transmitterInitialStateFromCharger: Maybe<DeviceTransmitterInitialStateFromCharger>;
	transmitterLightingBehavior: Maybe<DeviceTransmitterLightingBehavior>;
	transmitterMuteBehavior: Maybe<DeviceTransmitterMuteBehavior>;
	transmitterOutOfRangeBehavior: Maybe<DeviceTransmitterOutOfRangeBehavior>;
	transmitterRejoinBehavior: Maybe<DeviceTransmitterRejoinBehavior>;
	transmitterSwitchBehavior: Maybe<DeviceTransmitterSwitchBehavior>;
	updateProgress: Maybe<DeviceUpdateProgress>;
	uptime: Maybe<DeviceUptime>;
	usbMuteSync: Maybe<DeviceUsbMuteSync>;
	usbTerminalType: Maybe<DeviceUsbTerminalType>;
	userPresets: Maybe<DeviceUserPresets>;
	virtual: Maybe<DeviceVirtual>;
	virtualAcousticBoundary: Maybe<DeviceVirtualAcousticBoundary>;
	webService: Maybe<DeviceWebService>;
};

export type DeviceFirmware = {
	valid: Scalars['Boolean']['output'];
	version: Scalars['SemanticVersion']['output'];
};

export type DeviceFirmwareUpdatePolicy = {
	downgradeAllowed: Scalars['Boolean']['output'];
};

export type DeviceFirmwareUpdatePolicyChange = {
	firmwareUpdatePolicy: Maybe<DeviceFirmwareUpdatePolicy>;
};

export type DeviceFirmwareUpdatePolicyUpdateInput = {
	downgradeAllowed: Scalars['Boolean']['input'];
};

export type DeviceGatewayDevice = {
	device: Device;
};

export type DeviceGatewayDeviceChange = {
	gatewayDevice: Maybe<DeviceGatewayDevice>;
};

export type DeviceGlobalMicStatus = {
	status: GlobalMicStatus | `${GlobalMicStatus}`;
};

export type DeviceGlobalMicStatusChange = {
	globalMicStatus: Maybe<DeviceGlobalMicStatus>;
};

export type DeviceGlobalMicStatusUpdateInput = {
	id: Scalars['ID']['input'];
	status: GlobalMicStatusInput | `${GlobalMicStatusInput}`;
};

export type DeviceGroupLinkingResult = {
	details: Maybe<Array<DeviceLinkingDetailResult>>;
	overallResult: LinkingOperationResult | `${LinkingOperationResult}`;
};

export type DeviceGroupUnlinkingResult = {
	details: Maybe<Array<DeviceUnlinkingDetailResult>>;
	overallResult: UnlinkingOperationResult | `${UnlinkingOperationResult}`;
};

/** height values are scaled to represent in millimeters (mm) */
export type DeviceHeight = {
	height: Scalars['Float']['output'];
};

export type DeviceHeightChange = {
	height: Maybe<DeviceHeight>;
};

export type DeviceHeightConstraints = {
	height: DeviceHeightFieldConstraints;
};

export type DeviceHeightFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type DeviceHeightUpdateInput = {
	height: Scalars['Float']['input'];
};

export type DeviceIdentify = {
	identifying: Scalars['Boolean']['output'];
};

export type DeviceIdentifyChange = {
	identify: Maybe<DeviceIdentify>;
};

/** represents device IOT feature */
export type DeviceInternetOfThings = {
	connected: Scalars['Boolean']['output'];
	enabled: Scalars['Boolean']['output'];
};

export type DeviceInternetOfThingsChange = {
	internetOfThings: Maybe<DeviceInternetOfThings>;
};

export type DeviceInternetOfThingsUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceIpsInput = {
	ipAddress: Array<Scalars['IpAddress']['input']>;
};

export type DeviceLicense = {
	info: Array<DeviceLicenseInfo>;
};

export type DeviceLicenseActivationProgress = {
	activationKey: Scalars['String']['output'];
	count: Scalars['Int']['output'];
	device: Device;
	message: Scalars['String']['output'];
	state: LicenseActivationState | `${LicenseActivationState}`;
};

export type DeviceLicenseCancelPendingActivationProgress = {
	activationKey: Scalars['String']['output'];
	device: Device;
	message: Scalars['String']['output'];
	state: LicenseCancelationState | `${LicenseCancelationState}`;
};

export type DeviceLicenseChange = {
	license: Maybe<DeviceLicense>;
};

export type DeviceLicenseChangeV2 = {
	licenseV2: Maybe<DeviceLicenseV2>;
};

export type DeviceLicenseDeactivationProgress = {
	activationKey: Scalars['String']['output'];
	count: Scalars['Int']['output'];
	device: Device;
	message: Scalars['String']['output'];
	state: LicenseDeactivationState | `${LicenseDeactivationState}`;
};

export type DeviceLicenseInfo = {
	activationId: Maybe<Scalars['String']['output']>;
	activationKey: Scalars['String']['output'];
	count: Scalars['Int']['output'];
	entitlementId: Maybe<Scalars['String']['output']>;
	expirationDate: Maybe<Scalars['DateTime']['output']>;
	/** @deprecated Use expirationDate for more accurate time handling when close to expiration. This will be removed in a subsequent release */
	expiresIn: Maybe<Scalars['Duration']['output']>;
	featureName: Maybe<Scalars['String']['output']>;
	/** @deprecated This will be removed in a subsequent release. There should be no remaining requirements enforcing that we notify the user of how many days their license remains in the grace period. */
	graceExpirationDate: Maybe<Scalars['DateTime']['output']>;
	/** @deprecated Use graceExpirationDate for more accurate time handling when close to expiration. This will be removed in a subsequent release */
	graceExpiresIn: Maybe<Scalars['Duration']['output']>;
	inGrace: Maybe<Scalars['Boolean']['output']>;
	inUse: Maybe<Scalars['Boolean']['output']>;
	inWarning: Maybe<Scalars['Boolean']['output']>;
	perpetual: Maybe<Scalars['Boolean']['output']>;
	soldTo: Maybe<Scalars['String']['output']>;
	startDate: Maybe<Scalars['DateTime']['output']>;
	state: LicenseState | `${LicenseState}`;
	type: Maybe<LicenseType | `${LicenseType}`>;
};

export type DeviceLicenseInfoV2 = {
	activationId: Maybe<Scalars['String']['output']>;
	activationKey: Scalars['String']['output'];
	count: Scalars['Int']['output'];
	entitlementId: Maybe<Scalars['String']['output']>;
	expirationDate: Maybe<Scalars['DateTime']['output']>;
	featureName: Maybe<Scalars['String']['output']>;
	inGrace: Maybe<Scalars['Boolean']['output']>;
	inUse: Maybe<Scalars['Boolean']['output']>;
	inWarning: Maybe<Scalars['Boolean']['output']>;
	perpetual: Maybe<Scalars['Boolean']['output']>;
	soldTo: Maybe<Scalars['String']['output']>;
	startDate: Maybe<Scalars['DateTime']['output']>;
	state: LicenseState | `${LicenseState}`;
	type: Maybe<LicenseType | `${LicenseType}`>;
};

export type DeviceLicenseV2 = {
	info: Array<DeviceLicenseInfoV2>;
};

export type DeviceLightingAudioActivity = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceLightingAudioActivityChange = {
	lightingAudioActivity: Maybe<DeviceLightingAudioActivity>;
};

export type DeviceLightingAudioActivityUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLightingAudioMeters = {
	breathingMeterColor: MeterColorThemeInfo;
	liveMeterColor: LiveMeterColorThemeInfo;
	mode: AudioMetersLightingMode | `${AudioMetersLightingMode}`;
	solidMeterColor: MeterColorThemeInfo;
};

export type DeviceLightingAudioMetersChange = {
	lightingAudioMeters: Maybe<DeviceLightingAudioMeters>;
};

export type DeviceLightingAudioMetersConstraints = {
	breathingMeterColor: MeterColorConstraints;
	liveMeterColor: LiveMeterColorConstraints;
	solidMeterColor: MeterColorConstraints;
};

export type DeviceLightingAudioMetersUpdateInput = {
	breathingMeterColor?: InputMaybe<MeterColorThemeInfoInput>;
	liveMeterColor?: InputMaybe<LiveMeterColorThemeInfoInput>;
	mode?: InputMaybe<AudioMetersLightingMode | `${AudioMetersLightingMode}`>;
	solidMeterColor?: InputMaybe<MeterColorThemeInfoInput>;
};

export type DeviceLightingAudioMuteIndicatorOverride = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceLightingAudioMuteIndicatorOverrideChange = {
	lightingAudioMuteIndicatorOverride: Maybe<DeviceLightingAudioMuteIndicatorOverride>;
};

export type DeviceLightingAudioMuteIndicatorOverrideUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLightingAutomixGating = {
	automixerInputDisengagedBehavior: AutomixerInputDisengagedBehavior | `${AutomixerInputDisengagedBehavior}`;
	enabled: Scalars['Boolean']['output'];
};

export type DeviceLightingAutomixGatingChange = {
	lightingAutomixGating: Maybe<DeviceLightingAutomixGating>;
};

export type DeviceLightingAutomixGatingConstraints = {
	automixerInputDisengagedBehavior: Maybe<AutomixerInputDisengagedBehaviorConstraints>;
};

export type DeviceLightingAutomixGatingUpdateInput = {
	automixerInputDisengagedBehavior?: InputMaybe<
		AutomixerInputDisengagedBehavior | `${AutomixerInputDisengagedBehavior}`
	>;
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeviceLightingBehavior = {
	color: Maybe<LightingColor | `${LightingColor}`>;
	mode: Maybe<LightingMode | `${LightingMode}`>;
	state: Maybe<LightingState | `${LightingState}`>;
};

export type DeviceLightingBehaviorChange = {
	lightingBehavior: Maybe<DeviceLightingBehavior>;
};

export type DeviceLightingBehaviorColorConstraints = {
	options: Array<LightingColor | `${LightingColor}`>;
};

export type DeviceLightingBehaviorConstraints = {
	color: DeviceLightingBehaviorColorConstraints;
	mode: DeviceLightingBehaviorModeConstraints;
	state: DeviceLightingBehaviorStateConstraints;
};

export type DeviceLightingBehaviorModeConstraints = {
	options: Array<LightingMode | `${LightingMode}`>;
};

export type DeviceLightingBehaviorStateConstraints = {
	options: Array<LightingState | `${LightingState}`>;
};

export type DeviceLightingBehaviorUpdateInput = {
	color?: InputMaybe<LightingColor | `${LightingColor}`>;
	mode?: InputMaybe<LightingMode | `${LightingMode}`>;
};

export type DeviceLightingBrightness = {
	/** The current brightness level of the device's lights */
	level: Scalars['Int']['output'];
	/** Number of brightness levels supported by the device's lights */
	supportedLevels: Scalars['Int']['output'];
};

export type DeviceLightingBrightnessChange = {
	lightingBrightness: Maybe<DeviceLightingBrightness>;
};

export type DeviceLightingBrightnessConstraints = {
	level: DeviceLightingBrightnessFieldConstraints;
};

export type DeviceLightingBrightnessFieldConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type DeviceLightingBrightnessUpdateInput = {
	id: Scalars['ID']['input'];
	level: Scalars['Int']['input'];
};

export type DeviceLightingLiveAudioMeters = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceLightingLiveAudioMetersChange = {
	lightingLiveAudioMeters: Maybe<DeviceLightingLiveAudioMeters>;
};

export type DeviceLightingLiveAudioMetersUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLightingMuteBehavior = {
	color: LightingColor | `${LightingColor}`;
	mode: LightingMode | `${LightingMode}`;
};

export type DeviceLightingMuteBehaviorChange = {
	lightingMuteBehavior: Maybe<DeviceLightingMuteBehavior>;
};

export type DeviceLightingMuteBehaviorConstraints = {
	color: DeviceLightingMuteColorConstraints;
	mode: DeviceLightingMuteModeConstraints;
};

export type DeviceLightingMuteColorConstraints = {
	options: Array<LightingColor | `${LightingColor}`>;
};

export type DeviceLightingMuteModeConstraints = {
	options: Array<LightingMode | `${LightingMode}`>;
};

export type DeviceLightingRing = {
	style: LightingStyle | `${LightingStyle}`;
};

export type DeviceLightingRingChange = {
	lightingRing: Maybe<DeviceLightingRing>;
};

export type DeviceLightingRingConstraints = {
	style: DeviceLightingRingStyleConstraints;
};

export type DeviceLightingRingStyleConstraints = {
	options: Array<LightingStyle | `${LightingStyle}`>;
};

export type DeviceLightingRingUpdateInput = {
	style?: InputMaybe<LightingStyle | `${LightingStyle}`>;
};

export type DeviceLightingSplitModeBreathingMeterColorChange = {
	lightingSplitModeBreathingMeterColor: Maybe<DeviceLightingSplitModeMeterColor>;
};

export type DeviceLightingSplitModeMeterColor = {
	auxiliaryCustomThemeLightColor: RgbColorMix;
	primaryCustomThemeLightColor: RgbColorMix;
	theme: ColorTheme | `${ColorTheme}`;
};

export type DeviceLightingSplitModeMeterColorConstraints = {
	auxiliaryCustomThemeLightColor: RgbColorMixConstraints;
	primaryCustomThemeLightColor: RgbColorMixConstraints;
	theme: ColorThemeConstraints;
};

export type DeviceLightingSplitModeMeterColorUpdateInput = {
	auxiliaryCustomThemeLightColor?: InputMaybe<RgbColorMixInput>;
	primaryCustomThemeLightColor?: InputMaybe<RgbColorMixInput>;
	theme?: InputMaybe<ColorTheme | `${ColorTheme}`>;
};

export type DeviceLightingSplitModeSolidMeterColorChange = {
	lightingSplitModeSolidMeterColor: Maybe<DeviceLightingSplitModeMeterColor>;
};

export type DeviceLightingTouchPanelMode = {
	/** Indicates whether the lighting touch panel mode should switch automatically whenever new input is connected/disconnected to/from the device. */
	automaticModeChange: Scalars['Boolean']['output'];
	/**
	 * Lighting touch panel mode controls the lighting display and also the mute control of the device.
	 *
	 *
	 * Split Mode: The lighting display is split into multiple segments represent different input signals of the device. Each segment's lighting color can be customized and also the mute status of each input can be controlled separately by tapping the specific segment of the lighing panel.
	 *
	 *
	 * Combined Mode: One single lighting display is shown for all inputs of the device. Similarly, the mute status changes would be applicable for all inputs of the device.
	 */
	mode: LightingTouchPanelMode | `${LightingTouchPanelMode}`;
};

export type DeviceLightingTouchPanelModeChange = {
	lightingTouchPanelMode: Maybe<DeviceLightingTouchPanelMode>;
};

export type DeviceLightingTouchPanelModeConstraints = {
	mode: DeviceLightingTouchPanelModeTypeConstraints;
};

export type DeviceLightingTouchPanelModeTypeConstraints = {
	options: Array<LightingTouchPanelMode | `${LightingTouchPanelMode}`>;
};

export type DeviceLightingTouchPanelModeUpdateInput = {
	automaticModeChange?: InputMaybe<Scalars['Boolean']['input']>;
	mode?: InputMaybe<LightingTouchPanelMode | `${LightingTouchPanelMode}`>;
};

export type DeviceLightingUnmuteBehavior = {
	color: LightingColor | `${LightingColor}`;
	mode: LightingMode | `${LightingMode}`;
};

export type DeviceLightingUnmuteBehaviorChange = {
	lightingUnmuteBehavior: Maybe<DeviceLightingUnmuteBehavior>;
};

export type DeviceLightingUnmuteBehaviorConstraints = {
	color: DeviceLightingUnmuteColorConstraints;
	mode: DeviceLightingUnmuteModeConstraints;
};

export type DeviceLightingUnmuteColorConstraints = {
	options: Array<LightingColor | `${LightingColor}`>;
};

export type DeviceLightingUnmuteModeConstraints = {
	options: Array<LightingMode | `${LightingMode}`>;
};

export type DeviceLinkButtonLock = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceLinkButtonLockChange = {
	linkButtonLock: Maybe<DeviceLinkButtonLock>;
};

export type DeviceLinkButtonLockUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLinkage = {
	/** device and rfChannel fields are null when there is a linkage established with an access point but the access point isn't discovered */
	device: Maybe<Device>;
	rfChannel: Maybe<RfChannel>;
};

export type DeviceLinkedAccessPoint = {
	/** linkage field is null when there is no linkage established with an access point */
	linkage: Maybe<DeviceLinkage>;
};

export type DeviceLinkedAccessPointChange = {
	linkedAccessPoint: Maybe<DeviceLinkedAccessPoint>;
};

export type DeviceLinkingDetailResult = {
	chargerBay: ChargerBay;
	rfChannel: RfChannel;
	status: LinkingStatus | `${LinkingStatus}`;
};

export type DeviceLinkingInput = {
	chargerBay: Scalars['ID']['input'];
	rfChannel: Scalars['ID']['input'];
};

/** Listener height values are scaled to represent in millimeters (mm) */
export type DeviceListenerHeight = {
	height: Scalars['Float']['output'];
};

export type DeviceListenerHeightChange = {
	listenerHeight: Maybe<DeviceListenerHeight>;
};

export type DeviceListenerHeightConstraints = {
	height: DeviceListenerHeightFieldConstraints;
};

export type DeviceListenerHeightFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type DeviceListenerHeightUpdateInput = {
	height: Scalars['Float']['input'];
};

export type DeviceLogicMute = {
	muted: Scalars['Boolean']['output'];
};

export type DeviceLogicMuteChange = {
	logicMute: Maybe<DeviceLogicMute>;
};

export type DeviceLogicMuteUpdateInput = {
	muted: Scalars['Boolean']['input'];
};

export type DeviceLogicReceiver = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceLogicReceiverChange = {
	logicReceiver: Maybe<DeviceLogicReceiver>;
};

export type DeviceLogicReceiverUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceLogicTransmitter = {
	inputLightingSignal: Scalars['Boolean']['output'];
	outputSignal: Scalars['Boolean']['output'];
};

export type DeviceLogicTransmitterChange = {
	logicTransmitter: Maybe<DeviceLogicTransmitter>;
};

export type DeviceLoudspeaker = {
	available: Scalars['Boolean']['output'];
	enabled: Scalars['Boolean']['output'];
};

export type DeviceLoudspeakerChange = {
	loudspeaker: Maybe<DeviceLoudspeaker>;
};

export type DeviceLoudspeakerUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeviceMdnsService = {
	enabled: Scalars['Boolean']['output'];
	port: Scalars['Int']['output'];
};

export type DeviceMdnsServiceChange = {
	mdnsService: Maybe<DeviceMdnsService>;
};

export type DeviceMdnsServiceUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceMeteringModes = {
	inputMode: MeteringMode | `${MeteringMode}`;
	outputMode: MeteringMode | `${MeteringMode}`;
};

export type DeviceMeteringModesChange = {
	meteringModes: Maybe<DeviceMeteringModes>;
};

export type DeviceMeteringModesUpdateInput = {
	inputMode?: InputMaybe<MeteringMode | `${MeteringMode}`>;
	outputMode?: InputMaybe<MeteringMode | `${MeteringMode}`>;
};

export type DeviceMicStatus = {
	status: MicStatus | `${MicStatus}`;
};

export type DeviceMicStatusChange = {
	micStatus: Maybe<DeviceMicStatus>;
};

export type DeviceMicStatusUpdateInput = {
	status: MicStatusInput | `${MicStatusInput}`;
};

export type DeviceName = {
	name: Scalars['String']['output'];
};

export type DeviceNameChange = {
	name: Maybe<DeviceName>;
};

export type DeviceNameConflict = {
	/** Audio network device name, which is in conflict */
	audioNetworkDeviceName: Scalars['String']['output'];
	/** Device that has a conflicting Network Audio device name */
	device: Device;
};

export type DeviceNameConstraints = {
	name: DeviceNameFieldConstraints;
};

export type DeviceNameFieldConstraints = IStringPatternRangeConstraints &
	IUtf8StringMaxBytesConstraints & {
		pattern: Maybe<Scalars['Regex']['output']>;
		utf8MaxBytes: Maybe<Scalars['Int']['output']>;
	};

export type DeviceNameUpdateInput = {
	name: Scalars['String']['input'];
};

export type DeviceNetworkInterface = {
	/** Example: Windows: (GUID) '{60B5DC8A-837A-44BC-B594-5027416EF5CC}', Unix: 'en0' */
	id: Scalars['String']['output'];
	ipAddress: Scalars['IpAddress']['output'];
	/**
	 * Display name, or 'Friendly name', of network interface.
	 * Example: Windows: 'Test Network', Mac: 'Thunderbolt Ethernet Slot 1'. Note: Will be copy of 'id' field for Linux.
	 */
	name: Scalars['String']['output'];
};

export type DeviceOnCharger = {
	docked: Scalars['Boolean']['output'];
};

export type DeviceOnChargerChange = {
	onCharger: Maybe<DeviceOnCharger>;
};

export type DeviceOperationResult = NodeOperationResult & {
	device: Maybe<Device>;
	error: Maybe<OperationError>;
};

export type DeviceOptimizationResult = {
	device: Maybe<Device>;
	status: DeviceOptimizationStatus | `${DeviceOptimizationStatus}`;
};

export enum DeviceOptimizationStatus {
	Conflict = 'CONFLICT',
	DeviceUnavailable = 'DEVICE_UNAVAILABLE',
	Failure = 'FAILURE',
	PartialSuccess = 'PARTIAL_SUCCESS',
	Success = 'SUCCESS',
	Unsupported = 'UNSUPPORTED'
}

export type DevicePcSpecification = {
	manufacturer: Maybe<Scalars['String']['output']>;
	memory: Maybe<Scalars['String']['output']>;
	model: Maybe<Scalars['String']['output']>;
	operatingSystem: Maybe<OperatingSystem>;
	processorInfo: Maybe<ProcessorInfo>;
};

export type DevicePlacement = {
	placement: Placement | `${Placement}`;
};

export type DevicePlacementChange = {
	placement: Maybe<DevicePlacement>;
};

export type DevicePlacementConstraints = {
	placement: DevicePlacementValuesConstraint;
};

export type DevicePlacementUpdateInput = {
	placement: Placement | `${Placement}`;
};

export type DevicePlacementValuesConstraint = {
	options: Array<Placement | `${Placement}`>;
};

/** The default value of the X/Y positions is uninitialized(i.e., null). Positions are scaled to represent in millimeters (mm) */
export type DevicePosition = {
	xPosition: Maybe<Scalars['Float']['output']>;
	yPosition: Maybe<Scalars['Float']['output']>;
};

export type DeviceProperty = {
	id: Scalars['ID']['output'];
	propertyInfo: Maybe<DevicePropertyInfo>;
	propertyKey: Scalars['String']['output'];
	propertyValue: Maybe<Scalars['String']['output']>;
};

export type DevicePropertyChangeEvent = {
	id: Scalars['ID']['output'];
	propertyKey: Scalars['String']['output'];
	propertyValue: Maybe<Scalars['String']['output']>;
};

export type DevicePropertyFilter = {
	get?: InputMaybe<Scalars['Boolean']['input']>;
	propertyKeys?: InputMaybe<Array<Scalars['String']['input']>>;
	public?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DevicePropertyInfo = {
	baseKey: Scalars['String']['output'];
	category: Scalars['String']['output'];
	eventReliable: Scalars['Boolean']['output'];
	get: Scalars['Boolean']['output'];
	isPublic: Scalars['Boolean']['output'];
	maxSize: Scalars['Int']['output'];
	propValueType: Maybe<Scalars['String']['output']>;
	set: Scalars['Boolean']['output'];
	subscribe: Scalars['Boolean']['output'];
};

export type DeviceProxiedDevices = {
	proxiedDevices: Array<Device>;
};

export type DeviceProxiedDevicesChange = {
	proxiedDevices: Maybe<DeviceProxiedDevices>;
};

export type DeviceRedundantAudioNetwork = {
	available: Scalars['Boolean']['output'];
	interface: Maybe<NetworkInterfaceInfo>;
};

export type DeviceRedundantAudioNetworkChange = {
	redundantAudioNetwork: Maybe<DeviceRedundantAudioNetwork>;
};

/**
 * Event emitted whenever a device is removed from management
 * (e.g. a physical device goes to DeviceState.Offline, a virtual device is deleted)
 */
export type DeviceRemoved = {
	removed: Scalars['ID']['output'];
};

export enum DeviceResetScope {
	/** use audio channel id as request id for resetDevice mutation */
	Automixer = 'AUTOMIXER',
	/** use device id as request id for resetDevice mutation */
	CoverageAreas = 'COVERAGE_AREAS',
	/** use audio channel id as request id for resetDevice mutation */
	EqualizerFilters = 'EQUALIZER_FILTERS',
	/** use device id as request id for resetDevice mutation */
	Factory = 'FACTORY',
	/** use device id as request id for resetDevice mutation */
	Networks = 'NETWORKS',
	/** use device id as request id for resetDevice mutation */
	UserPresetable = 'USER_PRESETABLE'
}

export type DeviceRfBand = {
	/**
	 * The name of the radio frequency band used by the device. (e.g. Z11, G57+) This will also be indicated somewhere on the device.
	 * (e.g. a label or badge)
	 */
	band: Scalars['String']['output'];
	/** A display name for the band which may be more easily recognized (e.g. DECT) */
	displayName: Maybe<Scalars['String']['output']>;
	/**
	 * The overall radio frequency range covered by the band.
	 * The band may be non-contiguous, and portions within the band may not be usable for regulatory reasons.
	 */
	frequencyRange: FrequencyRange;
};

export type DeviceRfChannels = {
	/** RF channels present on the device */
	rfChannels: Array<RfChannel>;
};

export type DeviceRfDensityMode = {
	mode: RfDensityMode | `${RfDensityMode}`;
};

export type DeviceRfDensityModeChange = {
	rfDensityMode: Maybe<DeviceRfDensityMode>;
};

export type DeviceRfDensityModeConstraints = {
	mode: Maybe<RfDensityModeConstraints>;
};

export type DeviceRfDensityModeUpdateInput = {
	mode: RfDensityMode | `${RfDensityMode}`;
};

export type DeviceRfPower = {
	level: RfPower | `${RfPower}`;
};

export type DeviceRfPowerChange = {
	rfPower: Maybe<DeviceRfPower>;
};

export type DeviceRfPowerConstraints = {
	level: RfPowerConstraints;
};

export type DeviceRfPowerUpdateInput = {
	level: RfPower | `${RfPower}`;
};

/**
 * The room that this device is currently in
 *
 * NOTE: This feature may be transiently null when the device is first discovered.
 */
export type DeviceRoom = {
	room: Maybe<Room>;
};

export type DeviceRoomChange = {
	room: Maybe<DeviceRoom>;
};

export type DeviceRotation = {
	xAxis: Scalars['Int']['output'];
	yAxis: Scalars['Int']['output'];
	zAxis: Scalars['Int']['output'];
};

export type DeviceRotationAxisConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type DeviceRotationChange = {
	rotation: Maybe<DeviceRotation>;
};

export type DeviceRotationConstraints = {
	xAxis: DeviceRotationAxisConstraints;
	yAxis: DeviceRotationAxisConstraints;
	zAxis: DeviceRotationAxisConstraints;
};

export type DeviceRotationUpdateInput = {
	xAxis?: InputMaybe<Scalars['Int']['input']>;
	yAxis?: InputMaybe<Scalars['Int']['input']>;
	zAxis?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceRoutingPositionChange = {
	routingPosition: Maybe<DevicePosition>;
};

export type DeviceRoutingPositionInput = {
	xPosition: Scalars['Float']['input'];
	yPosition: Scalars['Float']['input'];
};

export type DeviceSecurity8021X = {
	anonymousId: Maybe<Scalars['String']['output']>;
	clientCertificate: Maybe<CertificateInfo8021X>;
	eapMethod: EapMethod8021X | `${EapMethod8021X}`;
	enabled: Scalars['Boolean']['output'];
	lastAuthenticationStatus: LastAuthenticationStatus8021X | `${LastAuthenticationStatus8021X}`;
	rebootRequired: Scalars['Boolean']['output'];
	rootCertificate: Maybe<CertificateInfo8021X>;
	username: Maybe<Scalars['String']['output']>;
};

export type DeviceSecurity8021XAnonymousIdConstraints = IStringPatternRangeConstraints & {
	pattern: Scalars['Regex']['output'];
};

export type DeviceSecurity8021XChange = {
	security8021X: Maybe<DeviceSecurity8021X>;
};

export type DeviceSecurity8021XConstraints = {
	anonymousId: DeviceSecurity8021XAnonymousIdConstraints;
	eapMethod: DeviceSecurity8021XConstraintsEapMethods;
	password: DeviceSecurity8021XPasswordConstraints;
	username: DeviceSecurity8021XUserNameConstraints;
};

export type DeviceSecurity8021XConstraintsEapMethods = {
	options: Array<EapMethod8021X | `${EapMethod8021X}`>;
};

export type DeviceSecurity8021XPasswordConstraints = IStringPatternRangeConstraints & {
	pattern: Scalars['Regex']['output'];
};

export type DeviceSecurity8021XUserNameConstraints = IStringPatternRangeConstraints & {
	pattern: Scalars['Regex']['output'];
};

export type DeviceSerialNumber = {
	serialNumber: Scalars['String']['output'];
};

export type DeviceSingleLinkingResult = {
	detail: Maybe<DeviceLinkingDetailResult>;
	overallResult: LinkingOperationResult | `${LinkingOperationResult}`;
};

export type DeviceSingleUnlinkingResult = {
	detail: Maybe<DeviceUnlinkingDetailResult>;
	overallResult: UnlinkingOperationResult | `${UnlinkingOperationResult}`;
};

/**
 * The smart gating feature helps to reduce cross talk between multiple audio inputs by distinguishing the primary talker from non-primary talker(s).
 * The information is used to determine how multiple audio signals are mixed together.
 */
export type DeviceSmartGate = {
	/** When enabled, it will help reduce cross talk between multiple different audio inputs of the device */
	enabled: Scalars['Boolean']['output'];
};

export type DeviceSmartGateChange = {
	smartGate: Maybe<DeviceSmartGate>;
};

export type DeviceSmartGateUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export enum DeviceSpecificEqualizerContour {
	Mxa310 = 'MXA310',
	Mxa710LowShelf = 'MXA710_LOW_SHELF',
	Mxa901HighPass = 'MXA901_HIGH_PASS',
	Mxa902HighPass = 'MXA902_HIGH_PASS',
	Mxa910 = 'MXA910',
	Mxa910HighPass = 'MXA910_HIGH_PASS',
	Mxa910MultiBand = 'MXA910_MULTI_BAND',
	Mxa920HighPass = 'MXA920_HIGH_PASS',
	Off = 'OFF'
}

export enum DeviceStatus {
	/**
	 * The device was discovered by a particular communication protocol,
	 * but is yet not ready to be interacted with. This state is normally transient.
	 *
	 * Factory reset and reboot operations are allowed if applicable, but with no guarantees of success.
	 */
	Discovered = 'DISCOVERED',
	/**
	 * The device was discovered, but is in an unusable state for further feature interaction.
	 *
	 * Factory reset and reboot operations are allowed if applicable, but with no guarantees of success.
	 */
	Error = 'ERROR',
	/**
	 * A connection can no logner be established with the device.
	 *
	 * This state may be transient when performing an operation which reboots the device.
	 * (e.g. factory reset, rebooting the ethernet switch a device is powered by, etc)
	 */
	Offline = 'OFFLINE',
	/** The device and all of its features can be interacted with */
	Online = 'ONLINE'
}

export type DeviceStatusChange = {
	id: Scalars['ID']['output'];
	status: DeviceStatus | `${DeviceStatus}`;
};

export type DeviceStereoAudio = {
	available: Scalars['Boolean']['output'];
	enabled: Scalars['Boolean']['output'];
};

export type DeviceStereoAudioChange = {
	stereoAudio: Maybe<DeviceStereoAudio>;
};

export type DeviceStereoAudioUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceSupportedFeatures = {
	activePreset: FeatureSupport;
	allEqualizersBypass: FeatureSupport;
	allIntellimixBypass: FeatureSupport;
	analogAudioOutputMode: FeatureSupport;
	analogLogic: FeatureSupport;
	analogLogicPresetSwitch: FeatureSupport;
	analogLogicPresetSwitchStatus: FeatureSupport;
	audioAutoFocus: FeatureSupport;
	audioChannelCount: FeatureSupport;
	audioChannels: FeatureSupport;
	audioCoverageAreas: FeatureSupport;
	audioMute: FeatureSupport;
	audioMuteControl: FeatureSupport;
	audioMuteControlGroup: FeatureSupport;
	audioMuteDefault: FeatureSupport;
	audioMuteLock: FeatureSupport;
	audioNetwork: FeatureSupport;
	audioNetworkInputChannelCount: FeatureSupport;
	audioNetworkInterface: FeatureSupport;
	audioNetworkNameSync: FeatureSupport;
	audioNetworkOutputChannelCount: FeatureSupport;
	authentication: FeatureSupport;
	automixGainMetering: FeatureSupport;
	availablePackages: FeatureSupport;
	batteryHealth: FeatureSupport;
	batteryLevel: FeatureSupport;
	bodypackMicrophoneMode: FeatureSupport;
	callStatus: FeatureSupport;
	chargerBays: FeatureSupport;
	commandStringsService: FeatureSupport;
	conferencingOperationMode: FeatureSupport;
	conferencingOperationModeButtonLock: FeatureSupport;
	configurationLock: FeatureSupport;
	connectedDeviceLabel: FeatureSupport;
	controlNetwork: FeatureSupport;
	controlNetworkInterface: FeatureSupport;
	coverageMode: FeatureSupport;
	coveragePosition: FeatureSupport;
	danteAudioEncryption: FeatureSupport;
	danteAudioNetwork: FeatureSupport;
	danteAudioNetworkAccess: FeatureSupport;
	dectChannelCount: FeatureSupport;
	dectRfSpectrumScan: FeatureSupport;
	deployment: FeatureSupport;
	dnsClientConfiguration: FeatureSupport;
	/** @deprecated For Cloud Managed Rooms Use Only. */
	doublestuffProxiedTransmitters: FeatureSupport;
	equalizerContour: FeatureSupport;
	errorIndicator: FeatureSupport;
	ethernetLights: FeatureSupport;
	ethernetPowerSource: FeatureSupport;
	eventLogExport: FeatureSupport;
	factoryReset: FeatureSupport;
	firmware: FeatureSupport;
	firmwareUpdatePolicy: FeatureSupport;
	gatewayDevice: FeatureSupport;
	globalMicStatus: FeatureSupport;
	height: FeatureSupport;
	identify: FeatureSupport;
	internetOfThings: FeatureSupport;
	license: FeatureSupport;
	licenseV2: FeatureSupport;
	lightingAudioActivity: FeatureSupport;
	lightingAudioMeters: FeatureSupport;
	lightingAudioMuteIndicatorOverride: FeatureSupport;
	lightingAutomixGating: FeatureSupport;
	lightingBehavior: FeatureSupport;
	lightingBrightness: FeatureSupport;
	lightingLiveAudioMeters: FeatureSupport;
	lightingMuteBehavior: FeatureSupport;
	lightingRing: FeatureSupport;
	lightingSplitModeBreathingMeterColor: FeatureSupport;
	lightingSplitModeSolidMeterColor: FeatureSupport;
	lightingTouchPanelMode: FeatureSupport;
	lightingUnmuteBehavior: FeatureSupport;
	linkButtonLock: FeatureSupport;
	linkedAccessPoint: FeatureSupport;
	listenerHeight: FeatureSupport;
	logicMute: FeatureSupport;
	logicReceiver: FeatureSupport;
	logicTransmitter: FeatureSupport;
	loudspeaker: FeatureSupport;
	lowPowerStandbyMode: FeatureSupport;
	mdnsService: FeatureSupport;
	meteringModes: FeatureSupport;
	micStatus: FeatureSupport;
	name: FeatureSupport;
	onCharger: FeatureSupport;
	pcSpecification: FeatureSupport;
	placement: FeatureSupport;
	powerOff: FeatureSupport;
	proxiedDevices: FeatureSupport;
	reboot: FeatureSupport;
	redundantAudioNetwork: FeatureSupport;
	rfBand: FeatureSupport;
	rfChannels: FeatureSupport;
	rfDensityMode: FeatureSupport;
	rfPower: FeatureSupport;
	room: FeatureSupport;
	rotation: FeatureSupport;
	routingPosition: FeatureSupport;
	security8021X: FeatureSupport;
	serialNumber: FeatureSupport;
	smartGate: FeatureSupport;
	stereoAudio: FeatureSupport;
	switchConfiguration: FeatureSupport;
	tags: FeatureSupport;
	transmitterIdentifyBehavior: FeatureSupport;
	transmitterInitialStateFromCharger: FeatureSupport;
	transmitterLightingBehavior: FeatureSupport;
	transmitterMuteBehavior: FeatureSupport;
	transmitterOutOfRangeBehavior: FeatureSupport;
	transmitterRejoinBehavior: FeatureSupport;
	transmitterSwitchBehavior: FeatureSupport;
	updateProgress: FeatureSupport;
	uptime: FeatureSupport;
	usbMuteSync: FeatureSupport;
	usbTerminalType: FeatureSupport;
	userPresets: FeatureSupport;
	virtual: FeatureSupport;
	virtualAcousticBoundary: FeatureSupport;
	webService: FeatureSupport;
};

export type DeviceSwitchConfiguration = {
	mode: Maybe<NetworkMode | `${NetworkMode}`>;
};

export type DeviceSwitchConfigurationChange = {
	switchConfiguration: Maybe<DeviceSwitchConfiguration>;
};

export type DeviceSwitchConfigurationConstraints = {
	mode: DeviceSwitchConfigurationModeConstraints;
};

export type DeviceSwitchConfigurationModeConstraints = {
	options: Array<Maybe<NetworkMode | `${NetworkMode}`>>;
};

export type DeviceSwitchConfigurationUpdateInput = {
	id: Scalars['ID']['input'];
	mode: NetworkMode | `${NetworkMode}`;
};

export type DeviceTags = {
	tags: Array<Scalars['String']['output']>;
};

export type DeviceTagsChange = {
	tags: Maybe<DeviceTags>;
};

export type DeviceTransmitterIdentifyAlarm = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceTransmitterIdentifyAlarmInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceTransmitterIdentifyBehavior = {
	alarm: DeviceTransmitterIdentifyAlarm;
};

export type DeviceTransmitterIdentifyBehaviorChange = {
	transmitterIdentifyBehavior: Maybe<DeviceTransmitterIdentifyBehavior>;
};

export type DeviceTransmitterIdentifyBehaviorInput = {
	alarm?: InputMaybe<DeviceTransmitterIdentifyAlarmInput>;
};

export type DeviceTransmitterInitialStateFromCharger = {
	bodypack: TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`;
	boundary: TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`;
	gooseneck: TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`;
	handheld: TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`;
};

export type DeviceTransmitterInitialStateFromChargerChange = {
	transmitterInitialStateFromCharger: Maybe<DeviceTransmitterInitialStateFromCharger>;
};

export type DeviceTransmitterInitialStateFromChargerConstraints = {
	bodypack: TransmitterInitialStateFromChargerConstraints;
	boundary: TransmitterInitialStateFromChargerConstraints;
	gooseneck: TransmitterInitialStateFromChargerConstraints;
	handheld: TransmitterInitialStateFromChargerConstraints;
};

export type DeviceTransmitterInitialStateFromChargerInput = {
	bodypack?: InputMaybe<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
	boundary?: InputMaybe<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
	gooseneck?: InputMaybe<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
	handheld?: InputMaybe<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
};

export type DeviceTransmitterLightingBehavior = {
	behavior: TransmitterLightingBehavior | `${TransmitterLightingBehavior}`;
};

export type DeviceTransmitterLightingBehaviorChange = {
	transmitterLightingBehavior: Maybe<DeviceTransmitterLightingBehavior>;
};

export type DeviceTransmitterLightingBehaviorConstraints = {
	behavior: Maybe<TransmitterLightingBehaviorConstraints>;
};

export type DeviceTransmitterLightingBehaviorUpdateInput = {
	behavior: TransmitterLightingBehavior | `${TransmitterLightingBehavior}`;
};

export type DeviceTransmitterMuteBehavior = {
	muteBehavior: TransmitterMuteBehavior | `${TransmitterMuteBehavior}`;
	/**
	 * Different options are available when changing conferencingOperationMode.
	 * muteBehavior cannot be adjusted if muteBehaviorOptions is null.
	 */
	muteBehaviorOptions: Maybe<Array<TransmitterMuteBehavior | `${TransmitterMuteBehavior}`>>;
	usbHostDisconnectMuteBehavior:
		| TransmitterUsbHostDisconnectMuteBehavior
		| `${TransmitterUsbHostDisconnectMuteBehavior}`;
	/**
	 * Different options are available when changing conferencingOperationMode.
	 * usbHostDisconnectMuteBehavior cannot be adjusted if usbHostDisconnectMuteBehaviorOptions is null.
	 */
	usbHostDisconnectMuteBehaviorOptions: Maybe<
		Array<TransmitterUsbHostDisconnectMuteBehavior | `${TransmitterUsbHostDisconnectMuteBehavior}`>
	>;
};

export type DeviceTransmitterMuteBehaviorChange = {
	transmitterMuteBehavior: Maybe<DeviceTransmitterMuteBehavior>;
};

export type DeviceTransmitterMuteBehaviorUpdateInput = {
	muteBehavior?: InputMaybe<TransmitterMuteBehavior | `${TransmitterMuteBehavior}`>;
	usbHostDisconnectMuteBehavior?: InputMaybe<
		TransmitterUsbHostDisconnectMuteBehavior | `${TransmitterUsbHostDisconnectMuteBehavior}`
	>;
};

export type DeviceTransmitterOutOfRangeAlarm = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceTransmitterOutOfRangeAlarmInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceTransmitterOutOfRangeBehavior = {
	alarm: DeviceTransmitterOutOfRangeAlarm;
};

export type DeviceTransmitterOutOfRangeBehaviorChange = {
	transmitterOutOfRangeBehavior: Maybe<DeviceTransmitterOutOfRangeBehavior>;
};

export type DeviceTransmitterOutOfRangeBehaviorInput = {
	alarm?: InputMaybe<DeviceTransmitterOutOfRangeAlarmInput>;
};

export type DeviceTransmitterRejoinAudioMute = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceTransmitterRejoinAudioMuteInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceTransmitterRejoinBehavior = {
	audioMute: DeviceTransmitterRejoinAudioMute;
};

export type DeviceTransmitterRejoinBehaviorChange = {
	transmitterRejoinBehavior: Maybe<DeviceTransmitterRejoinBehavior>;
};

export type DeviceTransmitterRejoinBehaviorInput = {
	audioMute?: InputMaybe<DeviceTransmitterRejoinAudioMuteInput>;
};

export type DeviceTransmitterSwitchBehavior = {
	behaviorOptions: Array<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
	bodypack: TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`;
	boundary: TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`;
	gooseneck: TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`;
	handheld: TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`;
};

export type DeviceTransmitterSwitchBehaviorChange = {
	transmitterSwitchBehavior: Maybe<DeviceTransmitterSwitchBehavior>;
};

export type DeviceTransmitterSwitchBehaviorUpdateInput = {
	bodypack?: InputMaybe<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
	boundary?: InputMaybe<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
	gooseneck?: InputMaybe<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
	handheld?: InputMaybe<TransmitterSwitchBehavior | `${TransmitterSwitchBehavior}`>;
};

export type DeviceUnlinkingChargerBayResult = {
	chargerBay: ChargerBay;
	success: Scalars['Boolean']['output'];
};

export type DeviceUnlinkingDetailResult = DeviceUnlinkingChargerBayResult | DeviceUnlinkingRfChannelResult;

export type DeviceUnlinkingRfChannelResult = {
	rfChannel: RfChannel;
	success: Scalars['Boolean']['output'];
};

export type DeviceUpdateInput = {
	features?: InputMaybe<DeviceFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export type DeviceUpdateProgress = {
	deviceProgressPercentage: Scalars['Int']['output'];
	firmwarePackageKey: Scalars['String']['output'];
	firmwarePackageVersion: Scalars['String']['output'];
	stageProgressPercentage: Scalars['Int']['output'];
	updateStage: FirmwareUpdateStage | `${FirmwareUpdateStage}`;
	updateStatus: FirmwareUpdateStatus | `${FirmwareUpdateStatus}`;
};

export type DeviceUpdateProgressChange = {
	updateProgress: Maybe<DeviceUpdateProgress>;
};

export type DeviceUptime = {
	uptime: Scalars['Duration']['output'];
};

export type DeviceUptimeChange = {
	uptime: Maybe<DeviceUptime>;
};

export type DeviceUsbMuteSync = {
	enabled: Scalars['Boolean']['output'];
};

export type DeviceUsbMuteSyncChange = {
	usbMuteSync: Maybe<DeviceUsbMuteSync>;
};

export type DeviceUsbMuteSyncUpdateInput = {
	enabled: Scalars['Boolean']['input'];
};

export type DeviceUsbTerminalType = {
	terminalType: UsbTerminalType | `${UsbTerminalType}`;
};

export type DeviceUsbTerminalTypeChange = {
	usbTerminalType: Maybe<DeviceUsbTerminalType>;
};

export type DeviceUsbTerminalTypeConstraints = {
	terminalType: DeviceValidUsbTerminalTypeConstraints;
};

export type DeviceUsbTerminalTypeUpdateInput = {
	terminalType: UsbTerminalType | `${UsbTerminalType}`;
};

export type DeviceUserPresets = {
	userPresets: Array<UserPreset>;
};

export type DeviceUserPresetsChange = {
	userPresets: Maybe<DeviceUserPresets>;
};

export type DeviceValidEthernetPowerSourceConstraints = {
	options: Array<EthernetPowerSourceType | `${EthernetPowerSourceType}`>;
};

export type DeviceValidUsbTerminalTypeConstraints = {
	options: Array<UsbTerminalType | `${UsbTerminalType}`>;
};

export type DeviceVirtual = {
	origin: Maybe<OriginDevice>;
	virtual: Scalars['Boolean']['output'];
};

export type DeviceVirtualAcousticBoundary = {
	enabled: Scalars['Boolean']['output'];
	sensitivity: Scalars['Int']['output'];
};

export type DeviceVirtualAcousticBoundaryChange = {
	virtualAcousticBoundary: Maybe<DeviceVirtualAcousticBoundary>;
};

export type DeviceVirtualAcousticBoundaryConstraints = {
	sensitivity: Maybe<DeviceVirtualAcousticBoundarySensitivityConstraints>;
};

export type DeviceVirtualAcousticBoundarySensitivityConstraints = IIntRangeConstraints & {
	/** Describes the range of values for sensitivity */
	range: IntRange;
};

export type DeviceVirtualAcousticBoundaryUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	sensitivity?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceWebService = {
	deviceRestApi: Maybe<Scalars['Boolean']['output']>;
	enabled: Scalars['Boolean']['output'];
	port: Scalars['Int']['output'];
};

export type DeviceWebServiceChange = {
	webService: Maybe<DeviceWebService>;
};

export type DeviceWebServiceConstraints = {
	deviceRestApi: FieldSupport;
};

export type DeviceWebServiceUpdateInput = {
	/** When the Web Service is disabled, the device api services are also disabled */
	deviceRestApi?: InputMaybe<Scalars['Boolean']['input']>;
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DevicesConnection = {
	edges: Array<DeviceEdge>;
	pageInfo: PageInfo;
};

export enum DirectOutputTapPoint {
	/** Sends a signal with automixer gating, AEC, and noise reduction, but without AGC, to the matrix mixer. */
	PostProcessingPostGate = 'POST_PROCESSING_POST_GATE',
	/** Sends a signal with AEC and noise reduction, but without automixer gating or AGC, to the matrix mixer. */
	PostProcessingPreGate = 'POST_PROCESSING_PRE_GATE',
	/** Sends a signal with automixer gating, but without AEC, noise reduction, or AGC, to the matrix mixer. */
	PreProcessingPostGate = 'PRE_PROCESSING_POST_GATE',
	/** Sends a signal without AEC, noise reduction, or AGC to the matrix mixer. */
	PreProcessingPreGate = 'PRE_PROCESSING_PRE_GATE'
}

export enum DisassociationStatus {
	/** Access point is not discoverable */
	AccessPointDeviceNotFound = 'ACCESS_POINT_DEVICE_NOT_FOUND',
	/** Charger device is not discoverable */
	ChargerDeviceNotFound = 'CHARGER_DEVICE_NOT_FOUND',
	/** Failed to disassociate RF Channel and Charger Bay */
	Failure = 'FAILURE',
	/** Input node id(s) provided in association request is not valid */
	InvalidInput = 'INVALID_INPUT',
	/** Provided input device(s) either does not support association feature or not a valid input for this operation */
	InvalidOperation = 'INVALID_OPERATION',
	/** Association is cleared successfully on Charger Bay */
	PartialSuccessChargerBay = 'PARTIAL_SUCCESS_CHARGER_BAY',
	/** Association is cleared successfully on RF Channel */
	PartialSuccessRfChannel = 'PARTIAL_SUCCESS_RF_CHANNEL',
	/** Successfully disassociated RF Channel and Charger Bay */
	Success = 'SUCCESS'
}

export type DiscoveredDevicesEvent = DeviceAdded | DeviceRemoved;

export type DiscoveredDevicesStatusChange = {
	device: Device;
};

export type DiscoveredRoomEvent = RoomAddedEvent | RoomRemovedEvent;

export type DmpCommunicationProtocol = {
	ipAddress: Scalars['IpAddress']['output'];
};

export type DoubleStuffProxiedTransmitter = {
	batteryLevelPercentage: Maybe<Scalars['Int']['output']>;
	muted: Maybe<Scalars['Boolean']['output']>;
	name: Scalars['String']['output'];
	online: Scalars['Boolean']['output'];
	status: Maybe<DoubleStuffProxiedTransmitterStatus | `${DoubleStuffProxiedTransmitterStatus}`>;
};

export enum DoubleStuffProxiedTransmitterStatus {
	Active = 'ACTIVE',
	Charging = 'CHARGING',
	Unknown = 'UNKNOWN'
}

export type DurationRange = {
	max: Scalars['Duration']['output'];
	min: Scalars['Duration']['output'];
	step: Scalars['Duration']['output'];
};

export enum EapMethod8021X {
	Disabled = 'DISABLED',
	EapMd5 = 'EAP_MD5',
	PeapGtc = 'PEAP_GTC',
	PeapMschapv2 = 'PEAP_MSCHAPV2',
	Pwd = 'PWD',
	Tls = 'TLS',
	TtlsGtc = 'TTLS_GTC',
	TtlsMd5 = 'TTLS_MD5',
	TtlsMschapv2 = 'TTLS_MSCHAPV2',
	TtlsPap = 'TTLS_PAP'
}

export enum EqFilterType {
	/** Rolls off the audio signal above the selected frequency */
	HighCut = 'HIGH_CUT',
	/** Attenuates or boosts the audio signal above the selected frequency */
	HighShelf = 'HIGH_SHELF',
	/** Rolls off the audio signal below the selected frequency */
	LowCut = 'LOW_CUT',
	/** Attenuates or boost the audio signal below the selected frequency */
	LowShelf = 'LOW_SHELF',
	/** Attenuates or boost the audio signal within a customizable frequency range */
	Parametric = 'PARAMETRIC'
}

export enum EqualizerContour {
	HighPass = 'HIGH_PASS',
	HighPassAndPresenceBoost = 'HIGH_PASS_AND_PRESENCE_BOOST',
	Hpf_75Hz = 'HPF_75_HZ',
	Hpf_150Hz = 'HPF_150_HZ',
	LowShelf = 'LOW_SHELF',
	MultiBand = 'MULTI_BAND',
	Off = 'OFF',
	PresenceBoost = 'PRESENCE_BOOST'
}

export type EqualizerEnabledConstraints = IFieldSupportInfo & {
	supported: Scalars['Boolean']['output'];
};

export type EqualizerFilter = Fragment &
	Node & {
		description: EqualizerFilterDescription;
		features: EqualizerFilterFeatures;
		fragmentId: Scalars['ID']['output'];
		id: Scalars['ID']['output'];
	};

export type EqualizerFilterBandwidth = {
	/** Range of frequencies affected by the filter */
	bandwidth: Fraction;
};

export type EqualizerFilterBandwidthChange = {
	bandwidth: Maybe<EqualizerFilterBandwidth>;
};

export type EqualizerFilterBandwidthConstraint = {
	options: Array<Fraction>;
};

export type EqualizerFilterBandwidthConstraints = {
	bandwidth: EqualizerFilterBandwidthConstraint;
};

export type EqualizerFilterBandwidthUpdateInput = {
	denominator: Scalars['Int']['input'];
	numerator: Scalars['Int']['input'];
};

export type EqualizerFilterChange = {
	features: EqualizerFilterFeatureChange;
	id: Scalars['ID']['output'];
};

export type EqualizerFilterConfiguration = {
	/** Indicates whether or not the filter is enabled */
	enabled: Scalars['Boolean']['output'];
	/** Equalizer Filter type */
	filterType: Maybe<EqFilterType | `${EqFilterType}`>;
};

export type EqualizerFilterConfigurationChange = {
	configuration: Maybe<EqualizerFilterConfiguration>;
};

export type EqualizerFilterConfigurationConstraints = {
	filterType: Maybe<ConfigurationFilterTypeConstraints>;
};

export type EqualizerFilterConfigurationUpdateInput = {
	enabled?: InputMaybe<Scalars['Boolean']['input']>;
	filterType?: InputMaybe<EqFilterType | `${EqFilterType}`>;
};

export type EqualizerFilterConstraints = {
	bandwidth: Maybe<EqualizerFilterBandwidthConstraints>;
	configuration: Maybe<EqualizerFilterConfigurationConstraints>;
	frequency: Maybe<EqualizerFilterFrequencyConstraints>;
	gain: Maybe<EqualizerFilterGainConstraints>;
	qFactor: Maybe<EqualizerFilterQFactorConstraints>;
};

export type EqualizerFilterDescription = {
	constraints: EqualizerFilterConstraints;
	features: EqualizerFilterSupportedFeatures;
	index: Scalars['Int']['output'];
};

export type EqualizerFilterFeatureChange =
	| EqualizerFilterBandwidthChange
	| EqualizerFilterConfigurationChange
	| EqualizerFilterFrequencyChange
	| EqualizerFilterGainChange
	| EqualizerFilterQFactorChange;

export type EqualizerFilterFeatureUpdateInput = {
	bandwidth?: InputMaybe<EqualizerFilterBandwidthUpdateInput>;
	configuration?: InputMaybe<EqualizerFilterConfigurationUpdateInput>;
	frequency?: InputMaybe<EqualizerFilterFrequencyUpdateInput>;
	gain?: InputMaybe<EqualizerFilterGainUpdateInput>;
	qFactor?: InputMaybe<EqualizerFilterQFactorUpdateInput>;
};

export type EqualizerFilterFeatures = {
	bandwidth: Maybe<EqualizerFilterBandwidth>;
	configuration: Maybe<EqualizerFilterConfiguration>;
	frequency: Maybe<EqualizerFilterFrequency>;
	gain: Maybe<EqualizerFilterGain>;
	qFactor: Maybe<EqualizerFilterQFactor>;
};

export type EqualizerFilterFrequency = {
	/** Center frequency (Hz) */
	frequency: Scalars['Float']['output'];
};

export type EqualizerFilterFrequencyChange = {
	frequency: Maybe<EqualizerFilterFrequency>;
};

export type EqualizerFilterFrequencyConstraints = {
	frequency: EqualizerFilterFrequencyFieldConstraints;
};

export type EqualizerFilterFrequencyFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type EqualizerFilterFrequencyUpdateInput = {
	frequency?: InputMaybe<Scalars['Float']['input']>;
};

export type EqualizerFilterGain = {
	/** Gain applied to frequencies affected by the filter (dB) */
	gain: Scalars['Float']['output'];
};

export type EqualizerFilterGainChange = {
	gain: Maybe<EqualizerFilterGain>;
};

export type EqualizerFilterGainConstraints = {
	gain: EqualizerFilterGainFieldConstraints;
};

export type EqualizerFilterGainFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type EqualizerFilterGainUpdateInput = {
	gain?: InputMaybe<Scalars['Float']['input']>;
};

export type EqualizerFilterOperationResult = NodeOperationResult & {
	equalizerFilter: Maybe<EqualizerFilter>;
	error: Maybe<OperationError>;
};

export type EqualizerFilterQFactor = {
	/** Range of frequencies affected by the filter, represented as a ratio of center frequency to bandwidth */
	qFactor: Scalars['Float']['output'];
};

export type EqualizerFilterQFactorChange = {
	qFactor: Maybe<EqualizerFilterQFactor>;
};

export type EqualizerFilterQFactorConstraints = {
	qFactor: QFactorConstraint;
};

export type EqualizerFilterQFactorUpdateInput = {
	qFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type EqualizerFilterSupportedFeatures = {
	bandwidth: FeatureSupport;
	configuration: FeatureSupport;
	frequency: FeatureSupport;
	gain: FeatureSupport;
	qFactor: FeatureSupport;
};

export type EqualizerFilterUpdateInput = {
	features?: InputMaybe<EqualizerFilterFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export type EqualizerFilterValidQFactors = {
	options: Array<Scalars['Float']['output']>;
};

export type EqualizerFiltersConstraints = IFieldSupportInfo & {
	query: Scalars['Boolean']['output'];
	subscribe: Scalars['Boolean']['output'];
	supported: Scalars['Boolean']['output'];
	updateNodes: Scalars['Boolean']['output'];
};

export enum EqualizerPreset {
	Acoustic = 'ACOUSTIC',
	Band = 'BAND',
	Flat = 'FLAT',
	Singing = 'SINGING',
	Speech = 'SPEECH'
}

export type EqualizerToneConstraints = IFieldSupportInfo &
	INullableIntRangeConstraints & {
		range: Maybe<IntRange>;
		supported: Scalars['Boolean']['output'];
	};

export enum ErrorCode {
	Conflict = 'CONFLICT',
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	DeviceUnauthenticated = 'DEVICE_UNAUTHENTICATED',
	InvalidInput = 'INVALID_INPUT',
	InvalidOperation = 'INVALID_OPERATION',
	NotImplemented = 'NOT_IMPLEMENTED',
	TimeoutExceeded = 'TIMEOUT_EXCEEDED',
	Unknown = 'UNKNOWN'
}

export enum ErrorIndicator {
	ClearableError = 'CLEARABLE_ERROR',
	NonClearableError = 'NON_CLEARABLE_ERROR',
	NoError = 'NO_ERROR'
}

export type EthernetConfig = {
	networkInterfaceId: Scalars['String']['input'];
};

export enum EthernetPowerSourceType {
	Poe = 'POE',
	PoePlus = 'POE_PLUS',
	PoePlusPlus = 'POE_PLUS_PLUS',
	Unknown = 'UNKNOWN'
}

export type EventLogExportResult = {
	error: Maybe<OperationError>;
	/** One or more lines of device events in a CSV formatted string */
	eventLog: Maybe<Scalars['String']['output']>;
};

export type FeatureSupport = {
	query: Scalars['Boolean']['output'];
	subscribe: Scalars['Boolean']['output'];
	supported: Scalars['Boolean']['output'];
	supportedMutations: Maybe<Array<Scalars['String']['output']>>;
	supportedSubscriptions: Maybe<Array<Scalars['String']['output']>>;
	updateNodes: Scalars['Boolean']['output'];
};

export type FieldSupport = IFieldSupportInfo & {
	supported: Scalars['Boolean']['output'];
};

export type FirmwarePackage = {
	description: Scalars['String']['output'];
	key: Scalars['String']['output'];
	releaseDate: Scalars['String']['output'];
	releaseNotesUrl: Scalars['String']['output'];
	version: Scalars['String']['output'];
};

export type FirmwarePackageDownloadInput = {
	firmwarePackageKey: Scalars['String']['input'];
	firmwarePackageVersion: Scalars['String']['input'];
};

export type FirmwarePackageDownloadProgress = Node & {
	id: Scalars['ID']['output'];
	/** The key of the firmware package being downloaded */
	key: Scalars['String']['output'];
	/** A percentage that indicates the progress of the firmware package download */
	progress: Scalars['Int']['output'];
	/** The current stage of the firmware package download */
	stage: FirmwarePackageDownloadStage | `${FirmwarePackageDownloadStage}`;
	/** The version of the firmware package being downloaded */
	version: Scalars['String']['output'];
};

export type FirmwarePackageDownloadRequestProgress = Node & {
	id: Scalars['ID']['output'];
	/** The progress for each package in the firmware package download request */
	packages: Array<FirmwarePackageDownloadProgress>;
	/** A percentage that indicates the overall progress of the firmware package download request */
	progress: Scalars['Int']['output'];
	/** A unique identifier for the firmware package download request */
	requestId: Scalars['Guid']['output'];
	/** The current state of the firmware package download request */
	state: FirmwarePackageDownloadRequestState | `${FirmwarePackageDownloadRequestState}`;
};

export enum FirmwarePackageDownloadRequestState {
	Canceled = 'CANCELED',
	Failed = 'FAILED',
	InProgress = 'IN_PROGRESS',
	Pending = 'PENDING',
	Successful = 'SUCCESSFUL'
}

export enum FirmwarePackageDownloadStage {
	AlreadyDownloaded = 'ALREADY_DOWNLOADED',
	Canceled = 'CANCELED',
	Complete = 'COMPLETE',
	InvalidDestination = 'INVALID_DESTINATION',
	InvalidPackage = 'INVALID_PACKAGE',
	InvalidUrl = 'INVALID_URL',
	InProgress = 'IN_PROGRESS',
	NonSpecific = 'NON_SPECIFIC',
	NoConnection = 'NO_CONNECTION',
	Pending = 'PENDING'
}

export type FirmwarePackageServerStatus = {
	available: Scalars['Boolean']['output'];
};

export type FirmwareUpdateCapabilityOutput = {
	deviceId: Scalars['Guid']['output'];
	hostedPackages: Array<FirmwarePackage>;
	primaryPackages: Array<FirmwarePackage>;
};

export type FirmwareUpdateProgress = Node & {
	/** The hardware ID of the updating device */
	deviceId: Scalars['Guid']['output'];
	/** A percentage that indicates the overall progress of the firmware update */
	deviceProgress: Scalars['Int']['output'];
	/** The key of the firmware package in use for the update */
	firmwarePackageKey: Scalars['String']['output'];
	/** The version of the firmware package in use for the update */
	firmwarePackageVersion: Scalars['String']['output'];
	id: Scalars['ID']['output'];
	/** The current stage of the firmware update */
	stage: FirmwareUpdateStage | `${FirmwareUpdateStage}`;
	/** A percentage that indicates the progress of the current stage */
	stageProgress: Scalars['Int']['output'];
	/** The current state of the firmware update */
	status: FirmwareUpdateStatus | `${FirmwareUpdateStatus}`;
};

export type FirmwareUpdateRequestProgress = Node & {
	/** The progress for each device in the firmware update request */
	devices: Array<FirmwareUpdateProgress>;
	id: Scalars['ID']['output'];
	/** A percentage that indicates the overall progress of the firmware update request */
	progress: Scalars['Int']['output'];
	/** A unique identifier for the firmware update request */
	requestId: Scalars['Guid']['output'];
	/** The current state of the firmware update request */
	state: FirmwareUpdateRequestState | `${FirmwareUpdateRequestState}`;
};

export enum FirmwareUpdateRequestState {
	/** The firmware update request was canceled. */
	Canceled = 'CANCELED',
	/** The firmware update request failed. */
	Failed = 'FAILED',
	/** The firmware update request is in progress. */
	InProgress = 'IN_PROGRESS',
	/** The firmware update request was received, but has not started. */
	Pending = 'PENDING',
	/** The firmware update request is complete. */
	Successful = 'SUCCESSFUL'
}

export enum FirmwareUpdateStage {
	/** The firmware update was canceled. */
	Canceled = 'CANCELED',
	/** The firmware update is complete. */
	Complete = 'COMPLETE',
	/** The device is being reserved for a firmware update. */
	DeviceReserved = 'DEVICE_RESERVED',
	/** An error occurred during the firmware update. */
	Error = 'ERROR',
	/** The device is available for a firmware update. */
	Idle = 'IDLE',
	/** The device is installing the firmware package. */
	Installing = 'INSTALLING',
	/** The request to update the device was received, but has not started yet. */
	Pending = 'PENDING',
	/** The firmware package is being validated by the device before installation. */
	PreInstallValidating = 'PRE_INSTALL_VALIDATING',
	/** The device is rebooting. */
	Rebooting = 'REBOOTING',
	/** The firmware package is being transferred to the device. */
	TransferringFiles = 'TRANSFERRING_FILES',
	/** The device is verifying that the firmware package was installed correctly. */
	Verifying = 'VERIFYING'
}

export enum FirmwareUpdateStatus {
	/** The device aborted the firmware update. */
	Aborted = 'ABORTED',
	/** The firmware update was canceled. */
	Canceled = 'CANCELED',
	/** There was a connectivity issue. */
	ConnectivityLoss = 'CONNECTIVITY_LOSS',
	/** The firmware package was corrupted locally or in transfer to the device. */
	CorruptPackage = 'CORRUPT_PACKAGE',
	/** The device is unavailable for a firmware update. */
	DeviceBusy = 'DEVICE_BUSY',
	/** The device cannot be found. */
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	DeviceRejectedPackage = 'DEVICE_REJECTED_PACKAGE',
	/** An error occurred while transferring the firmware package to the device. */
	FileTransferError = 'FILE_TRANSFER_ERROR',
	/** An error occurred while the device was installing the firmware package. */
	InstallationError = 'INSTALLATION_ERROR',
	/** The device does not support the specified firmware package. */
	InvalidPackage = 'INVALID_PACKAGE',
	/** The firmware update is complete. */
	Success = 'SUCCESS',
	/** An unknown error occurred. */
	UnknownError = 'UNKNOWN_ERROR',
	/** An error occurred while the device was verifying the installation. */
	VerificationError = 'VERIFICATION_ERROR'
}

export type FloatPosition = {
	xPosition: Scalars['Float']['output'];
	yPosition: Scalars['Float']['output'];
	zPosition: Scalars['Float']['output'];
};

export type FloatPositionUpdateInput = {
	xPosition?: InputMaybe<Scalars['Float']['input']>;
	yPosition?: InputMaybe<Scalars['Float']['input']>;
	zPosition?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatRange = {
	max: Scalars['Float']['output'];
	min: Scalars['Float']['output'];
	step: Scalars['Float']['output'];
};

export type FloatRangeConstraints = {
	range: FloatRange;
};

export type Fraction = {
	denominator: Scalars['Int']['output'];
	numerator: Scalars['Int']['output'];
};

export type Fragment = {
	fragmentId: Scalars['ID']['output'];
};

/** All frequencies are represented in MHz */
export type FrequencyRange = {
	max: Scalars['Float']['output'];
	min: Scalars['Float']['output'];
};

export enum GlobalMicStatus {
	Active = 'ACTIVE',
	Mute = 'MUTE',
	NonUniform = 'NON_UNIFORM',
	Off = 'OFF',
	Standby = 'STANDBY'
}

export enum GlobalMicStatusInput {
	Active = 'ACTIVE',
	Mute = 'MUTE',
	Off = 'OFF',
	Standby = 'STANDBY'
}

export type GroupAssociationResult = {
	overallResult: GroupAssociationStatus | `${GroupAssociationStatus}`;
	warnings: Array<GroupAssociationWarning | `${GroupAssociationWarning}`>;
};

export enum GroupAssociationStatus {
	AllAssociationsUnsuccessful = 'ALL_ASSOCIATIONS_UNSUCCESSFUL',
	Conflict = 'CONFLICT',
	DevicesUnavailable = 'DEVICES_UNAVAILABLE',
	Failure = 'FAILURE',
	IncompatibleDevice = 'INCOMPATIBLE_DEVICE',
	NoApplicableRoutesForStereoAecProcessor = 'NO_APPLICABLE_ROUTES_FOR_STEREO_AEC_PROCESSOR',
	Success = 'SUCCESS',
	SuccessWithWarnings = 'SUCCESS_WITH_WARNINGS',
	Timeout = 'TIMEOUT'
}

export enum GroupAssociationWarning {
	AllNetworkAudioAssociationsUnsuccessful = 'ALL_NETWORK_AUDIO_ASSOCIATIONS_UNSUCCESSFUL',
	NonImxMixOutputsUsedToFitDevices = 'NON_IMX_MIX_OUTPUTS_USED_TO_FIT_DEVICES',
	NoApplicableRoutesForStereoAecProcessor = 'NO_APPLICABLE_ROUTES_FOR_STEREO_AEC_PROCESSOR',
	SomeNetworkAudioAssociationsUnsuccessful = 'SOME_NETWORK_AUDIO_ASSOCIATIONS_UNSUCCESSFUL',
	TooManyAmplifiers = 'TOO_MANY_AMPLIFIERS',
	TooManyMicrophones = 'TOO_MANY_MICROPHONES',
	TooManyMicrophonesForStereoAecProcessor = 'TOO_MANY_MICROPHONES_FOR_STEREO_AEC_PROCESSOR',
	TooManyProcessors = 'TOO_MANY_PROCESSORS'
}

export type GroupOptimizationResult = {
	deviceOptimizationResults: Array<DeviceOptimizationResult>;
	overallResult: GroupOptimizationStatus | `${GroupOptimizationStatus}`;
};

export enum GroupOptimizationStatus {
	Conflict = 'CONFLICT',
	DevicesUnavailable = 'DEVICES_UNAVAILABLE',
	Failure = 'FAILURE',
	NoDevicesToOptimize = 'NO_DEVICES_TO_OPTIMIZE',
	PartialSuccess = 'PARTIAL_SUCCESS',
	Success = 'SUCCESS'
}

export type IDurationRangeConstraints = {
	range: DurationRange;
};

export type IFieldSupportInfo = {
	supported: Scalars['Boolean']['output'];
};

export type IFloatRangeConstraints = {
	range: FloatRange;
};

export type IIntRangeConstraints = {
	range: IntRange;
};

export type INullableDurationRangeConstraints = {
	range: Maybe<DurationRange>;
};

export type INullableFloatRangeConstraints = {
	range: Maybe<FloatRange>;
};

export type INullableIntRangeConstraints = {
	range: Maybe<IntRange>;
};

export type IStringPatternRangeConstraints = {
	pattern: Maybe<Scalars['Regex']['output']>;
};

export type IUtf8StringMaxBytesConstraints = {
	utf8MaxBytes: Maybe<Scalars['Int']['output']>;
};

export type InspectKeyringOperationResult = {
	deviceIds: Array<Scalars['ID']['output']>;
	error: Maybe<OperationError>;
};

export type IntRange = {
	max: Scalars['Int']['output'];
	min: Scalars['Int']['output'];
	step: Scalars['Int']['output'];
};

export type IntRangeConstraints = {
	range: IntRange;
};

export type InterfaceDetails = {
	category: Scalars['String']['output'];
	model: Maybe<Scalars['String']['output']>;
	version: InterfaceVersion;
};

export type InterfaceVersion = {
	beta: Scalars['Boolean']['output'];
	interfaceId: Scalars['Int']['output'];
	version: Scalars['SemanticVersion']['output'];
};

export enum IpMode {
	Dhcp = 'DHCP',
	Static = 'STATIC'
}

export type KeyringInput = {
	id: Scalars['ID']['input'];
	passphrase: Scalars['String']['input'];
};

export type KeyringOperationResult = {
	error: Maybe<OperationError>;
	keyring: Maybe<Scalars['String']['output']>;
};

export enum LastAuthenticationStatus8021X {
	Failure = 'FAILURE',
	NeverAttempted = 'NEVER_ATTEMPTED',
	Success = 'SUCCESS'
}

export enum LicenseActivationState {
	Activated = 'ACTIVATED',
	Canceled = 'CANCELED',
	HostMismatchError = 'HOST_MISMATCH_ERROR',
	InsufficientFailure = 'INSUFFICIENT_FAILURE',
	InternalError = 'INTERNAL_ERROR',
	InvalidConfigurationFailure = 'INVALID_CONFIGURATION_FAILURE',
	KeyNotfoundFailure = 'KEY_NOTFOUND_FAILURE',
	NetworkFailure = 'NETWORK_FAILURE',
	ParameterError = 'PARAMETER_ERROR',
	Pending = 'PENDING',
	PendingInsufficient = 'PENDING_INSUFFICIENT',
	PendingNetwork = 'PENDING_NETWORK'
}

export enum LicenseCancelationState {
	Canceled = 'CANCELED',
	Failed = 'FAILED',
	InternalError = 'INTERNAL_ERROR',
	ParameterError = 'PARAMETER_ERROR',
	Pending = 'PENDING'
}

export enum LicenseDeactivationState {
	Deactivated = 'DEACTIVATED',
	InternalError = 'INTERNAL_ERROR',
	NetworkFailure = 'NETWORK_FAILURE',
	ParameterError = 'PARAMETER_ERROR',
	Pending = 'PENDING'
}

export enum LicenseState {
	Clockwindback = 'CLOCKWINDBACK',
	Expired = 'EXPIRED',
	FailedActivationKeyError = 'FAILED_ACTIVATION_KEY_ERROR',
	Grace = 'GRACE',
	InternalError = 'INTERNAL_ERROR',
	PendingActivationInsufficient = 'PENDING_ACTIVATION_INSUFFICIENT',
	PendingActivationNetwork = 'PENDING_ACTIVATION_NETWORK',
	PendingActivationOffline = 'PENDING_ACTIVATION_OFFLINE',
	PendingDeactivation = 'PENDING_DEACTIVATION',
	PendingExpiration = 'PENDING_EXPIRATION',
	Tampered = 'TAMPERED',
	Valid = 'VALID'
}

export enum LicenseStateV2 {
	Clockwindback = 'CLOCKWINDBACK',
	Expired = 'EXPIRED',
	FailedActivationKeyError = 'FAILED_ACTIVATION_KEY_ERROR',
	Grace = 'GRACE',
	InternalError = 'INTERNAL_ERROR',
	PendingActivationInsufficient = 'PENDING_ACTIVATION_INSUFFICIENT',
	PendingActivationNetwork = 'PENDING_ACTIVATION_NETWORK',
	PendingActivationOffline = 'PENDING_ACTIVATION_OFFLINE',
	PendingDeactivation = 'PENDING_DEACTIVATION',
	PendingExpiration = 'PENDING_EXPIRATION',
	Tampered = 'TAMPERED',
	Valid = 'VALID'
}

export enum LicenseType {
	Demo = 'DEMO',
	Paid = 'PAID',
	Trial = 'TRIAL'
}

export enum LicenseTypeV2 {
	Demo = 'DEMO',
	Paid = 'PAID',
	Trial = 'TRIAL'
}

export enum LightingColor {
	Blue = 'BLUE',
	Cyan = 'CYAN',
	Gold = 'GOLD',
	Green = 'GREEN',
	LightPurple = 'LIGHT_PURPLE',
	Orange = 'ORANGE',
	Orchid = 'ORCHID',
	Pink = 'PINK',
	PowderBlue = 'POWDER_BLUE',
	Purple = 'PURPLE',
	Red = 'RED',
	SkyBlue = 'SKY_BLUE',
	Turquoise = 'TURQUOISE',
	Violet = 'VIOLET',
	White = 'WHITE',
	Yellow = 'YELLOW',
	YellowGreen = 'YELLOW_GREEN'
}

export enum LightingMode {
	Flashing = 'FLASHING',
	Off = 'OFF',
	On = 'ON'
}

export enum LightingState {
	Muted = 'MUTED',
	Unmuted = 'UNMUTED'
}

export enum LightingStyle {
	Ring = 'RING',
	Segment = 'SEGMENT'
}

export enum LightingTouchPanelMode {
	Combined = 'COMBINED',
	Split = 'SPLIT'
}

export enum LinkingOperationResult {
	/** Device is not discoverable. */
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	/** Failed to link RF channel(s) and transmitter(s) */
	Failure = 'FAILURE',
	/** Input node id(s) provided in linking request is not valid. */
	InvalidInput = 'INVALID_INPUT',
	/** Provided input devices either does not support linking feature or not a valid input for this operation. */
	InvalidOperation = 'INVALID_OPERATION',
	/** No associations exist on RF channel(s) or charger bay(s). */
	NoAssociationsExist = 'NO_ASSOCIATIONS_EXIST',
	/** No transmitter is discovered for the charger bay. */
	NoTransmittersOnCharger = 'NO_TRANSMITTERS_ON_CHARGER',
	/** Successfully linked RF channel(s) and transmitter(s) */
	Success = 'SUCCESS'
}

export enum LinkingStatus {
	/**
	 * An unknown reason caused the failure.
	 * This means the middleware receive a linking result that is not expected such as BAN_SECONDARY_MIC or INVALID_APT_CHANNEL
	 */
	Failure = 'FAILURE',
	/** Linking failed due to audio protocol mismatch */
	FailureAudioProtocolMismatch = 'FAILURE_AUDIO_PROTOCOL_MISMATCH',
	/**
	 * Linking failed due to mismatched access point/charger/transmitter firmware version
	 * Major and minor version of firmware for devices should be matched to perform linking operation
	 */
	FailureFirmwareVersionMismatch = 'FAILURE_FIRMWARE_VERSION_MISMATCH',
	/** Linking failed due to RF band mismatch */
	FailureRfBandMismatch = 'FAILURE_RF_BAND_MISMATCH',
	/** Successfully linked RF channel and transmitter */
	Success = 'SUCCESS',
	/** Failed to receive linking result within the timeout */
	TimeoutExceeded = 'TIMEOUT_EXCEEDED'
}

export type LiveMeterColorConstraints = {
	customThemeLightColorGradient: LiveMeterCustomThemeLightColorGradientConstraints;
	theme: ColorThemeConstraints;
};

export type LiveMeterColorThemeInfo = {
	customThemeLightColorGradient: LiveMeterCustomThemeLightColorGradient;
	theme: ColorTheme | `${ColorTheme}`;
};

export type LiveMeterColorThemeInfoInput = {
	customThemeLightColorGradient?: InputMaybe<LiveMeterCustomThemeLightColorGradientInput>;
	theme?: InputMaybe<ColorTheme | `${ColorTheme}`>;
};

export type LiveMeterCustomThemeLightColorGradient = {
	centerLightColor: RgbColorMix;
	clippingLightColor: RgbColorMix;
	outermostLightColor: RgbColorMix;
};

export type LiveMeterCustomThemeLightColorGradientConstraints = {
	centerLightColor: RgbColorMixConstraints;
	clippingLightColor: RgbColorMixConstraints;
	outermostLightColor: RgbColorMixConstraints;
};

export type LiveMeterCustomThemeLightColorGradientInput = {
	centerLightColor?: InputMaybe<RgbColorMixInput>;
	clippingLightColor?: InputMaybe<RgbColorMixInput>;
	outermostLightColor?: InputMaybe<RgbColorMixInput>;
};

export type LoadDeviceStateInput = {
	device: Scalars['ID']['input'];
	filePath: Scalars['String']['input'];
};

export type LoadDeviceStateResult = {
	device: Maybe<Device>;
	error: Maybe<OperationError>;
	filePath: Maybe<Scalars['String']['output']>;
};

export enum LoadPerDeviceStateResult {
	LoadFailed = 'LOAD_FAILED',
	LoadSuccess = 'LOAD_SUCCESS'
}

export enum LobeWidth {
	Medium = 'MEDIUM',
	Narrow = 'NARROW',
	Wide = 'WIDE'
}

export type LobeWidthConstraints = {
	options: Array<LobeWidth | `${LobeWidth}`>;
};

export enum Loudspeaker {
	Generic = 'GENERIC',
	Mxp_1 = 'MXP_1',
	Mxp_3 = 'MXP_3',
	Mxp_5 = 'MXP_5',
	Mxp_6 = 'MXP_6'
}

export type LoudspeakerConstraints = {
	options: Array<Loudspeaker | `${Loudspeaker}`>;
};

export type MeterChange = AudioChannelMeterChange | AudioCoverageAreaMeterChange | RfChannelMeterChange;

export enum MeterChangeType {
	AudioChannelAcousticEchoCancellationErle = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_ERLE',
	AudioChannelAcousticEchoCancellationPeak = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_PEAK',
	AudioChannelAcousticEchoCancellationRms = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_RMS',
	AudioChannelActivity = 'AUDIO_CHANNEL_ACTIVITY',
	AudioChannelAutomaticGain = 'AUDIO_CHANNEL_AUTOMATIC_GAIN',
	AudioChannelAutomaticGainControlPeak = 'AUDIO_CHANNEL_AUTOMATIC_GAIN_CONTROL_PEAK',
	AudioChannelAutomaticGainControlRms = 'AUDIO_CHANNEL_AUTOMATIC_GAIN_CONTROL_RMS',
	AudioChannelAutomixerGate = 'AUDIO_CHANNEL_AUTOMIXER_GATE',
	AudioChannelAutomixerInputPeak = 'AUDIO_CHANNEL_AUTOMIXER_INPUT_PEAK',
	AudioChannelAutomixerInputRms = 'AUDIO_CHANNEL_AUTOMIXER_INPUT_RMS',
	AudioChannelAutomixGain = 'AUDIO_CHANNEL_AUTOMIX_GAIN',
	AudioChannelClip = 'AUDIO_CHANNEL_CLIP',
	AudioChannelCompressorEngaged = 'AUDIO_CHANNEL_COMPRESSOR_ENGAGED',
	AudioChannelDenoiserNoiseReduction = 'AUDIO_CHANNEL_DENOISER_NOISE_REDUCTION',
	AudioChannelLimiterEngaged = 'AUDIO_CHANNEL_LIMITER_ENGAGED',
	AudioChannelMatrixMixerInputPeak = 'AUDIO_CHANNEL_MATRIX_MIXER_INPUT_PEAK',
	AudioChannelMatrixMixerInputRms = 'AUDIO_CHANNEL_MATRIX_MIXER_INPUT_RMS',
	AudioChannelMatrixMixerOutputPeak = 'AUDIO_CHANNEL_MATRIX_MIXER_OUTPUT_PEAK',
	AudioChannelMatrixMixerOutputRms = 'AUDIO_CHANNEL_MATRIX_MIXER_OUTPUT_RMS',
	AudioChannelMatrixMixerStereoInputPeak = 'AUDIO_CHANNEL_MATRIX_MIXER_STEREO_INPUT_PEAK',
	AudioChannelMatrixMixerStereoInputRms = 'AUDIO_CHANNEL_MATRIX_MIXER_STEREO_INPUT_RMS',
	AudioChannelPeak = 'AUDIO_CHANNEL_PEAK',
	AudioChannelRms = 'AUDIO_CHANNEL_RMS',
	AudioChannelStereoPeak = 'AUDIO_CHANNEL_STEREO_PEAK',
	AudioChannelStereoRms = 'AUDIO_CHANNEL_STEREO_RMS',
	AudioCoverageAreaAutomixerGate = 'AUDIO_COVERAGE_AREA_AUTOMIXER_GATE',
	AudioCoverageAreaPeak = 'AUDIO_COVERAGE_AREA_PEAK',
	AudioCoverageAreaRms = 'AUDIO_COVERAGE_AREA_RMS',
	RfChannelActivity = 'RF_CHANNEL_ACTIVITY',
	RfChannelSignalStrength = 'RF_CHANNEL_SIGNAL_STRENGTH'
}

export type MeterColorConstraints = {
	customThemeLightColor: RgbColorMixConstraints;
	theme: ColorThemeConstraints;
};

export type MeterColorThemeInfo = {
	customThemeLightColor: RgbColorMix;
	theme: ColorTheme | `${ColorTheme}`;
};

export type MeterColorThemeInfoInput = {
	customThemeLightColor?: InputMaybe<RgbColorMixInput>;
	theme?: InputMaybe<ColorTheme | `${ColorTheme}`>;
};

export enum MeteringMode {
	PostFader = 'POST_FADER',
	PreFader = 'PRE_FADER'
}

export enum MicOptimizationMode {
	Mxa310 = 'MXA310',
	Mxa710 = 'MXA710',
	Mxa901 = 'MXA901',
	Mxa902 = 'MXA902',
	Mxa910 = 'MXA910',
	Mxa920 = 'MXA920',
	Normal = 'NORMAL'
}

/**
 * The microphone polar pattern determines how much audio signal is picked up from the direction from which the signal originates from.
 * RAW_MID_SIDE: Combines Cardioid and Bi-directional polar pattern. Refer to the following youtube video for more information on its usage: https://youtu.be/HXpHcO8RG1M
 */
export enum MicPolarPattern {
	Bidirectional = 'BIDIRECTIONAL',
	Cardioid = 'CARDIOID',
	Hypercardioid = 'HYPERCARDIOID',
	Omnidirectional = 'OMNIDIRECTIONAL',
	RawMidSide = 'RAW_MID_SIDE',
	Stereo = 'STEREO',
	Supercardioid = 'SUPERCARDIOID',
	Toroid = 'TOROID'
}

export enum MicStatus {
	Active = 'ACTIVE',
	Mute = 'MUTE',
	Off = 'OFF',
	OnCharger = 'ON_CHARGER',
	OutOfRange = 'OUT_OF_RANGE',
	Standby = 'STANDBY',
	Unknown = 'UNKNOWN'
}

export enum MicStatusInput {
	Active = 'ACTIVE',
	Mute = 'MUTE',
	Off = 'OFF',
	Standby = 'STANDBY'
}

export type MicrophoneAngle = {
	angle: Scalars['Int']['output'];
};

export type Mutation = {
	/**
	 * Activates a coverage area with the provided initial configuration.
	 *
	 * The coverage area CANNOT overlap with currently active coverage areas.
	 *
	 * The coverage area CANNOT be more than 25'(7620000μm) away from the center of the device.
	 *
	 * Dedicated coverage areas MUST have a fixed size of 6' x 6' (1828800μm x 1828800μm).
	 */
	activateAudioCoverageArea: AudioCoverageAreaOperationResult;
	activateDeviceLicense: DeviceOperationResult;
	addDevicesToAudioMuteControlGroup: DeviceOperationResult;
	addDevicesToRoom: RoomOperationResult;
	addManualDeviceIps: OperationResult;
	/**
	 * Requests to add one or more virtual devices
	 *
	 * Waits for the virtual device(s) to come online
	 */
	addVirtualDevices: VirtualDeviceAdditionResult;
	/**
	 * Requests to add one or more virtual devices
	 *
	 * Does not wait for the virtual device(s) to come online
	 */
	addVirtualDevicesAsync: VirtualDeviceAdditionAsyncResult;
	/**
	 * Requests to add one or more virtual devices from uploaded JSON state files
	 *
	 * Waits for the virtual device(s) to come online
	 */
	addVirtualDevicesFromStateFileContents: VirtualDevicesAdditionFromStateFileContentsResult;
	/**
	 * Requests to add one or more virtual devices from JSON state files
	 *
	 * Waits for the virtual device(s) to come online
	 */
	addVirtualDevicesFromStateFilePaths: VirtualDevicesAdditionFromStateFilePathsResult;
	/**
	 * Requests to add one or more virtual devices from JSON state files
	 *
	 * Does not wait for the virtual device(s) to come online
	 */
	addVirtualDevicesFromStateFilePathsAsync: VirtualDeviceAdditionAsyncResult;
	/**
	 * Requests to add one or more virtual devices from remote JSON state files
	 *
	 * Waits for the virtual device(s) to come online
	 */
	addVirtualDevicesFromStateFileUris: VirtualDevicesAdditionFromStateFileUrisResult;
	/**
	 * Associate an access point's RF channel and a charger's charger bay to *prepare for linking* a transmitter with an access point's RF channel.
	 * Any existing prior association for the RF channel or the charger bay will be cleared before creating the requested association.
	 */
	associateAccessPointAndCharger: DeviceAssociationResult;
	authenticate: Maybe<DeviceOperationResult>;
	bypassInitialCredentials: Array<DeviceOperationResult>;
	cancelFirmwareUpdate: OperationResult;
	cancelPendingActivationDeviceLicense: DeviceOperationResult;
	cancelRoomCloning: OperationResult;
	/**
	 * Clear all existing association(s) on an access point's RF channel or a charger's charger bay.
	 * 'disassociateAccessPointAndCharger()' should be used instead when the associated devices are online to confirm both device acknowledge the disassociation.
	 */
	clearAllAssociations: DeviceOperationResult;
	clearAllDanteAudioRoutes: DeleteRouteOperationResult;
	/**
	 * Clear a single association association(s) on an access point's RF channel or a charger's charger bay.
	 * 'disassociateAccessPointAndCharger()' should be used instead when the associated devices are online to confirm both devices acknowledge the disassociation.
	 */
	clearAssociation: ClearAssociationResult;
	clearAudioMuteControlGroup: DeviceOperationResult;
	clearDectRfSpectrumScanResults: DeviceOperationResult;
	clearErrorIndicator: DeviceOperationResult;
	clearManualDeviceIps: OperationResult;
	clearUserPreset: UserPresetOperationResult;
	configure8021x: DeviceOperationResult;
	createCredentials: Array<DeviceOperationResult>;
	createDanteAudioRoute: CreateRouteOperationResult;
	createKeyring: KeyringOperationResult;
	createRoom: RoomOperationResult;
	/** Deactivates a coverage area. */
	deactivateAudioCoverageArea: AudioCoverageAreaOperationResult;
	deactivateDeviceLicense: DeviceOperationResult;
	deleteDanteAudioRoute: DeleteRouteOperationResult;
	deleteRoom: OperationResult;
	disableDanteAudioEncryption: DeviceOperationResult;
	/**
	 * Disassociate an access point's RF channel and a charger's charger bay. Both devices must be online for the disassociation to succeed.
	 * Use clearAllAssociations() or clearAssociation() instead when one of the devices is offline.
	 */
	disassociateAccessPointAndCharger: DeviceDisassociationResult;
	downloadFirmwarePackages: FirmwarePackageDownloadRequestProgress;
	enable8021x: DeviceOperationResult;
	enableDanteAudioEncryption: DeviceOperationResult;
	exportDeviceEventLogs: EventLogExportResult;
	exportUserPresetToFileContents: UserPresetExportToFileContentsOperationResult;
	exportUserPresetToFilePath: UserPresetOperationResult;
	importUserPresetFromFileContents: UserPresetOperationResult;
	importUserPresetFromFilePath: UserPresetOperationResult;
	inspectKeyring: InspectKeyringOperationResult;
	/**
	 * Link all transmitters docked on a charger to the associated access point RF channels(when deviceId is charger device Id)
	 * or link all transmitters docked on chargers associated with an access point(when device is access point device Id).
	 * All associations between access point's RF channel and charger's charger bay should be established before linking
	 * id is charger device node Id or access point device node Id.
	 * Note: Skipped linkings for all charger bays or all RF channels will return SUCCESS with null details
	 */
	linkAllTransmitters: DeviceGroupLinkingResult;
	/**
	 * Link single transmitter on a charger to access point's RF channel remotely.
	 * This mutation will link the transmitter on a charger to access point's RF channel without association.
	 * Previously linked transmitter information will be lost if you link a different transmitter to the same RF channel.
	 */
	linkSingleTransmitter: DeviceSingleLinkingResult;
	loadDeviceStates: Array<LoadDeviceStateResult>;
	loadUserPreset: UserPresetOperationResult;
	lowPowerStandbyMode: OperationResult;
	powerOffDevice: OperationResult;
	rebootDevice: OperationResult;
	/** The refreshDeviceLicense mutation initiates a license refresh operation on the device. This operation involves communication between the device and ShureCloud. */
	refreshDeviceLicense: DeviceOperationResult;
	/** Requests to remove all virtual devices */
	removeAllVirtualDevices: VirtualDeviceRemovalResult;
	removeCredentials: Array<DeviceOperationResult>;
	removeDevicesFromAudioMuteControlGroup: DeviceOperationResult;
	removeDevicesFromRoom: RoomOperationResult;
	removeManualDeviceIps: OperationResult;
	/** Requests to remove one or more virtual devices */
	removeVirtualDevices: VirtualDeviceRemovalResult;
	replaceManualDeviceIps: OperationResult;
	/**
	 * Repositions a coverage area according to the provided coordinates.
	 *
	 * The coverage area CANNOT overlap with currently active coverage areas.
	 *
	 * The coverage area CANNOT be more than 25'(7620000μm) away from the center of the device.
	 *
	 * Dedicated coverage areas MUST have a fixed size of 6' x 6' (1828800μm x 1828800μm).
	 */
	repositionAudioCoverageArea: AudioCoverageAreaOperationResult;
	reset8021x: DeviceOperationResult;
	resetDevice: ResetDeviceResult;
	saveDeviceStates: Array<SaveDeviceStateResult>;
	saveUserPreset: UserPresetOperationResult;
	startAudioChannelAutoPositioning: AudioChannelOperationResult;
	startAudioChannelIdentify: AudioChannelOperationResult;
	startDectRfSpectrumScan: DeviceOperationResult;
	startIdentify: DeviceOperationResult;
	stopAudioChannelAutoPositioning: AudioChannelOperationResult;
	stopAudioChannelIdentify: AudioChannelOperationResult;
	stopDectRfSpectrumScan: DeviceOperationResult;
	stopIdentify: DeviceOperationResult;
	/** Synchronize Shure device/channel names to audio network device/channel names on the device */
	synchronizeAudioNetworkNamesOnDevice: NameSyncOperationResult;
	/**
	 * Unlink all transmitters via charger device or access point device.
	 * This mutation will unlink all transmitters docked on a charger(when deviceId is charger device Id)
	 * or unlink all transmitters linked to access point(when deviceId is access point device Id).
	 * id can be charger device node Id or access point node Id.
	 * Note: Skipped unlinkings for all charger bays or all RF channels will return SUCCESS with null details
	 */
	unlinkAllTransmitters: DeviceGroupUnlinkingResult;
	/**
	 * Unlink single transmitter via charger bay or RF channel.
	 * This mutation will unlink the transmitter docked on a charger(when id is charger bay node Id)
	 * or unlink the transmitter linked to access point's RF channel(when id is RF channel node Id).
	 * id can be charger bay node Id or RF channel node Id.
	 */
	unlinkSingleTransmitter: DeviceSingleUnlinkingResult;
	updateAcousticEchoCancellationMonoReferenceSource: AudioChannelOperationResult;
	updateAcousticEchoCancellationStereoReferenceSource: AudioChannelOperationResult;
	updateAudioChannelCount: DeviceOperationResult;
	updateAudioNetwork: OperationResult;
	updateAudioNetworkInterface: OperationResult;
	updateAudioNetworks: OperationResult;
	updateAutomixSourceSelector: AudioChannelOperationResult;
	updateControlNetwork: OperationResult;
	updateControlNetworkInterface: OperationResult;
	updateCredentials: Array<DeviceOperationResult>;
	updateDeviceLightingBrightness: DeviceOperationResult;
	/** The updateDevicePropery mutation is not-supported and is only used for internal purposes */
	updateDeviceProperty: Scalars['Boolean']['output'];
	updateDiscovery: OperationResult;
	updateEqualizerPreset: AudioChannelOperationResult;
	updateFirmware: FirmwareUpdateRequestProgress;
	updateGlobalMicStatus: DeviceOperationResult;
	updateKeyring: KeyringOperationResult;
	/**
	 * Update one or more nodes simultaneously by patching their current state(s) with new state(s).
	 *
	 * If one or more nodes provided correspond to devices which support the 'authentication' capability, and have authentication enabled,
	 * a keyring must be provided to authorize the update for the nodes on those devices.
	 */
	updateNodes: Array<NodeOperationResult>;
	updateRoom: RoomOperationResult;
	updateSwitchConfiguration: OperationResult;
	updateUserPresetName: UserPresetOperationResult;
	validateCertificate8021X: Certificate8021XValidationResult;
	verifyCredentials: Array<DeviceOperationResult>;
};

export type MutationActivateAudioCoverageAreaArgs = {
	coordinates: RectangularCoordinatesInput;
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
	type: AudioCoverageAreaType;
};

export type MutationActivateDeviceLicenseArgs = {
	input: ActivateLicenseInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddDevicesToAudioMuteControlGroupArgs = {
	devices: Array<Scalars['ID']['input']>;
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddDevicesToRoomArgs = {
	devices: Array<Scalars['ID']['input']>;
	keyring: InputMaybe<Scalars['String']['input']>;
	room: Scalars['ID']['input'];
};

export type MutationAddManualDeviceIpsArgs = {
	input: DeviceIpsInput;
};

export type MutationAddVirtualDevicesArgs = {
	input: VirtualDeviceAdditionInput;
};

export type MutationAddVirtualDevicesAsyncArgs = {
	input: VirtualDeviceAdditionInput;
};

export type MutationAddVirtualDevicesFromStateFileContentsArgs = {
	input: Array<VirtualDeviceAdditionFromStateFileContentsInput>;
};

export type MutationAddVirtualDevicesFromStateFilePathsArgs = {
	input: Array<VirtualDeviceAdditionFromStateFilePathInput>;
};

export type MutationAddVirtualDevicesFromStateFilePathsAsyncArgs = {
	input: Array<VirtualDeviceAdditionFromStateFilePathAsyncInput>;
};

export type MutationAddVirtualDevicesFromStateFileUrisArgs = {
	input: Array<VirtualDeviceAdditionFromStateFileUriInput>;
};

export type MutationAssociateAccessPointAndChargerArgs = {
	input: DeviceAssociationInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationAuthenticateArgs = {
	id: Scalars['ID']['input'];
	keyring: Scalars['String']['input'];
};

export type MutationBypassInitialCredentialsArgs = {
	inputs: Array<BypassCredentialsInput>;
};

export type MutationCancelFirmwareUpdateArgs = {
	requestId: Scalars['Guid']['input'];
};

export type MutationCancelPendingActivationDeviceLicenseArgs = {
	input: CancelPendingActivationLicenseInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationCancelRoomCloningArgs = {
	cloneRoomId: Scalars['ID']['input'];
};

export type MutationClearAllAssociationsArgs = {
	deviceId: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationClearAllDanteAudioRoutesArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	receiveDevice: Scalars['ID']['input'];
};

export type MutationClearAssociationArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationClearAudioMuteControlGroupArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationClearDectRfSpectrumScanResultsArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationClearErrorIndicatorArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationClearUserPresetArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	presetId: Scalars['ID']['input'];
};

export type MutationConfigure8021xArgs = {
	config: ConfigInput8021X;
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateCredentialsArgs = {
	inputs: Array<CreateCredentialsInput>;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateDanteAudioRouteArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	receiveChannel: Scalars['ID']['input'];
	transmitChannel: Scalars['ID']['input'];
};

export type MutationCreateKeyringArgs = {
	input: Array<KeyringInput>;
};

export type MutationCreateRoomArgs = {
	input: CreateRoomInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeactivateAudioCoverageAreaArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeactivateDeviceLicenseArgs = {
	input: DeactivateLicenseInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteDanteAudioRouteArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	receiveChannel: Scalars['ID']['input'];
};

export type MutationDeleteRoomArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	room: Scalars['ID']['input'];
};

export type MutationDisableDanteAudioEncryptionArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationDisassociateAccessPointAndChargerArgs = {
	input: DeviceDisassociationInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationDownloadFirmwarePackagesArgs = {
	packages: Array<FirmwarePackageDownloadInput>;
};

export type MutationEnable8021xArgs = {
	enable: Scalars['Boolean']['input'];
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
	rebootNow: Scalars['Boolean']['input'];
};

export type MutationEnableDanteAudioEncryptionArgs = {
	input: AudioEncryptionSettingsInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationExportDeviceEventLogsArgs = {
	id: Scalars['ID']['input'];
};

export type MutationExportUserPresetToFileContentsArgs = {
	presetId: Scalars['ID']['input'];
};

export type MutationExportUserPresetToFilePathArgs = {
	input: PresetExportToFilePathInput;
};

export type MutationImportUserPresetFromFileContentsArgs = {
	input: PresetImportFromFileContentsInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationImportUserPresetFromFilePathArgs = {
	input: PresetImportFromFilePathInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationInspectKeyringArgs = {
	keyring: Scalars['String']['input'];
};

export type MutationLinkAllTransmittersArgs = {
	deviceId: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationLinkSingleTransmitterArgs = {
	input: DeviceLinkingInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationLoadDeviceStatesArgs = {
	input: Array<LoadDeviceStateInput>;
};

export type MutationLoadUserPresetArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	presetId: Scalars['ID']['input'];
};

export type MutationLowPowerStandbyModeArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationPowerOffDeviceArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationRebootDeviceArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationRefreshDeviceLicenseArgs = {
	input: RefreshDeviceLicenseInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationRemoveCredentialsArgs = {
	inputs: Array<RemoveCredentialsInput>;
	keyring: Scalars['String']['input'];
};

export type MutationRemoveDevicesFromAudioMuteControlGroupArgs = {
	devices: Array<Scalars['ID']['input']>;
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationRemoveDevicesFromRoomArgs = {
	devices: Array<Scalars['ID']['input']>;
	keyring: InputMaybe<Scalars['String']['input']>;
	room: Scalars['ID']['input'];
};

export type MutationRemoveManualDeviceIpsArgs = {
	input: DeviceIpsInput;
};

export type MutationRemoveVirtualDevicesArgs = {
	input: VirtualDeviceRemovalInput;
};

export type MutationReplaceManualDeviceIpsArgs = {
	input: DeviceIpsInput;
};

export type MutationRepositionAudioCoverageAreaArgs = {
	coordinates: RectangularCoordinatesInput;
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationReset8021xArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
	rebootNow: Scalars['Boolean']['input'];
};

export type MutationResetDeviceArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
	scope: DeviceResetScope;
};

export type MutationSaveDeviceStatesArgs = {
	input: Array<SaveDeviceStateInput>;
};

export type MutationSaveUserPresetArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	presetId: Scalars['ID']['input'];
};

export type MutationStartAudioChannelAutoPositioningArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationStartAudioChannelIdentifyArgs = {
	id: Scalars['ID']['input'];
};

export type MutationStartDectRfSpectrumScanArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationStartIdentifyArgs = {
	id: Scalars['ID']['input'];
};

export type MutationStopAudioChannelAutoPositioningArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationStopAudioChannelIdentifyArgs = {
	id: Scalars['ID']['input'];
};

export type MutationStopDectRfSpectrumScanArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationStopIdentifyArgs = {
	id: Scalars['ID']['input'];
};

export type MutationSynchronizeAudioNetworkNamesOnDeviceArgs = {
	input: AudioNetworkNameSyncInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUnlinkAllTransmittersArgs = {
	deviceId: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUnlinkSingleTransmitterArgs = {
	id: Scalars['ID']['input'];
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAcousticEchoCancellationMonoReferenceSourceArgs = {
	input: AudioChannelAcousticEchoCancellationMonoReferenceInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAcousticEchoCancellationStereoReferenceSourceArgs = {
	input: AudioChannelAcousticEchoCancellationStereoReferenceInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAudioChannelCountArgs = {
	input: AudioChannelCountInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAudioNetworkArgs = {
	input: UpdateAudioNetworkInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAudioNetworkInterfaceArgs = {
	input: UpdateAudioNetworkInterfaceInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAudioNetworksArgs = {
	input: UpdateAudioNetworksInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAutomixSourceSelectorArgs = {
	input: AutomixSourceSelectorInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateControlNetworkArgs = {
	input: UpdateControlNetworkInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateControlNetworkInterfaceArgs = {
	input: UpdateControlNetworkInterfaceInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCredentialsArgs = {
	inputs: Array<UpdateCredentialsInput>;
	keyring: Scalars['String']['input'];
};

export type MutationUpdateDeviceLightingBrightnessArgs = {
	input: DeviceLightingBrightnessUpdateInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateDevicePropertyArgs = {
	id: Scalars['ID']['input'];
	propertyKey: Scalars['String']['input'];
	propertyValue: Scalars['String']['input'];
};

export type MutationUpdateDiscoveryArgs = {
	input: SupportedProtocolsInput;
};

export type MutationUpdateEqualizerPresetArgs = {
	input: AudioChannelEqualizerPresetUpdateInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateFirmwareArgs = {
	input: Array<UpdateDeviceFirmwareInput>;
};

export type MutationUpdateGlobalMicStatusArgs = {
	input: DeviceGlobalMicStatusUpdateInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateKeyringArgs = {
	input: Array<KeyringInput>;
	keyring: Scalars['String']['input'];
};

export type MutationUpdateNodesArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	updates: Array<NodeUpdateInput>;
};

export type MutationUpdateRoomArgs = {
	input: UpdateRoomInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateSwitchConfigurationArgs = {
	input: DeviceSwitchConfigurationUpdateInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateUserPresetNameArgs = {
	input: PresetUpdateInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type MutationValidateCertificate8021XArgs = {
	certificate: Scalars['String']['input'];
};

export type MutationVerifyCredentialsArgs = {
	inputs: Array<VerifyCredentialsInput>;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export enum MuteButtonMode {
	PushToMute = 'PUSH_TO_MUTE',
	PushToTalk = 'PUSH_TO_TALK',
	Toggle = 'TOGGLE'
}

export enum MuteControlFunction {
	Disabled = 'DISABLED',
	Local = 'LOCAL',
	LogicOut = 'LOGIC_OUT'
}

export type NameSyncOperationResult = {
	error: Maybe<OperationError>;
	result: Maybe<AudioNetworkNameSyncResult>;
};

export type NetworkInterface = {
	description: Scalars['String']['output'];
	gateway: Maybe<Scalars['IpAddress']['output']>;
	/** Example: Windows: (GUID) '{60B5DC8A-837A-44BC-B594-5027416EF5CC}', Unix: 'en0' */
	id: Scalars['String']['output'];
	ipAddress: Scalars['IpAddress']['output'];
	macAddress: Scalars['String']['output'];
	/**
	 * Display name, or 'Friendly name', of network interface.
	 * Example: Windows: 'Test Network', Mac: 'Thunderbolt Ethernet Slot 1'. Note: Will be copy of below 'id' field for Linux.
	 */
	name: Scalars['String']['output'];
	subnetMask: Scalars['IpAddress']['output'];
};

export type NetworkInterfaceChange = {
	status: NetworkInterfaceStatus | `${NetworkInterfaceStatus}`;
};

export type NetworkInterfaceInfo = {
	gateway: Maybe<Scalars['IpAddress']['output']>;
	ipAddress: Maybe<Scalars['IpAddress']['output']>;
	ipMode: Maybe<IpMode | `${IpMode}`>;
	macAddress: Scalars['String']['output'];
	subnetMask: Maybe<Scalars['IpAddress']['output']>;
};

export enum NetworkInterfaceStatus {
	InterfaceDisabled = 'INTERFACE_DISABLED',
	InterfaceEnabled = 'INTERFACE_ENABLED',
	IpaddressChanged = 'IPADDRESS_CHANGED',
	NetmaskChanged = 'NETMASK_CHANGED',
	NotSet = 'NOT_SET'
}

export enum NetworkMode {
	Redundant = 'REDUNDANT',
	Split = 'SPLIT',
	Switched = 'SWITCHED',
	Uplink = 'UPLINK'
}

export type Node = {
	id: Scalars['ID']['output'];
};

export type NodeChange =
	| AudioChannel
	| AudioChannelChange
	| AudioCoverageArea
	| AudioCoverageAreaChange
	| ChargerBay
	| ChargerBayChange
	| CrossPoint
	| CrossPointChange
	| Device
	| DeviceChange
	| DeviceStatusChange
	| EqualizerFilter
	| EqualizerFilterChange
	| FirmwarePackageDownloadProgress
	| FirmwarePackageDownloadRequestProgress
	| FirmwareUpdateProgress
	| FirmwareUpdateRequestProgress
	| RfChannel
	| RfChannelChange
	| Room
	| UserPreset
	| UserPresetChange;

export enum NodeChangeType {
	AudioChannelAcousticEchoCancellation = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION',
	AudioChannelAcousticEchoCancellationMonoReference = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_MONO_REFERENCE',
	AudioChannelAcousticEchoCancellationStereoReference = 'AUDIO_CHANNEL_ACOUSTIC_ECHO_CANCELLATION_STEREO_REFERENCE',
	AudioChannelAnalogLevel = 'AUDIO_CHANNEL_ANALOG_LEVEL',
	AudioChannelAutomaticGainControl = 'AUDIO_CHANNEL_AUTOMATIC_GAIN_CONTROL',
	AudioChannelAutomixer = 'AUDIO_CHANNEL_AUTOMIXER',
	AudioChannelAutomixerInput = 'AUDIO_CHANNEL_AUTOMIXER_INPUT',
	AudioChannelAutomixerVoiceActivityDetection = 'AUDIO_CHANNEL_AUTOMIXER_VOICE_ACTIVITY_DETECTION',
	AudioChannelAutomixSourceSelector = 'AUDIO_CHANNEL_AUTOMIX_SOURCE_SELECTOR',
	AudioChannelAutoLevel = 'AUDIO_CHANNEL_AUTO_LEVEL',
	AudioChannelAutoMute = 'AUDIO_CHANNEL_AUTO_MUTE',
	AudioChannelAutoPosition = 'AUDIO_CHANNEL_AUTO_POSITION',
	AudioChannelCarriedByRfChannels = 'AUDIO_CHANNEL_CARRIED_BY_RF_CHANNELS',
	AudioChannelChannelConfiguration = 'AUDIO_CHANNEL_CHANNEL_CONFIGURATION',
	AudioChannelCompressor = 'AUDIO_CHANNEL_COMPRESSOR',
	AudioChannelConnectionDetection = 'AUDIO_CHANNEL_CONNECTION_DETECTION',
	AudioChannelDanteEncryptionStatus = 'AUDIO_CHANNEL_DANTE_ENCRYPTION_STATUS',
	AudioChannelDanteRouteReceiver = 'AUDIO_CHANNEL_DANTE_ROUTE_RECEIVER',
	AudioChannelDanteRouteTransmitter = 'AUDIO_CHANNEL_DANTE_ROUTE_TRANSMITTER',
	AudioChannelDelay = 'AUDIO_CHANNEL_DELAY',
	AudioChannelDenoiser = 'AUDIO_CHANNEL_DENOISER',
	AudioChannelDeverb = 'AUDIO_CHANNEL_DEVERB',
	AudioChannelDeviceSpecificEqualizerContour = 'AUDIO_CHANNEL_DEVICE_SPECIFIC_EQUALIZER_CONTOUR',
	AudioChannelDirectOutputTapPoint = 'AUDIO_CHANNEL_DIRECT_OUTPUT_TAP_POINT',
	AudioChannelEqualizer = 'AUDIO_CHANNEL_EQUALIZER',
	AudioChannelEqualizerContour = 'AUDIO_CHANNEL_EQUALIZER_CONTOUR',
	AudioChannelEqualizerPreset = 'AUDIO_CHANNEL_EQUALIZER_PRESET',
	AudioChannelFaderGroup = 'AUDIO_CHANNEL_FADER_GROUP',
	AudioChannelGain = 'AUDIO_CHANNEL_GAIN',
	AudioChannelGainLock = 'AUDIO_CHANNEL_GAIN_LOCK',
	AudioChannelIdentify = 'AUDIO_CHANNEL_IDENTIFY',
	AudioChannelInputSource = 'AUDIO_CHANNEL_INPUT_SOURCE',
	AudioChannelLightingBehavior = 'AUDIO_CHANNEL_LIGHTING_BEHAVIOR',
	AudioChannelLimiter = 'AUDIO_CHANNEL_LIMITER',
	AudioChannelLobeConfiguration = 'AUDIO_CHANNEL_LOBE_CONFIGURATION',
	AudioChannelLobePosition = 'AUDIO_CHANNEL_LOBE_POSITION',
	AudioChannelLobeTalkerHeight = 'AUDIO_CHANNEL_LOBE_TALKER_HEIGHT',
	AudioChannelLobeWidth = 'AUDIO_CHANNEL_LOBE_WIDTH',
	AudioChannelLogicReceiver = 'AUDIO_CHANNEL_LOGIC_RECEIVER',
	AudioChannelMicrophoneAngle = 'AUDIO_CHANNEL_MICROPHONE_ANGLE',
	AudioChannelMicOptimization = 'AUDIO_CHANNEL_MIC_OPTIMIZATION',
	AudioChannelMicPolarPattern = 'AUDIO_CHANNEL_MIC_POLAR_PATTERN',
	AudioChannelMonitorMix = 'AUDIO_CHANNEL_MONITOR_MIX',
	AudioChannelMonitorMixReverb = 'AUDIO_CHANNEL_MONITOR_MIX_REVERB',
	AudioChannelMute = 'AUDIO_CHANNEL_MUTE',
	AudioChannelMuteGroup = 'AUDIO_CHANNEL_MUTE_GROUP',
	AudioChannelMuteLock = 'AUDIO_CHANNEL_MUTE_LOCK',
	AudioChannelName = 'AUDIO_CHANNEL_NAME',
	AudioChannelNoiseReduction = 'AUDIO_CHANNEL_NOISE_REDUCTION',
	AudioChannelOutputPath = 'AUDIO_CHANNEL_OUTPUT_PATH',
	AudioChannelPan = 'AUDIO_CHANNEL_PAN',
	AudioChannelPcPeripheral = 'AUDIO_CHANNEL_PC_PERIPHERAL',
	AudioChannelPhantomPower = 'AUDIO_CHANNEL_PHANTOM_POWER',
	AudioChannelPlosiveReduction = 'AUDIO_CHANNEL_PLOSIVE_REDUCTION',
	AudioChannelPostGateGain = 'AUDIO_CHANNEL_POST_GATE_GAIN',
	AudioChannelPostGateMute = 'AUDIO_CHANNEL_POST_GATE_MUTE',
	AudioChannelPostGateSolo = 'AUDIO_CHANNEL_POST_GATE_SOLO',
	AudioChannelReverb = 'AUDIO_CHANNEL_REVERB',
	AudioChannelSelectedLoudspeaker = 'AUDIO_CHANNEL_SELECTED_LOUDSPEAKER',
	AudioChannelSignalGenerator = 'AUDIO_CHANNEL_SIGNAL_GENERATOR',
	AudioChannelSolo = 'AUDIO_CHANNEL_SOLO',
	AudioChannelStereoGain = 'AUDIO_CHANNEL_STEREO_GAIN',
	AudioChannelStereoReverse = 'AUDIO_CHANNEL_STEREO_REVERSE',
	AudioChannelStereoWidth = 'AUDIO_CHANNEL_STEREO_WIDTH',
	AudioChannelTalkerHeight = 'AUDIO_CHANNEL_TALKER_HEIGHT',
	AudioChannelUsbOutputMode = 'AUDIO_CHANNEL_USB_OUTPUT_MODE',
	AudioCoverageAreaConfiguration = 'AUDIO_COVERAGE_AREA_CONFIGURATION',
	AudioCoverageAreaGain = 'AUDIO_COVERAGE_AREA_GAIN',
	AudioCoverageAreaMute = 'AUDIO_COVERAGE_AREA_MUTE',
	ChargerBayAssociation = 'CHARGER_BAY_ASSOCIATION',
	ChargerBayBatteryPresent = 'CHARGER_BAY_BATTERY_PRESENT',
	/** @deprecated Proof of concept of charger bay on device.  Do not use. */
	ChargerBayChargingMode = 'CHARGER_BAY_CHARGING_MODE',
	ChargerBayDockedDevice = 'CHARGER_BAY_DOCKED_DEVICE',
	CrossPointConfiguration = 'CROSS_POINT_CONFIGURATION',
	CrossPointGain = 'CROSS_POINT_GAIN',
	DeviceActivePreset = 'DEVICE_ACTIVE_PRESET',
	DeviceAllEqualizersBypass = 'DEVICE_ALL_EQUALIZERS_BYPASS',
	DeviceAllIntellimixBypass = 'DEVICE_ALL_INTELLIMIX_BYPASS',
	DeviceAnalogAudioOutputMode = 'DEVICE_ANALOG_AUDIO_OUTPUT_MODE',
	DeviceAnalogLogic = 'DEVICE_ANALOG_LOGIC',
	DeviceAnalogLogicPresetSwitch = 'DEVICE_ANALOG_LOGIC_PRESET_SWITCH',
	DeviceAnalogLogicPresetSwitchStatus = 'DEVICE_ANALOG_LOGIC_PRESET_SWITCH_STATUS',
	DeviceAudioAutoFocus = 'DEVICE_AUDIO_AUTO_FOCUS',
	DeviceAudioChannels = 'DEVICE_AUDIO_CHANNELS',
	DeviceAudioChannelCount = 'DEVICE_AUDIO_CHANNEL_COUNT',
	DeviceAudioMute = 'DEVICE_AUDIO_MUTE',
	DeviceAudioMuteControl = 'DEVICE_AUDIO_MUTE_CONTROL',
	DeviceAudioMuteControlGroup = 'DEVICE_AUDIO_MUTE_CONTROL_GROUP',
	DeviceAudioMuteDefault = 'DEVICE_AUDIO_MUTE_DEFAULT',
	DeviceAudioMuteLock = 'DEVICE_AUDIO_MUTE_LOCK',
	DeviceAudioNetworkInterface = 'DEVICE_AUDIO_NETWORK_INTERFACE',
	DeviceAuthentication = 'DEVICE_AUTHENTICATION',
	DeviceAutomixGainMetering = 'DEVICE_AUTOMIX_GAIN_METERING',
	DeviceAvailablePackages = 'DEVICE_AVAILABLE_PACKAGES',
	DeviceBatteryHealth = 'DEVICE_BATTERY_HEALTH',
	DeviceBatteryLevel = 'DEVICE_BATTERY_LEVEL',
	DeviceBodypackMicrophoneMode = 'DEVICE_BODYPACK_MICROPHONE_MODE',
	DeviceCallStatus = 'DEVICE_CALL_STATUS',
	DeviceCommandStringsService = 'DEVICE_COMMAND_STRINGS_SERVICE',
	DeviceConferencingOperationMode = 'DEVICE_CONFERENCING_OPERATION_MODE',
	DeviceConferencingOperationModeButtonLock = 'DEVICE_CONFERENCING_OPERATION_MODE_BUTTON_LOCK',
	DeviceConfigurationLock = 'DEVICE_CONFIGURATION_LOCK',
	DeviceConnectedDeviceLabel = 'DEVICE_CONNECTED_DEVICE_LABEL',
	DeviceControlNetworkInterface = 'DEVICE_CONTROL_NETWORK_INTERFACE',
	DeviceCoverageMode = 'DEVICE_COVERAGE_MODE',
	DeviceCoveragePosition = 'DEVICE_COVERAGE_POSITION',
	DeviceDanteAudioEncryption = 'DEVICE_DANTE_AUDIO_ENCRYPTION',
	DeviceDanteAudioNetwork = 'DEVICE_DANTE_AUDIO_NETWORK',
	DeviceDanteAudioNetworkAccess = 'DEVICE_DANTE_AUDIO_NETWORK_ACCESS',
	DeviceDectRfSpectrumScan = 'DEVICE_DECT_RF_SPECTRUM_SCAN',
	DeviceDeploymentCandidates = 'DEVICE_DEPLOYMENT_CANDIDATES',
	DeviceDnsClientConfiguration = 'DEVICE_DNS_CLIENT_CONFIGURATION',
	/** @deprecated For Cloud Managed Rooms Use Only. */
	DeviceDoubleStuffProxiedTransmitters = 'DEVICE_DOUBLE_STUFF_PROXIED_TRANSMITTERS',
	DeviceEqualizerContour = 'DEVICE_EQUALIZER_CONTOUR',
	DeviceErrorIndicator = 'DEVICE_ERROR_INDICATOR',
	DeviceEthernetLights = 'DEVICE_ETHERNET_LIGHTS',
	DeviceFirmwareUpdatePolicy = 'DEVICE_FIRMWARE_UPDATE_POLICY',
	DeviceGatewayDevice = 'DEVICE_GATEWAY_DEVICE',
	DeviceGlobalMicStatus = 'DEVICE_GLOBAL_MIC_STATUS',
	DeviceHeight = 'DEVICE_HEIGHT',
	DeviceIdentify = 'DEVICE_IDENTIFY',
	DeviceInternetOfThings = 'DEVICE_INTERNET_OF_THINGS',
	DeviceLicense = 'DEVICE_LICENSE',
	DeviceLicenseV2 = 'DEVICE_LICENSE_V2',
	DeviceLightingAudioActivity = 'DEVICE_LIGHTING_AUDIO_ACTIVITY',
	DeviceLightingAudioMeters = 'DEVICE_LIGHTING_AUDIO_METERS',
	DeviceLightingAudioMuteIndicatorOverride = 'DEVICE_LIGHTING_AUDIO_MUTE_INDICATOR_OVERRIDE',
	DeviceLightingAutomixGating = 'DEVICE_LIGHTING_AUTOMIX_GATING',
	DeviceLightingBehavior = 'DEVICE_LIGHTING_BEHAVIOR',
	DeviceLightingBrightness = 'DEVICE_LIGHTING_BRIGHTNESS',
	DeviceLightingLiveAudioMeters = 'DEVICE_LIGHTING_LIVE_AUDIO_METERS',
	DeviceLightingMuteBehavior = 'DEVICE_LIGHTING_MUTE_BEHAVIOR',
	DeviceLightingRing = 'DEVICE_LIGHTING_RING',
	DeviceLightingSplitModeBreathingMeterColor = 'DEVICE_LIGHTING_SPLIT_MODE_BREATHING_METER_COLOR',
	DeviceLightingSplitModeSolidMeterColor = 'DEVICE_LIGHTING_SPLIT_MODE_SOLID_METER_COLOR',
	DeviceLightingTouchPanelMode = 'DEVICE_LIGHTING_TOUCH_PANEL_MODE',
	DeviceLightingUnmuteBehavior = 'DEVICE_LIGHTING_UNMUTE_BEHAVIOR',
	DeviceLinkedAccessPoint = 'DEVICE_LINKED_ACCESS_POINT',
	DeviceLinkButtonLock = 'DEVICE_LINK_BUTTON_LOCK',
	DeviceListenerHeight = 'DEVICE_LISTENER_HEIGHT',
	DeviceLogicMute = 'DEVICE_LOGIC_MUTE',
	DeviceLogicReceiver = 'DEVICE_LOGIC_RECEIVER',
	DeviceLogicTransmitter = 'DEVICE_LOGIC_TRANSMITTER',
	DeviceLoudspeaker = 'DEVICE_LOUDSPEAKER',
	DeviceMdnsService = 'DEVICE_MDNS_SERVICE',
	DeviceMeteringModes = 'DEVICE_METERING_MODES',
	DeviceMicStatus = 'DEVICE_MIC_STATUS',
	DeviceName = 'DEVICE_NAME',
	DeviceOnCharger = 'DEVICE_ON_CHARGER',
	DevicePlacement = 'DEVICE_PLACEMENT',
	DeviceProxiedDevices = 'DEVICE_PROXIED_DEVICES',
	DeviceRedundantAudioNetwork = 'DEVICE_REDUNDANT_AUDIO_NETWORK',
	DeviceRfDensityMode = 'DEVICE_RF_DENSITY_MODE',
	DeviceRfPower = 'DEVICE_RF_POWER',
	DeviceRoom = 'DEVICE_ROOM',
	DeviceRotation = 'DEVICE_ROTATION',
	DeviceRoutingPosition = 'DEVICE_ROUTING_POSITION',
	DeviceSecurity_8021X = 'DEVICE_SECURITY_8021X',
	DeviceSmartGate = 'DEVICE_SMART_GATE',
	DeviceStereoAudio = 'DEVICE_STEREO_AUDIO',
	DeviceSwitchConfiguration = 'DEVICE_SWITCH_CONFIGURATION',
	DeviceTags = 'DEVICE_TAGS',
	DeviceTransmitterIdentifyBehavior = 'DEVICE_TRANSMITTER_IDENTIFY_BEHAVIOR',
	DeviceTransmitterInitialStateFromCharger = 'DEVICE_TRANSMITTER_INITIAL_STATE_FROM_CHARGER',
	DeviceTransmitterLightingBehavior = 'DEVICE_TRANSMITTER_LIGHTING_BEHAVIOR',
	DeviceTransmitterMuteBehavior = 'DEVICE_TRANSMITTER_MUTE_BEHAVIOR',
	DeviceTransmitterOutOfRangeBehavior = 'DEVICE_TRANSMITTER_OUT_OF_RANGE_BEHAVIOR',
	DeviceTransmitterRejoinBehavior = 'DEVICE_TRANSMITTER_REJOIN_BEHAVIOR',
	DeviceTransmitterSwitchBehavior = 'DEVICE_TRANSMITTER_SWITCH_BEHAVIOR',
	DeviceUpdateProgress = 'DEVICE_UPDATE_PROGRESS',
	DeviceUptime = 'DEVICE_UPTIME',
	DeviceUsbMuteSync = 'DEVICE_USB_MUTE_SYNC',
	DeviceUsbTerminalType = 'DEVICE_USB_TERMINAL_TYPE',
	DeviceUserPreset = 'DEVICE_USER_PRESET',
	DeviceVirtualAcousticBoundary = 'DEVICE_VIRTUAL_ACOUSTIC_BOUNDARY',
	DeviceWebService = 'DEVICE_WEB_SERVICE',
	EqualizerFilterBandwidth = 'EQUALIZER_FILTER_BANDWIDTH',
	EqualizerFilterConfiguration = 'EQUALIZER_FILTER_CONFIGURATION',
	EqualizerFilterFrequency = 'EQUALIZER_FILTER_FREQUENCY',
	EqualizerFilterGain = 'EQUALIZER_FILTER_GAIN',
	EqualizerFilterQFactor = 'EQUALIZER_FILTER_Q_FACTOR',
	FirmwarePackageDownloadProgress = 'FIRMWARE_PACKAGE_DOWNLOAD_PROGRESS',
	FirmwarePackageDownloadRequestProgress = 'FIRMWARE_PACKAGE_DOWNLOAD_REQUEST_PROGRESS',
	FirmwareUpdateProgress = 'FIRMWARE_UPDATE_PROGRESS',
	FirmwareUpdateRequestProgress = 'FIRMWARE_UPDATE_REQUEST_PROGRESS',
	RfChannelAssociation = 'RF_CHANNEL_ASSOCIATION',
	RfChannelCarriedAudioChannels = 'RF_CHANNEL_CARRIED_AUDIO_CHANNELS',
	RfChannelLinkedTransmitter = 'RF_CHANNEL_LINKED_TRANSMITTER',
	/** @deprecated Proof of concept of RF channels on device.  Do not use. */
	RfChannelPrimaryGroupChanName = 'RF_CHANNEL_PRIMARY_GROUP_CHAN_NAME',
	Room = 'ROOM',
	UserPreset = 'USER_PRESET'
}

/**
 * Result returned for an operation which may result in the associated device becoming inaccessible
 * (e.g. transitioning to DeviceState.OFFLINE)
 */
export type NodeOperationResult = {
	error: Maybe<OperationError>;
};

export type NodeUpdateInput = {
	audioChannel?: InputMaybe<AudioChannelUpdateInput>;
	audioCoverageArea?: InputMaybe<AudioCoverageAreaUpdateInput>;
	crossPoint?: InputMaybe<CrossPointUpdateInput>;
	device?: InputMaybe<DeviceUpdateInput>;
	equalizerFilters?: InputMaybe<EqualizerFilterUpdateInput>;
	rfChannel?: InputMaybe<RfChannelUpdateInput>;
};

export enum NoiseDetectionSensitivity {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
	Off = 'OFF'
}

export type NoiseDetectionSensitivityConstraints = {
	options: Array<NoiseDetectionSensitivity | `${NoiseDetectionSensitivity}`>;
};

export enum NoiseReductionLevel {
	Auto = 'AUTO',
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM'
}

export type NoiseReductionLevelConstraints = IFieldSupportInfo & {
	options: Maybe<Array<NoiseReductionLevel | `${NoiseReductionLevel}`>>;
	supported: Scalars['Boolean']['output'];
};

export enum NoiseRemovalLevel {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
	Off = 'OFF'
}

export type NoiseRemovalLevelConstraints = {
	options: Array<NoiseRemovalLevel | `${NoiseRemovalLevel}`>;
};

export enum NonLinearProcessingLevel {
	Auto = 'AUTO',
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM'
}

export type NonLinearProcessingLevelConstraints = {
	options: Array<NonLinearProcessingLevel | `${NonLinearProcessingLevel}`>;
};

export type OperatingSystem = {
	manufacturer: Maybe<Scalars['String']['output']>;
	version: Maybe<Scalars['String']['output']>;
};

export type OperationError = {
	code: ErrorCode | `${ErrorCode}`;
	message: Scalars['String']['output'];
};

export type OperationResult = {
	error: Maybe<OperationError>;
};

export enum OperationalStatus {
	Down = 'DOWN',
	Up = 'UP'
}

export type OptimizeRoomInput = {
	/**
	 * Indicates whether audio setting should be applied to room devices.
	 * If not provided, true is assumed (audio settings will be applied to associated room devices).
	 */
	adjustAudioSettings?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * Indicates whether optimizing the room should happen even if one or more room devices are offline.
	 * If not provided, false is assumed (room optimization would fail if one or more devices are offline).
	 */
	allowUnavailableDevices?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * Indicates whether mute synchronization should be enabled on processor while optimizaing room.
	 * If not provided, true is assumed (mute synchronization will be enabled on the processor).
	 */
	enableMuteSynchronization?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OptimizeRoomProgress = OptimizeRoomProgressEvent | OptimizeRoomResult;

export type OptimizeRoomProgressEvent = {
	roomOptimizationStage: RoomOptimizationStage | `${RoomOptimizationStage}`;
};

export enum OptimizeRoomProgressType {
	OptimizeRoomProgress = 'OPTIMIZE_ROOM_PROGRESS',
	OptimizeRoomResult = 'OPTIMIZE_ROOM_RESULT'
}

export type OptimizeRoomResult = {
	associationResult: GroupAssociationResult;
	optimizationResult: GroupOptimizationResult;
	status: RoomOptimizationStatus | `${RoomOptimizationStatus}`;
};

export type OriginDevice = {
	device: Maybe<Device>;
	hardwareId: Scalars['Guid']['output'];
};

export enum OutputSignalPath {
	PostDigitalSignalProcessing = 'POST_DIGITAL_SIGNAL_PROCESSING',
	PreDigitalSignalProcessing = 'PRE_DIGITAL_SIGNAL_PROCESSING'
}

export type PageInfo = {
	endCursor: Scalars['String']['output'];
	hasNextPage: Scalars['Boolean']['output'];
	hasPreviousPage: Scalars['Boolean']['output'];
	startCursor: Scalars['String']['output'];
};

export type PcPeripheral = {
	id: Scalars['String']['output'];
	name: Scalars['String']['output'];
	status: PcPeripheralStatus | `${PcPeripheralStatus}`;
};

export enum PcPeripheralStatus {
	Available = 'AVAILABLE',
	Begin = 'BEGIN',
	End = 'END',
	InProgress = 'IN_PROGRESS',
	None = 'NONE',
	Unavailable = 'UNAVAILABLE'
}

export type PendingVirtualDevice = {
	advertisedInterface: InterfaceDetails;
	compatibility: CompatibilityLevel | `${CompatibilityLevel}`;
	description: PendingVirtualDeviceDescription;
	hardwareId: Scalars['Guid']['output'];
	id: Scalars['ID']['output'];
	interface: InterfaceDetails;
	status: DeviceStatus | `${DeviceStatus}`;
	virtual: Scalars['Boolean']['output'];
};

export type PendingVirtualDeviceDescription = {
	interface: InterfaceDetails;
};

export type PhantomPowerConstraints = {
	options: Array<Scalars['Int']['output']>;
};

export enum Placement {
	Ceiling = 'CEILING',
	Table = 'TABLE',
	WallHorizontal = 'WALL_HORIZONTAL',
	WallVertical = 'WALL_VERTICAL'
}

export type PowerSourceGainConstraints = {
	poe: Maybe<FloatRangeConstraints>;
	poePlus: FloatRangeConstraints;
};

export type PresetExportToFilePathInput = {
	/**
	 * Full file path, where preset file should be exported to, including file name;
	 * file name is expected in path to ensure naming conventions, which might be different
	 * for different clients, are satisfied
	 */
	filePath: Scalars['String']['input'];
	/** Preset Id to be exported */
	presetId: Scalars['ID']['input'];
};

/** This input is intended for REST interface */
export type PresetImportFromFileContentsInput = {
	/** Full path to JSON preset file to be imported */
	fileContents: Scalars['Upload']['input'];
	/** Preset Id, to which imported preset should be applied */
	presetId: Scalars['ID']['input'];
};

export type PresetImportFromFilePathInput = {
	/** Provided path to a JSON preset file to be imported */
	filePath: Scalars['String']['input'];
	/** Preset Id, to which imported preset should be applied */
	presetId: Scalars['ID']['input'];
};

export type PresetUpdateInput = {
	name: Scalars['String']['input'];
	presetId: Scalars['ID']['input'];
};

export type ProcessorInfo = {
	cores: Maybe<Scalars['String']['output']>;
	cpuSpeed: Maybe<Scalars['String']['output']>;
	logicalProcessor: Maybe<Scalars['String']['output']>;
	processor: Maybe<Scalars['String']['output']>;
};

export type ProductionDeviceIdentity = {
	category: Scalars['String']['input'];
	minVersions: Array<Scalars['SemanticVersion']['input']>;
};

export type QFactorConstraint = EqualizerFilterValidQFactors | FloatRange;

export type Query = {
	availableFirmwarePackages: Array<FirmwarePackage>;
	deviceByAddress: Device;
	deviceByHardwareId: Device;
	deviceModels: Array<Scalars['String']['output']>;
	/** The deviceProperties query is not-supported and is only used for internal purposes */
	deviceProperties: Array<Maybe<DeviceProperty>>;
	devicesByHardwareId: Array<Maybe<Device>>;
	discoveredDevices: Array<Device>;
	discoveredDevicesConnection: DevicesConnection;
	discoveredRooms: Array<Room>;
	firmwarePackageDownloadProgress: FirmwarePackageDownloadRequestProgress;
	firmwarePackageServerStatus: FirmwarePackageServerStatus;
	firmwareUpdateProgress: FirmwareUpdateRequestProgress;
	fragmentByHardwareId: Fragment;
	manualDeviceDiscoveryIps: Array<Scalars['IpAddress']['output']>;
	networkInterfaces: Array<NetworkInterface>;
	node: Node;
	nodes: Array<Maybe<Node>>;
	self: Device;
	selfFragment: Fragment;
};

export type QueryDeviceByAddressArgs = {
	host: Scalars['String']['input'];
	port: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDeviceByHardwareIdArgs = {
	hardwareId: Scalars['Guid']['input'];
};

export type QueryDevicePropertiesArgs = {
	filter: InputMaybe<DevicePropertyFilter>;
	id: Scalars['ID']['input'];
};

export type QueryDevicesByHardwareIdArgs = {
	hardwareIds: Array<Scalars['Guid']['input']>;
};

export type QueryDiscoveredDevicesConnectionArgs = {
	after: InputMaybe<Scalars['String']['input']>;
	before: InputMaybe<Scalars['String']['input']>;
	deviceModels: InputMaybe<Array<Scalars['String']['input']>>;
	first: InputMaybe<Scalars['Int']['input']>;
	last: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFirmwarePackageDownloadProgressArgs = {
	requestId: Scalars['String']['input'];
};

export type QueryFirmwareUpdateProgressArgs = {
	updateRequestId: Scalars['Guid']['input'];
};

export type QueryFragmentByHardwareIdArgs = {
	fragmentId: Scalars['ID']['input'];
	hardwareId: Scalars['Guid']['input'];
};

export type QueryNodeArgs = {
	id: Scalars['ID']['input'];
};

export type QueryNodesArgs = {
	ids: Array<Scalars['ID']['input']>;
};

export type QuerySelfFragmentArgs = {
	fragmentId: Scalars['ID']['input'];
};

export type RectangularAreaConstraints = {
	distance: RectangularAreaFieldConstraints;
	height: RectangularAreaFieldConstraints;
	type: RectangularAreaTypeConstraints;
	width: RectangularAreaFieldConstraints;
};

export type RectangularAreaFieldConstraints = IFloatRangeConstraints & {
	range: FloatRange;
};

export type RectangularAreaTypeConstraints = {
	options: AudioCoverageAreaType | `${AudioCoverageAreaType}`;
};

export type RectangularCoordinates = {
	/** Maximum x-coordinate of the rectangular bounds, in millimeter */
	xMax: Scalars['Float']['output'];
	/** Minimum x-coordinate of the rectangular bounds, in millimeter */
	xMin: Scalars['Float']['output'];
	/** Maximum y-coordinate of the rectangular bounds, in millimeter */
	yMax: Scalars['Float']['output'];
	/** Minimum y-coordinate of the rectangular bounds, in millimeter */
	yMin: Scalars['Float']['output'];
};

export type RectangularCoordinatesInput = {
	/** Maximum x-coordinate of the rectangular bounds, in millimeters */
	xMax: Scalars['Float']['input'];
	/** Minimum x-coordinate of the rectangular bounds, in millimeters */
	xMin: Scalars['Float']['input'];
	/** Maximum y-coordinate of the rectangular bounds, in millimeters */
	yMax: Scalars['Float']['input'];
	/** Minimum y-coordinate of the rectangular bounds, in millimeters */
	yMin: Scalars['Float']['input'];
};

export type RefreshDeviceLicenseInput = {
	/** The node ID of the device to refresh the license for. */
	id: Scalars['ID']['input'];
	/** The client-generated, uuid-formatted, correlation token to use for the license refresh operation. */
	transactionId: Scalars['String']['input'];
};

export type RemoveCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
};

export type ResetDeviceResult = DeviceOperationResult | OperationResult;

export enum ReverbType {
	Hall = 'HALL',
	Plate = 'PLATE',
	Studio = 'STUDIO'
}

export enum RfActivityLevel {
	Adequate = 'ADEQUATE',
	Good = 'GOOD',
	None = 'NONE',
	Overload = 'OVERLOAD',
	Poor = 'POOR'
}

export type RfChannel = Fragment &
	Node & {
		description: RfChannelDescription;
		features: RfChannelFeatures;
		fragmentId: Scalars['ID']['output'];
		id: Scalars['ID']['output'];
	};

export type RfChannelActivityMeter = {
	level: RfActivityLevel | `${RfActivityLevel}`;
};

export type RfChannelAssociatedCharger = {
	chargerBay: Maybe<ChargerBay>;
	/** device and chargerBay fields are null when there is an association established with a charger but the charger isn't discovered */
	device: Maybe<Device>;
};

export type RfChannelAssociation = {
	/** associatedCharger field is null when there is no association established with a charger */
	associatedCharger: Maybe<RfChannelAssociatedCharger>;
};

export type RfChannelAssociationChange = {
	association: Maybe<RfChannelAssociation>;
};

export type RfChannelCarriedAudioChannels = {
	audioChannels: Array<AudioChannel>;
};

export type RfChannelCarriedAudioChannelsChange = {
	carriedAudioChannels: Maybe<RfChannelCarriedAudioChannels>;
};

export type RfChannelChange = {
	features: RfChannelFeatureChange;
	id: Scalars['ID']['output'];
};

export type RfChannelConstraints = {
	/** @deprecated Proof of concept of RF channels on device.  Do not use. */
	primaryGroupChanName: Maybe<RfChannelPrimaryGroupChanNameConstraints>;
	signalStrength: Maybe<RfChannelSignalStrengthMeterConstraints>;
};

export type RfChannelDescription = {
	constraints: RfChannelConstraints;
	features: RfChannelSupportedFeatures;
	index: Scalars['Int']['output'];
};

export type RfChannelFeatureChange =
	| RfChannelAssociationChange
	| RfChannelCarriedAudioChannelsChange
	| RfChannelLinkedTransmitterChange
	| RfChannelPrimaryGroupChanNameChange;

export type RfChannelFeatureUpdateInput = {
	primaryGroupChanName?: InputMaybe<RfChannelPrimaryGroupChanNameUpdateInput>;
};

export type RfChannelFeatures = {
	activity: Maybe<RfChannelActivityMeter>;
	association: Maybe<RfChannelAssociation>;
	carriedAudioChannels: Maybe<RfChannelCarriedAudioChannels>;
	linkedTransmitter: Maybe<RfChannelLinkedTransmitter>;
	/** @deprecated Proof of concept of RF channels on device.  Do not use. */
	primaryGroupChanName: Maybe<RfChannelPrimaryGroupChanName>;
	signalStrength: Maybe<RfChannelSignalStrengthMeter>;
};

export type RfChannelLinkage = {
	/** device field is null when there is a linkage established with a transmitter but the transmitter isn't discovered */
	device: Maybe<Device>;
};

export type RfChannelLinkedTransmitter = {
	/** linkage field is null when there is no linkage established with a transmitter */
	linkage: Maybe<RfChannelLinkage>;
};

export type RfChannelLinkedTransmitterChange = {
	linkedTransmitter: Maybe<RfChannelLinkedTransmitter>;
};

export type RfChannelMeterChange = {
	features: Maybe<RfChannelMeterFeatureChange>;
	id: Scalars['ID']['output'];
};

export type RfChannelMeterFeatureChange = {
	activity: Maybe<RfChannelActivityMeter>;
	signalStrength: Maybe<RfChannelSignalStrengthMeter>;
};

export type RfChannelOperationResult = NodeOperationResult & {
	error: Maybe<OperationError>;
	rfChannel: Maybe<RfChannel>;
};

export type RfChannelPrimaryGroupChanName = {
	/** Primary group channel name */
	primaryGroupChanName: Scalars['String']['output'];
};

export type RfChannelPrimaryGroupChanNameChange = {
	primaryGroupChanName: Maybe<RfChannelPrimaryGroupChanName>;
};

export type RfChannelPrimaryGroupChanNameConstraints = {
	/** Regular expression that a primary group channel name must match */
	primaryGroupChanNamePattern: Scalars['Regex']['output'];
};

export type RfChannelPrimaryGroupChanNameUpdateInput = {
	primaryGroupChanName: Scalars['String']['input'];
};

export type RfChannelSignalStrengthAntennaMeter = {
	/**
	 * This is the discriminator field indicating each antenna's level.
	 * Possible values are ANTENNA_A, ANTENNA_B, ANTENNA_C, or ANTENNA_D
	 */
	antenna: Scalars['String']['output'];
	level: Scalars['Int']['output'];
};

export type RfChannelSignalStrengthAntennaMeterConstraints = {
	antenna: Scalars['String']['output'];
	level: IntRangeConstraints;
};

export type RfChannelSignalStrengthMeter = {
	antennas: Array<RfChannelSignalStrengthAntennaMeter>;
};

export type RfChannelSignalStrengthMeterConstraints = {
	antennas: Array<RfChannelSignalStrengthAntennaMeterConstraints>;
};

export type RfChannelSupportedFeatures = {
	activity: FeatureSupport;
	association: FeatureSupport;
	carriedAudioChannels: FeatureSupport;
	linkedTransmitter: FeatureSupport;
	linking: FeatureSupport;
	/** @deprecated Proof of concept of RF channels on device.  Do not use. */
	primaryGroupChanName: FeatureSupport;
	signalStrength: FeatureSupport;
};

export type RfChannelUpdateInput = {
	features?: InputMaybe<RfChannelFeatureUpdateInput>;
	id: Scalars['ID']['input'];
};

export enum RfDensityMode {
	High = 'HIGH',
	Standard = 'STANDARD'
}

export type RfDensityModeConstraints = {
	options: Array<RfDensityMode | `${RfDensityMode}`>;
};

export enum RfPower {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
	MediumHigh = 'MEDIUM_HIGH'
}

export type RfPowerConstraints = {
	options: Array<RfPower | `${RfPower}`>;
};

export type RgbColorMix = {
	blue: Scalars['Int']['output'];
	green: Scalars['Int']['output'];
	red: Scalars['Int']['output'];
};

export type RgbColorMixConstraints = {
	blue: RgbColorMixFieldConstraints;
	green: RgbColorMixFieldConstraints;
	red: RgbColorMixFieldConstraints;
};

export type RgbColorMixFieldConstraints = IIntRangeConstraints & {
	range: IntRange;
};

export type RgbColorMixInput = {
	blue: Scalars['Int']['input'];
	green: Scalars['Int']['input'];
	red: Scalars['Int']['input'];
};

export type Room = Node & {
	conflict: Scalars['Boolean']['output'];
	/** Time Stamp when room is created */
	created: Scalars['DateTime']['output'];
	description: Scalars['String']['output'];
	devices: Array<Device>;
	dimensions: Maybe<RoomDimensions>;
	/**
	 * When true, the room will be held when no devices belong to it.
	 * When false, the room will be automatically deleted when no devices belong to it.
	 */
	hold: Scalars['Boolean']['output'];
	id: Scalars['ID']['output'];
	/** Basic information about the devices which are currently assigned to the room, but have not been discovered. */
	missingDevices: Array<Maybe<RoomManifestDevice>>;
	name: Scalars['String']['output'];
	roomId: Scalars['Guid']['output'];
	/** Time Stamp when room is modified for the last time */
	updated: Scalars['DateTime']['output'];
};

export type RoomAddedEvent = {
	added: Room;
};

export enum RoomCloningStrategy {
	/**
	 * Cloned device has origin ID set to the ID of the device from which it is cloned
	 * Cloning of virtual device with CLONE_AS_CHILD strategy would always result
	 * in the clone with nill origin ID (identical to CLONE_AS_ORPHAN) due to the rule
	 * of "clone of a clone is pure virtual with no origin"
	 */
	CloneAsChild = 'CLONE_AS_CHILD',
	/**
	 * Cloned device has no origin ID (null UUID)
	 * Cloning of virtual device with CLONE_AS_CHILD strategy would always result
	 * in the clone with nill origin ID (identical to CLONE_AS_ORPHAN)
	 * due to the rule of "clone of a clone is pure virtual with no origin"
	 */
	CloneAsOrphan = 'CLONE_AS_ORPHAN',
	/**
	 * Cloned device has origin ID set to the origin ID of the original device
	 * Notice that for real devices this will result in the same originId as in
	 * CLONE_AS_CHILD strategy. Real devices have origin ID equal their ID
	 */
	CloneAsSibling = 'CLONE_AS_SIBLING'
}

export type RoomDeploymentInput = {
	destinationRoom?: InputMaybe<Scalars['ID']['input']>;
	destinationRoomDescription?: InputMaybe<Scalars['String']['input']>;
	destinationRoomName?: InputMaybe<Scalars['String']['input']>;
	devices: Array<DeviceDeploymentInput>;
	sourceRoom: Scalars['ID']['input'];
};

export enum RoomDeploymentOverallResult {
	/**
	 * Room deployment failed, device deployment failed and no room placement was attempted
	 * @deprecated Use the more specific failure modes instead
	 */
	DeploymentFailed = 'DEPLOYMENT_FAILED',
	/** Room deployment succeeded, devices were deployed and placed into a room */
	DeploymentSuccess = 'DEPLOYMENT_SUCCESS',
	/** Failed at creating the destination room for the target devices */
	FailedCreatingDestinationRoom = 'FAILED_CREATING_DESTINATION_ROOM',
	/** Failed at deploying audio routes between devices in room */
	FailedDeployingAudioRoutes = 'FAILED_DEPLOYING_AUDIO_ROUTES',
	/** Failed at deploying control routes (i.e. mute routes) between devices in room */
	FailedDeployingControlRoutes = 'FAILED_DEPLOYING_CONTROL_ROUTES',
	/** Failed at deploying device state from sources to targets */
	FailedDeployingDeviceState = 'FAILED_DEPLOYING_DEVICE_STATE',
	/** Failed at cleaning up and validating the room deployment */
	FailedFinalizing = 'FAILED_FINALIZING',
	/** Failed at preparing the devices and rooms for room deployment */
	FailedPreparing = 'FAILED_PREPARING',
	/** Failed for unknown reasons */
	FailedUnknown = 'FAILED_UNKNOWN',
	/**
	 * Room deployment failed, devices were successfully deployed, but could not be placed in a room
	 * @deprecated Use the more specific failure modes instead
	 */
	PlacementFailed = 'PLACEMENT_FAILED'
}

export type RoomDeploymentProgress = RoomDeploymentProgressEvent | RoomDeploymentResult;

export type RoomDeploymentProgressEvent = {
	/** Overall progress of room deployment between 0 and 100 */
	progressPercentage: Scalars['Int']['output'];
	/** The current deployment activity happening */
	roomDeploymentStage: RoomDeploymentStage | `${RoomDeploymentStage}`;
};

export enum RoomDeploymentProgressType {
	RoomDeploymentResult = 'ROOM_DEPLOYMENT_RESULT'
}

export type RoomDeploymentResult = {
	destinationRoom: Maybe<Room>;
	deviceDeploymentResults: Array<DeviceDeploymentResult>;
	overallResult: RoomDeploymentOverallResult | `${RoomDeploymentOverallResult}`;
};

export enum RoomDeploymentStage {
	/** Creating the destination room for the target devices */
	CreatingDestinationRoom = 'CREATING_DESTINATION_ROOM',
	/** Deploying audio routes between devices in room */
	DeployingAudioRoutes = 'DEPLOYING_AUDIO_ROUTES',
	/** Deploying control routes (i.e. mute routes) between devices in room */
	DeployingControlRoutes = 'DEPLOYING_CONTROL_ROUTES',
	/** Deploying device state from sources to targets */
	DeployingDeviceState = 'DEPLOYING_DEVICE_STATE',
	/** Cleaning up and validating the room deployment */
	Finalizing = 'FINALIZING',
	/** The deployment request has been accepted and is pending execution */
	Pending = 'PENDING',
	/** Preparing the devices and rooms for room deployment */
	Preparing = 'PREPARING'
}

export type RoomDimensions = {
	/** height values are scaled to represent in millimeters (mm) */
	height: Scalars['Float']['output'];
	/** length values are scaled to represent in millimeters (mm) */
	length: Scalars['Float']['output'];
	/** width values are scaled to represent in millimeters (mm) */
	width: Scalars['Float']['output'];
};

export type RoomDimensionsInput = {
	/** height values are scaled to represent in millimeters (mm) */
	height: Scalars['Float']['input'];
	/** length values are scaled to represent in millimeters (mm) */
	length: Scalars['Float']['input'];
	/** width values are scaled to represent in millimeters (mm) */
	width: Scalars['Float']['input'];
};

/** Basic information about devices which are assigned to a room */
export type RoomManifestDevice = Node & {
	hardwareId: Scalars['Guid']['output'];
	id: Scalars['ID']['output'];
	model: Scalars['String']['output'];
};

export type RoomOperationResult = NodeOperationResult & {
	error: Maybe<OperationError>;
	room: Maybe<Room>;
};

export enum RoomOptimizationStage {
	/** Creating audio/control routes between devices */
	CreatingAssociations = 'CREATING_ASSOCIATIONS',
	/** Applying device settings for optimal audio */
	OptimizingDeviceSettings = 'OPTIMIZING_DEVICE_SETTINGS'
}

export enum RoomOptimizationStatus {
	Conflict = 'CONFLICT',
	DevicesUnavailable = 'DEVICES_UNAVAILABLE',
	Failure = 'FAILURE',
	IncompatibleDevice = 'INCOMPATIBLE_DEVICE',
	MissingMicrophone = 'MISSING_MICROPHONE',
	MissingProcessor = 'MISSING_PROCESSOR',
	PartialSuccess = 'PARTIAL_SUCCESS',
	Success = 'SUCCESS'
}

export type RoomRemovedEvent = {
	removed: Scalars['ID']['output'];
};

export type SaveDeviceStateInput = {
	device: Scalars['ID']['input'];
	filePath: Scalars['String']['input'];
	overwrite: Scalars['Boolean']['input'];
	saveOption: SaveDeviceStateOption | `${SaveDeviceStateOption}`;
};

export enum SaveDeviceStateOption {
	SaveAll = 'SAVE_ALL',
	SaveCacheOnly = 'SAVE_CACHE_ONLY'
}

export type SaveDeviceStateResult = {
	device: Maybe<Device>;
	error: Maybe<OperationError>;
	filePath: Maybe<Scalars['String']['output']>;
	/** @deprecated Use 'error' field instead to check for operation failure. */
	saveResult: Maybe<SavePerDeviceStateResult | `${SavePerDeviceStateResult}`>;
};

export enum SavePerDeviceStateResult {
	SaveFailed = 'SAVE_FAILED',
	SaveSuccess = 'SAVE_SUCCESS'
}

export type SignalGeneratorPowerSourceGainConstraints = {
	frequency: FloatRangeConstraints;
	powerSources: PowerSourceGainConstraints;
};

export enum SignalType {
	PinkNoise = 'PINK_NOISE',
	SineWave = 'SINE_WAVE',
	Sweep = 'SWEEP',
	WhiteNoise = 'WHITE_NOISE'
}

export type SignalTypeConstraints = {
	options: Array<SignalType | `${SignalType}`>;
};

export type StereoAudioChannel = Node & {
	audioChannelId: Scalars['ID']['output'];
	description: StereoAudioChannelDescription;
	features: AudioChannelFeatures;
	fragmentId: Scalars['ID']['output'];
	id: Scalars['ID']['output'];
};

export type StereoAudioChannelDescription = {
	constraints: AudioChannelConstraints;
	features: AudioChannelSupportedFeatures;
	/** @deprecated Use the 'groupName' field instead to ensure your code is open to extension to new audio channel groups. This enum is no longer preferred since it regularly breaks on expanding the available enum members to cover new channel groupings for new devices. */
	group: AudioChannelGroup | `${AudioChannelGroup}`;
	groupName: Scalars['AudioChannelGroupName']['output'];
	role: AudioChannelRole | `${AudioChannelRole}`;
	stereoAudioChannelSpatialSeparation: Maybe<
		StereoAudioChannelSpatialSeparation | `${StereoAudioChannelSpatialSeparation}`
	>;
};

export enum StereoAudioChannelSpatialSeparation {
	Left = 'LEFT',
	Right = 'RIGHT'
}

export type Subscription = {
	availableFirmwarePackages: Array<FirmwarePackage>;
	cloneRoomAsync: Maybe<CloneRoomEvent>;
	deployRoomAsync: RoomDeploymentProgress;
	deviceFirmwareUpdateCapabilities: Array<FirmwareUpdateCapabilityOutput>;
	deviceLicenseActivationProgress: DeviceLicenseActivationProgress;
	deviceLicenseCancelPendingActivationProgress: DeviceLicenseCancelPendingActivationProgress;
	deviceLicenseDeactivationProgress: DeviceLicenseDeactivationProgress;
	/**
	 * Emits events whenever a device is added under management (e.g. discovered for the first time as DeviceState.Discovered),
	 * or whenever a device is removed from management (e.g. a physical device goes to DeviceState.Offline, a virtual device is deleted).
	 */
	discoveredDevices: DiscoveredDevicesEvent;
	/** Emits events whenever any device undergoes a status change (DISCOVERED, ONLINE, OFFLINE, ERROR). */
	discoveredDevicesStatus: DiscoveredDevicesStatusChange;
	discoveredRooms: Maybe<DiscoveredRoomEvent>;
	firmwarePackageDownloadProgress: FirmwarePackageDownloadRequestProgress;
	firmwarePackageServerStatus: FirmwarePackageServerStatus;
	firmwareUpdateProgress: FirmwareUpdateRequestProgress;
	/**
	 * Emits change events for a given node specified by the node's ID.
	 * A list of MeterChangeType values can be used to suppress events that do not correspond to the features being subscribed for.
	 * The update period must also be specified for the minimum amount of time between events.
	 *
	 * Note that when multiple MeterChangeTypes and meterable data are provided simultaneously,
	 * each MeterChange event can contain one or more features for that given node which have changed.
	 */
	meterChanges: MeterChange;
	networkInterfacechange: Maybe<NetworkInterfaceChange>;
	/**
	 * Emits change events for a given node specified by the node's ID.
	 * A list of NodeChangeType values can be used to suppress events that do not correspond to the features being subscribing for.
	 * DeviceStateChange events returned from this subscription cannot be suppressed.
	 */
	nodeChanges: NodeChange;
	optimizeRoomAsync: OptimizeRoomProgress;
	/** The subscribeDeviceProperties is not-supported and is only used for internal purposes */
	subscribeDeviceProperties: Array<DevicePropertyChangeEvent>;
};

export type SubscriptionCloneRoomAsyncArgs = {
	input: CloneRoomInput;
};

export type SubscriptionDeployRoomAsyncArgs = {
	input: RoomDeploymentInput;
	keyring: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionDeviceLicenseActivationProgressArgs = {
	id: Scalars['ID']['input'];
};

export type SubscriptionDeviceLicenseCancelPendingActivationProgressArgs = {
	id: Scalars['ID']['input'];
};

export type SubscriptionDeviceLicenseDeactivationProgressArgs = {
	id: Scalars['ID']['input'];
};

export type SubscriptionMeterChangesArgs = {
	id: Scalars['ID']['input'];
	types: Array<MeterChangeType>;
	updateRate: Scalars['Duration']['input'];
};

export type SubscriptionNetworkInterfacechangeArgs = {
	networkInterfaceId: Scalars['String']['input'];
};

export type SubscriptionNodeChangesArgs = {
	id: Scalars['ID']['input'];
	types: Array<NodeChangeType>;
};

export type SubscriptionOptimizeRoomAsyncArgs = {
	keyring: InputMaybe<Scalars['String']['input']>;
	request: InputMaybe<OptimizeRoomInput>;
	room: Scalars['ID']['input'];
};

export type SubscriptionSubscribeDevicePropertiesArgs = {
	id: Scalars['ID']['input'];
	propertyKeys: Array<Scalars['String']['input']>;
};

export type SupportedProtocolsInput = {
	ethernet?: InputMaybe<EthernetConfig>;
	usb?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TalkerHeight = {
	height: Scalars['Float']['output'];
};

export type TlsCertificateInput = {
	anonymousId?: InputMaybe<Scalars['String']['input']>;
	clientCertificate: Scalars['String']['input'];
	passphrase?: InputMaybe<Scalars['String']['input']>;
	rootCertificate: Scalars['String']['input'];
	username?: InputMaybe<Scalars['String']['input']>;
};

export enum TransmitterInitialStateFromCharger {
	Active = 'ACTIVE',
	Off = 'OFF'
}

export type TransmitterInitialStateFromChargerConstraints = {
	options: Array<TransmitterInitialStateFromCharger | `${TransmitterInitialStateFromCharger}`>;
};

export enum TransmitterLightingBehavior {
	/** External LED control */
	ExternalControl = 'EXTERNAL_CONTROL',
	/** Solid green when the mic is unmuted and solid red when the mic is muted */
	UnmuteSolidGreenMuteSolidRed = 'UNMUTE_SOLID_GREEN_MUTE_SOLID_RED',
	/** Solid red when the mic is unmuted and flashing red when the mic is muted */
	UnmuteSolidRedMuteFlashingRed = 'UNMUTE_SOLID_RED_MUTE_FLASHING_RED',
	/** Solid red when the mic is unmuted and off when the mic is muted */
	UnmuteSolidRedMuteOff = 'UNMUTE_SOLID_RED_MUTE_OFF'
}

export type TransmitterLightingBehaviorConstraints = {
	options: Array<TransmitterLightingBehavior | `${TransmitterLightingBehavior}`>;
};

export enum TransmitterMuteBehavior {
	LocalMuteAll = 'LOCAL_MUTE_ALL',
	LocalMuteIndividual = 'LOCAL_MUTE_INDIVIDUAL',
	UsbHostSync = 'USB_HOST_SYNC'
}

export enum TransmitterSwitchBehavior {
	Disabled = 'DISABLED',
	PushToMute = 'PUSH_TO_MUTE',
	PushToTalk = 'PUSH_TO_TALK',
	Toggle = 'TOGGLE'
}

export enum TransmitterUsbHostDisconnectMuteBehavior {
	AlwaysMute = 'ALWAYS_MUTE',
	LocalMuteAll = 'LOCAL_MUTE_ALL',
	LocalMuteIndividual = 'LOCAL_MUTE_INDIVIDUAL'
}

export enum UnlinkingOperationResult {
	/** Device is not discoverable. */
	DeviceNotFound = 'DEVICE_NOT_FOUND',
	/** Failed to unlink RF channel(s) and transmitter(s). */
	Failure = 'FAILURE',
	/** Input node id(s) provided in linking request is not valid. */
	InvalidInput = 'INVALID_INPUT',
	/** Provided input devices either does not support linking feature or not a valid input for this operation. */
	InvalidOperation = 'INVALID_OPERATION',
	/** No transmitter is linked to RF channel. */
	NoLinkedTransmitterOnRfChannel = 'NO_LINKED_TRANSMITTER_ON_RF_CHANNEL',
	/** No transmitter is discovered for the charger bay. */
	NoTransmitterOnChargerBay = 'NO_TRANSMITTER_ON_CHARGER_BAY',
	/** Successfully unlinked RF channel(s) and transmitter(s) */
	Success = 'SUCCESS',
	/** Failed to receive unlinking result within the timeout */
	TimeoutExceeded = 'TIMEOUT_EXCEEDED'
}

export type UpdateAudioNetworkInput = {
	gateway?: InputMaybe<Scalars['IpAddress']['input']>;
	id: Scalars['ID']['input'];
	ipAddress?: InputMaybe<Scalars['IpAddress']['input']>;
	ipMode: IpMode | `${IpMode}`;
	subnetMask?: InputMaybe<Scalars['IpAddress']['input']>;
};

export type UpdateAudioNetworkInterfaceInput = {
	id: Scalars['ID']['input'];
	networkInterfaceId: Scalars['String']['input'];
};

export type UpdateAudioNetworksInput = {
	id: Scalars['ID']['input'];
	primary?: InputMaybe<AudioNetworkConfiguration>;
	redundant?: InputMaybe<AudioNetworkConfiguration>;
};

export type UpdateControlNetworkInput = {
	gateway?: InputMaybe<Scalars['IpAddress']['input']>;
	id: Scalars['ID']['input'];
	ipAddress?: InputMaybe<Scalars['IpAddress']['input']>;
	ipMode: IpMode | `${IpMode}`;
	subnetMask?: InputMaybe<Scalars['IpAddress']['input']>;
};

export type UpdateControlNetworkInterfaceInput = {
	id: Scalars['ID']['input'];
	networkInterfaceId: Scalars['String']['input'];
};

export type UpdateCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
	newPassphrase: Scalars['String']['input'];
};

export type UpdateDeviceFirmwareInput = {
	firmwarePackageKey: Scalars['String']['input'];
	firmwarePackageVersion: Scalars['String']['input'];
	id: Scalars['ID']['input'];
};

/**
 * Result from attempting to update a node via updateNodes().
 *
 * Typically, the 'node' to update will be returned with and can be queried to observe the updated data, or query for additional data.
 * In the event that the 'node' cannot be successfully queried back on behalf of the first operation, it will be returned as null (instead of additionally compounding separate errors).
 *
 * Typically, 'error' will be null.
 * If the update to the node was unsuccessful, an error will be returned via this field.
 */
export type UpdateNodeResult = {
	error: Maybe<OperationError>;
	node: Maybe<Node>;
};

export type UpdateRoomInput = {
	description?: InputMaybe<Scalars['String']['input']>;
	dimensions?: InputMaybe<RoomDimensionsInput>;
	hold?: InputMaybe<Scalars['Boolean']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
	room: Scalars['ID']['input'];
};

export enum UsbOutputMode {
	Mixdown = 'MIXDOWN',
	Stereo = 'STEREO',
	Tracking = 'TRACKING'
}

export enum UsbTerminalType {
	EchoCancelingSpeakerphone = 'ECHO_CANCELING_SPEAKERPHONE',
	SpeakerphoneNoEchoReduction = 'SPEAKERPHONE_NO_ECHO_REDUCTION'
}

export type UserPreset = Fragment &
	Node & {
		description: UserPresetDescription;
		features: UserPresetFeatures;
		fragmentId: Scalars['ID']['output'];
		id: Scalars['ID']['output'];
	};

export type UserPresetChange = {
	features: UserPresetFeatureChange;
	fragmentId: Scalars['ID']['output'];
	id: Scalars['ID']['output'];
};

export type UserPresetConstraints = {
	preset: Maybe<UserPresetCoreConstraints>;
};

export type UserPresetCore = {
	created: Maybe<Scalars['DateTime']['output']>;
	empty: Scalars['Boolean']['output'];
	name: Scalars['String']['output'];
};

export type UserPresetCoreChange = {
	preset: Maybe<UserPresetCore>;
};

export type UserPresetCoreConstraints = {
	name: UserPresetCoreNameConstraints;
};

export type UserPresetCoreNameConstraints = IStringPatternRangeConstraints & {
	pattern: Scalars['Regex']['output'];
};

export type UserPresetDescription = {
	constraints: UserPresetConstraints;
	features: UserPresetSupportedFeatures;
	index: Scalars['Int']['output'];
};

export type UserPresetExportToFileContentsOperationResult = {
	error: Maybe<OperationError>;
	fileContents: Maybe<Scalars['Upload']['output']>;
};

export type UserPresetFeatureChange = UserPresetCoreChange;

export type UserPresetFeatures = {
	preset: Maybe<UserPresetCore>;
};

export type UserPresetOperationResult = NodeOperationResult & {
	error: Maybe<OperationError>;
	userPreset: Maybe<UserPreset>;
};

export type UserPresetSupportedFeatures = {
	preset: FeatureSupport;
};

export type UsernamePassphraseInput = {
	passphrase: Scalars['String']['input'];
	username: Scalars['String']['input'];
};

export type VerifyCredentialsInput = {
	accessLevel: Scalars['String']['input'];
	id: Scalars['ID']['input'];
	passphrase: Scalars['String']['input'];
};

export type VirtualDeviceAdditionAsyncResult = {
	/** Requested virtual devices that were added, or will be added */
	addedDevices: Array<PendingVirtualDevice>;
	error: Maybe<OperationError>;
};

export type VirtualDeviceAdditionFromStateFileContentsInput = {
	/** Device ID to assign to the virtual device (optional) */
	deviceId?: InputMaybe<Scalars['Guid']['input']>;
	/** Uploaded contents of a JSON state file */
	fileContents: Scalars['Upload']['input'];
	/** Device ID of the original device represented by the state file (optional) */
	originDeviceId?: InputMaybe<Scalars['Guid']['input']>;
};

export type VirtualDeviceAdditionFromStateFileContentsResult = {
	/** Indicates whether or not the virtual device was added */
	added: Scalars['Boolean']['output'];
	/** Virtual device that was added */
	device: Device;
	/** Device ID of the original device represented by the state file, if provided */
	originDeviceId: Maybe<Scalars['Guid']['output']>;
};

export type VirtualDeviceAdditionFromStateFilePathAsyncInput = {
	/** Number of identical virtual devices to load */
	count: Scalars['Int']['input'];
	/** Path to a JSON state file */
	filePath: Scalars['String']['input'];
};

export type VirtualDeviceAdditionFromStateFilePathInput = {
	/** Device ID to assign to the requested virtual device (optional) */
	deviceId?: InputMaybe<Scalars['Guid']['input']>;
	/** Path to a JSON state file */
	filePath: Scalars['String']['input'];
	/** Device ID of the original device represented by the state file (optional) */
	originDeviceId?: InputMaybe<Scalars['Guid']['input']>;
};

export type VirtualDeviceAdditionFromStateFilePathResult = {
	/** Indicates whether or not the requested virtual device was added */
	added: Scalars['Boolean']['output'];
	/** Virtual device */
	device: Device;
	/** Provided path to a JSON state file */
	filePath: Scalars['String']['output'];
	/** Device ID of the original device represented by the state file, if provided */
	originDeviceId: Maybe<Scalars['Guid']['output']>;
};

export type VirtualDeviceAdditionFromStateFileUriInput = {
	/** Device ID to assign to the virtual device (optional) */
	deviceId?: InputMaybe<Scalars['Guid']['input']>;
	/** URI of a JSON state file */
	fileUri: Scalars['String']['input'];
	/** Device ID of the original device represented by the state file (optional) */
	originDeviceId?: InputMaybe<Scalars['Guid']['input']>;
};

export type VirtualDeviceAdditionFromStateFileUriResult = {
	/** Indicates whether or not the requested virtual device was added */
	added: Scalars['Boolean']['output'];
	/** Virtual device that was added */
	device: Device;
	/** Provided URI to a JSON state file */
	fileUri: Scalars['String']['output'];
	/** Device ID of the original device represented by the state file, if provided */
	originDeviceId: Maybe<Scalars['Guid']['output']>;
};

export type VirtualDeviceAdditionInput = {
	/** Number of identical virtual devices to add */
	count: Scalars['Int']['input'];
	/** Device category */
	deviceCategory: Scalars['String']['input'];
	/** Device type ID */
	deviceType: Scalars['Guid']['input'];
	/**
	 * Property interface ID (optional)
	 *
	 * If one is not provided, the latest supported interface will be used
	 */
	interfaceId?: InputMaybe<Scalars['Int']['input']>;
};

export type VirtualDeviceAdditionResult = {
	/** Requested virtual devices that were successfully added */
	addedDevices: Array<Device>;
	error: Maybe<OperationError>;
};

export type VirtualDeviceRemovalInput = {
	/** Node IDs of the virtual devices to remove */
	ids: Array<Scalars['ID']['input']>;
};

export type VirtualDeviceRemovalResult = {
	error: Maybe<OperationError>;
	/** Virtual devices that were not removed */
	failedDevices: Array<Device>;
};

export type VirtualDevicesAdditionFromStateFileContentsResult = {
	error: Maybe<OperationError>;
	/** Results for each requested virtual device */
	results: Array<VirtualDeviceAdditionFromStateFileContentsResult>;
};

export type VirtualDevicesAdditionFromStateFilePathsResult = {
	error: Maybe<OperationError>;
	/** Results for each requested virtual device */
	results: Array<VirtualDeviceAdditionFromStateFilePathResult>;
};

export type VirtualDevicesAdditionFromStateFileUrisResult = {
	error: Maybe<OperationError>;
	/** Results for each requested virtual device */
	results: Array<VirtualDeviceAdditionFromStateFileUriResult>;
};
